import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { translations } from "i18n";
import Lottie from "lottie-react";

import styles from "./style.module.scss";

import OBSTemplateWithoutDevices from "../common/components/OBSTemplateWithoutDevices";
import WheelOfFortuneRows from "../common/components/WheelOfFortuneRows";
import { useWheelOfFortune } from "hooks/WheelOfFortuneHook";

import imgLogo from "./img/logo.png";
import imgGift from "./img/gift.png";
import imgWin from "./img/win.png";

import lightRed from "./animations/light-red.json";
import red from "./animations/red.json";
import white from "./animations/white.json";
import yellow from "./animations/yellow.json";

// wheel consist of 4 colored sectors
const animations = [lightRed, red, white, yellow];

const settings = {
  imgGift,
  imgLogo,
  imgWin,
  giftStyle: { height: 90 },
  tipValueTextColor: "#fbe1b1",
  tipValueTextFontSize: 48,
  tipValueTextFontFamily: "Skia",
};

const WidgetComponent = ({ preset, tips, isPreview, widgetStep }) => {
  const animationRef = useRef();
  const { randomOption, getRandomOption, getRandomAnimationKey } =
    useWheelOfFortune();

  const render = () => {
    return (
      <>
        {(isPreview || widgetStep === 0) && (
          <div
            className={cn(
              styles.settingsContainer,
              styles.backgroundDefault,
              isPreview && styles.preview
            )}
          >
            <img className={styles.logo} src={settings.imgLogo} alt="" />
            <div className={styles.title}>
              {translations("WheelOfFortune.FortuneWheel")}
            </div>
            <div
              className={cn(
                styles.trapezoid,
                isPreview && styles.previewTrapezoid
              )}
            />
            <div className={styles.price}>
              {translations("WheelOfFortune.SpinForTokens", {
                amount: preset.buyIn,
              })}
            </div>
            <WheelOfFortuneRows
              preset={preset}
              isPreview={isPreview}
              settings={settings}
              showGiftImg
            />
          </div>
        )}
        {widgetStep === 1 && !isPreview && (
          <div
            className={cn(
              styles.settingsContainer,
              styles.backgroundSpinningWheel
            )}
          >
            <img className={styles.logo} src={settings.imgLogo} alt="" />
            <div className={styles.senderTipped}>
              {translations("WheelOfFortune.TippedTokens", {
                sender: tips[tips.length - 1]?.sender,
                amount: tips[tips.length - 1]?.amount,
              })}
            </div>
            <Lottie
              lottieRef={animationRef}
              animationData={animations[getRandomAnimationKey()]}
              autoplay={true}
              loop={false}
              className={styles.wheel}
            />
          </div>
        )}
        {widgetStep === 2 && !isPreview && (
          <div
            className={cn(styles.settingsContainer, styles.backgroundNoWheel)}
          >
            <img className={styles.logo} src={settings.imgLogo} alt="" />
            <img className={styles.bigWin} src={settings.imgWin} alt="" />
            <img className={styles.box} src={settings.imgGift} alt="" />
            <WheelOfFortuneRows
              preset={preset}
              isPreview={isPreview}
              settings={{ ...settings, tipValueTextFontSize: 60 }}
              indexWon={randomOption}
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (widgetStep === 1) {
      getRandomOption(preset);
    }
  }, [widgetStep]);

  return (
    <OBSTemplateWithoutDevices
      tipSettingsRenderFunction={render}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
