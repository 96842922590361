import React, { StrictMode } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { init, OperationMode, Source } from "@feelrobotics/ftap-connector";
import { createRoot } from "react-dom/client";

import "fonts/fonts.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendWebVitalsToAnalytics } from "lib/googleAnalytics";

Sentry.init({
  dsn: "https://51d5e6ca549047fd92b4bcbb414b96a2@o117313.ingest.sentry.io/6520177",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// ftap init
const isLocal =
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("127.0.0.1");
if (isLocal) {
  init(Source.FeelPerformer, OperationMode.Test);
} else {
  const isProduction = window.location.hostname === "feelperformer.com";
  init(
    Source.FeelPerformer,
    isProduction ? OperationMode.Prod : OperationMode.Test
  );
}
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendWebVitalsToAnalytics);
