import React, { createContext, useEffect, useState } from "react";
import { cloneDeep, sample } from "lodash";

import { DEFAULT_WHEEL_OF_FORTUNE } from "data/constants";

const AMOUNT_OF_ANIMATIONS = 4;

const WHEEL_OF_FORTUNE_KEY = "wheelOfFortune";

const WheelOfFortuneContext = createContext();

const useWheelOfFortune = () => {
  const context = React.useContext(WheelOfFortuneContext);
  if (context === undefined) {
    throw new Error(
      "`useWheelOfFortune` hook must be used within a `WheelOfFortuneContextProvider` component"
    );
  }
  return context;
};

const getLocalStorage = () => {
  const localStorageStr = localStorage.getItem(WHEEL_OF_FORTUNE_KEY);
  if (!localStorageStr) {
    return DEFAULT_WHEEL_OF_FORTUNE;
  }

  try {
    return JSON.parse(localStorageStr);
  } catch (e) {
    return DEFAULT_WHEEL_OF_FORTUNE;
  }
};

const WheelOfFortuneContextProvider = ({ children }) => {
  const [options, setOptions] = useState(getLocalStorage());
  const [widgetStep, setWidgetStep] = useState(0);
  const [randomOption, setRandomOption] = useState(null);

  // used for spinning wheel of fortune inside widget
  const getRandomOption = (preset) => {
    const arrActiveOptionIndexes = preset.levels
      .map(({ active }, index) => {
        if (active) {
          return index;
        }
      })
      .filter((item) => {
        if (typeof item === "number") {
          return true;
        }
      });

    setRandomOption(sample(arrActiveOptionIndexes));
  };

  const getRandomAnimationKey = () => {
    return Math.floor(Math.random() * AMOUNT_OF_ANIMATIONS);
  };

  // Save options to the local storage on each update
  useEffect(() => {
    localStorage.setItem(WHEEL_OF_FORTUNE_KEY, JSON.stringify(options));
  }, [options]);

  const updateLevel = (value, levelIndex) => {
    const optionsCopy = cloneDeep(options);
    optionsCopy.levels[levelIndex] = {
      ...optionsCopy.levels[levelIndex],
      ...value,
    };
    setOptions(optionsCopy);
  };

  const setBuyIn = (value) => {
    setOptions({ ...options, buyIn: value });
  };

  return (
    <WheelOfFortuneContext.Provider
      value={{
        updateLevel,
        options,
        setBuyIn,
        getRandomOption,
        getRandomAnimationKey,
        randomOption,
        widgetStep,
        setWidgetStep,
      }}
    >
      {children}
    </WheelOfFortuneContext.Provider>
  );
};

export { WheelOfFortuneContextProvider, useWheelOfFortune };
