import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import imageCowgirl from "images/devices/cowgirl-promotion.png";
import imageUnicorn from "images/devices/unicorn-promotion.png";

const serviceUuid = 0xfe00; // ble service id for sensor data
const motorCharUuid = 0xfe01; // motor controlling characteristic

const devices = [
  { name: "THE COWGIRL", image: imageCowgirl },
  { name: "THE UNICORN", image: imageUnicorn },
];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Cowgirl extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    const deviceRec = devices.find((d) => d.name === device.name);
    if (!deviceRec) {
      throw Error(`Device ${device.name} is not supported`);
    }
    const { image } = deviceRec;
    super(device, serviceUuid, motorCharUuid, image, isSendingData);
  }

  static get deviceNames() {
    return devices.map((d) => d.name);
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "The Cowgirl";
  }

  async write(percent) {
    const value = Math.max(0, Math.min(100, Math.round((percent * 255) / 100)));
    const array = new Uint8Array([0x00, 0x01, value, value]);
    await this.motorChar.writeValueWithResponse(array);
  }
}
