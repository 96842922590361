import { sendToAnalytics } from "lib/googleAnalytics";
import { sendAnalytics } from "@feelrobotics/ftap-connector";
import { flatMap, cloneDeep } from "lodash";

import {
  shouldSendAnalytics,
  convertPercentToCommandValue,
  convertValueToUint16,
  rgbStringToArray,
  hexToRgb,
  findMaxRGBValue,
} from "utils/utils";
import * as DeviceStates from "./DeviceStates";
import { onGattServerDisconnected } from "lib/deviceReconnectingNotification";
import image from "images/devices/spot-promotion.png";

const names = ["Spot R1"];

const ACTUATOR_SERVICE_UUID = 0x1400;
const ACTUATOR_MOTOR_UUID = 0x1401;

const BATTERY_SERVICE_UUID = 0x180f;
const BATTERY_LEVEL_UUID = 0x2a19;

const OVERRIDE_SERVICE_UUID = 0x1900;
const OVERRIDE_NAME_UUID = 0x1904;

const LED_SERVICE_UUID = 0x1600;
const LED_INFO_FX_UUID = 0x1603;
const LED_INFO_FX_FRAME = 0x1604;

const DEVICE_SPECIFIC_INFO = 0x2a29;

const PERIOD_DURATION = 1000; // ms; default value for non-constant vibraion mode

/**
 * Abstract Class BaseDeviceWrapper.
 * @class BaseDeviceWrapper
 *
 * Device wrapper over the Web Bluetooth device object
 * @respons Responsible for
 * - holding the WebBluetooth object, its service and charactristics
 * - Connecting and disconnecting to the Web Bluetooth device
 * - Discovering its service and characteristic during the connection process
 * - holding Web Bluetooth device connectin state (connected, disconnected, connecting)
 * - Handling Web Bluetooth device disconnection
 *
 * @collab Is a base class for concrete device classes like Pearl2, Cliona, etc.
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Spot {
  constructor(device) {
    this.device = device;
    this.image = image;
    this.server = null;

    this.actuatorServce = null;
    this.actuatorCharacteristic = null;

    this.batteryService = null;
    this.batteryCharacteristic = null;

    this.ledService = null;
    this.ledCharacteristic = null;
    this.ledFrameWriteCharacteristic = null;

    this.isSendingData = true;
    this.connectionState = DeviceStates.DISCONNECTED;
    this.connectionStateCallback = null;
    this.defaultDeviceName = "Spot";

    device.addEventListener("gattserverdisconnected", () => {
      onGattServerDisconnected(this);
      shouldSendAnalytics() &&
        sendAnalytics("disconnect", this.device.name, this.device.id);
    });
  }

  setSendingData(isSendingData) {
    return;
  }

  static get deviceNames() {
    return names;
  }

  static get services() {
    return [
      ACTUATOR_SERVICE_UUID,
      BATTERY_SERVICE_UUID,
      OVERRIDE_SERVICE_UUID,
      LED_SERVICE_UUID,
    ];
  }

  get companyName() {
    return "Kiiroo";
  }

  /**
   * Set new connection state and notify the callback
   * @param {string} newState - new state from DeviceStates.*
   */
  setConnectionState(newState) {
    this.connectionState = newState;
    this.connectionStateCallback?.();
  }

  /**
   * Connect to the device and discover the service and characteristic
   */
  async connect() {
    async function innerConnect() {
      const startTime = performance.now();

      this.setConnectionState(DeviceStates.CONNECTING);
      this.server = await this.device.gatt.connect();

      // motor
      this.actuatorServce = await this.server.getPrimaryService(
        ACTUATOR_SERVICE_UUID
      );
      this.actuatorCharacteristic = await this.actuatorServce.getCharacteristic(
        ACTUATOR_MOTOR_UUID
      );

      // battery
      this.batteryService = await this.server.getPrimaryService(
        BATTERY_SERVICE_UUID
      );
      this.batteryCharacteristic = await this.batteryService.getCharacteristic(
        BATTERY_LEVEL_UUID
      );

      // LEDs
      this.ledService = await this.server.getPrimaryService(LED_SERVICE_UUID);
      this.ledCharacteristic = await this.ledService.getCharacteristic(
        LED_INFO_FX_UUID
      );
      this.ledFrameWriteCharacteristic =
        await this.ledService.getCharacteristic(LED_INFO_FX_FRAME);

      // override
      this.overrideService = await this.server.getPrimaryService(
        OVERRIDE_SERVICE_UUID
      );
      this.overrideNameCharacteristic =
        await this.overrideService.getCharacteristic(OVERRIDE_NAME_UUID);

      this.setConnectionState(DeviceStates.CONNECTED);
      clearTimeout(this.connectionTimeout);

      // Report to google analytics
      const milliseconds = Math.round(performance.now() - startTime);
      sendToAnalytics(
        "device_connect",
        "device",
        this.device.name,
        milliseconds
      );
      shouldSendAnalytics() &&
        sendAnalytics("connect", this.device.name, this.device.id);
    }

    return await Promise.race([innerConnect.bind(this)()]);
  }

  /**
   * Disconnect from the device
   */
  async disconnect() {
    this.setConnectionState(DeviceStates.DISCONNECTING);
    if (this.device.gatt.connected) {
      await this.device.gatt.disconnect();
    }
    this.setConnectionState(DeviceStates.MANUALLY_DISCONNECTED);
    sendToAnalytics("device_disconnect", "device", this.device.name);
  }

  async getBattery() {
    const value = await this.batteryCharacteristic.readValue();
    return value.getUint8(0);
  }

  // working only on Windows
  async setDeviceName(newName) {
    const hexDeviceName = newName.split("").map((c) => c.charCodeAt(0));
    await this.overrideNameCharacteristic.writeValue(
      new Uint8Array(hexDeviceName)
    );
  }

  // motor control
  async startMode(totalDuration, low, high, mode = 1, periodDuration = 500) {
    const frameData = [
      mode,
      ...convertValueToUint16(periodDuration),
      Math.round(totalDuration / 1000),
      convertPercentToCommandValue(low),
      convertPercentToCommandValue(high),
    ];
    await this.actuatorCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async write(percent) {
    const periodDuration = 100;
    const STOP_VALUE = 0;
    const FOREVER_VIBRATE_VALUE = 255;

    const frameData = [
      0,
      ...convertValueToUint16(periodDuration),
      percent ? FOREVER_VIBRATE_VALUE : STOP_VALUE,
      convertPercentToCommandValue(percent),
      convertPercentToCommandValue(percent),
    ];

    await this.actuatorCharacteristic.writeValue(new Uint8Array(frameData));
  }

  // GREEN, BLUE, RED
  async sendGBRtoFrame(frameIndex, frameLEDs, bilateral) {
    const clone = cloneDeep(frameLEDs);
    const arrayShiftedLEDs = clone.map((color) => rgbStringToArray(color));
    const halfFrameData = flatMap(arrayShiftedLEDs); // lights for 8 LEDs
    const frameData = [
      ...halfFrameData,
      ...(bilateral ? halfFrameData : new Array(24).fill(0)),
    ]; // lights for 16 LEDs
    // frames can be set to index from 0 to 4
    frameData.unshift(frameIndex);

    await this.ledFrameWriteCharacteristic.writeValue(
      new Uint8Array(frameData)
    );
  }

  async runRandomShiftEffect(frameIndex, totalDuration) {
    // frameData[0] - FX index = 1.
    // Available time range 5...500
    const frameData = [1, frameIndex, 500, Math.round(totalDuration / 1000)];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async runCometEffect(frameIndex, totalDuration) {
    // frameData[0] - FX index = 2
    // Available time range 5...500
    const frameData = [2, frameIndex, 500, Math.round(totalDuration / 1000)];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async runBlinkEffect(frameIndex, totalDuration) {
    // frameData[0] - FX index = 3
    // Available time range 5...255
    const frameData = [3, frameIndex, 200, Math.round(totalDuration / 1000)];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async runGlowEffect(frameIndex, totalDuration) {
    // frameData[0] - FX index = 4
    const periodDuration = 10;

    const frameData = [
      4,
      frameIndex,
      periodDuration,
      Math.round(totalDuration / 1000),
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async runSpinEffect(
    frameIndex,
    totalDuration,
    counterClockwiseDirection = 0
  ) {
    // frameData[0] - FX index = 4
    // Available time range 5...100
    const timePeriod = 80;

    const frameData = [
      5,
      frameIndex,
      timePeriod,
      Math.round(totalDuration / 1000),
      counterClockwiseDirection,
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async SpectrumAlgoFunction(totalDuration, intensity = 100, mirrored = 1) {
    const frameIndex = 11;
    const timePeriod = 100;

    // Available time range 5...150
    const frameData = [
      frameIndex,
      timePeriod,
      Math.round(totalDuration / 1000),
      convertPercentToCommandValue(intensity),
      mirrored ? 1 : 0,
    ];

    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async discoAlgoFunction(totalDuration, saturation = 100, intensity = 100) {
    const frameIndex = 12;
    const timePeriod = 200;

    const frameData = [
      frameIndex,
      timePeriod,
      Math.round(totalDuration / 1000),
      convertPercentToCommandValue(intensity),
      convertPercentToCommandValue(saturation),
    ];

    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async fireAlgoFunction(totalDuration, cooling = 100, sparkling = 100) {
    const frameIndex = 13;

    const frameData = [
      frameIndex,
      250,
      Math.round(totalDuration / 1000),
      convertPercentToCommandValue(cooling),
      convertPercentToCommandValue(sparkling),
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  // RGB -> RBG conversion
  async sparkleAlgoFunction(totalDuration, red = 255, green = 255, blue = 255) {
    const frameIndex = 14;
    const timePeriod = 250;

    // generate random color
    const frameData = [
      frameIndex,
      timePeriod,
      Math.round(totalDuration / 1000),
      red,
      blue,
      green,
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async twinkleAlgoFunction(
    totalDuration,
    redMin = 0,
    redMax = 255,
    greenMin = 0,
    greenMax = 255,
    blueMin = 0,
    blueMax = 255
  ) {
    const frameIndex = 15;
    const timePeriod = 250;

    const frameData = [
      frameIndex,
      timePeriod,
      Math.round(totalDuration / 1000),
      redMin,
      redMax,
      greenMin,
      greenMax,
      blueMin,
      blueMax,
      0,
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async rgbLoopFunction(totalDuration) {
    const frameIndex = 16;
    const timePeriod = 9;

    const frameData = [
      frameIndex,
      timePeriod,
      Math.round(totalDuration / 1000),
      255,
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async hsvLoopFunction(totalDuration) {
    const frameIndex = 17;
    const timePeriod = 10;

    const frameData = [
      frameIndex,
      timePeriod,
      Math.round(totalDuration / 1000),
      100,
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async RainbowFunction(totalDuration) {
    const frameIndex = 18;
    const timePeriod = 25;
    const repeations = Math.round(totalDuration / 1000);
    const Step_Res = 5;
    const hsv_start = 0;
    const hsv_stop = 360;
    const intensity = 50;

    const frameData = [
      frameIndex,
      timePeriod,
      repeations,
      Step_Res,
      ...convertValueToUint16(hsv_start),
      ...convertValueToUint16(hsv_stop),
      intensity,
    ];

    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async playLightShow(
    duration,
    lightShow,
    lightColor,
    secondaryColor = "rgb(0,0,0)"
  ) {
    switch (lightShow) {
      case "Starlight":
        const FRAME_0_LEDs = [
          secondaryColor,
          lightColor,
          secondaryColor,
          lightColor,
          secondaryColor,
          lightColor,
          secondaryColor,
          lightColor,
        ];
        await this.sendGBRtoFrame(0, FRAME_0_LEDs, true);
        this.runRandomShiftEffect(0, duration);
        break;
      case "Comet":
        // rainbow colors
        const FRAME_1_LEDs = [
          "rgb(255,0,0)",
          "rgb(255,165,0)",
          "rgb(255,255,0)",
          "rgb(0,128,0)",
          "rgb(0,0,255)",
          "rgb(75,0,130)",
          "rgb(148,0,211)",
          "rgb(255,182,193)",
        ];
        await this.sendGBRtoFrame(1, FRAME_1_LEDs, true);
        this.runCometEffect(1, duration);
        break;
      case "Blink":
        // effect can not be executed if all 8 LEDs are selected as active -> set color to 6 LEDs
        const FRAME_2_LEDs = [
          lightColor,
          lightColor,
          lightColor,
          lightColor,
          lightColor,
          lightColor,
          lightColor,
          lightColor,
        ];
        await this.sendGBRtoFrame(2, FRAME_2_LEDs, false);
        this.runBlinkEffect(2, duration);
        break;
      case "Glow":
        const FRAME_3_LEDs = [
          lightColor,
          lightColor,
          lightColor,
          lightColor,
          lightColor,
          lightColor,
          lightColor,
          lightColor,
        ];
        await this.sendGBRtoFrame(3, FRAME_3_LEDs, false);
        const maxVal = findMaxRGBValue(FRAME_3_LEDs);
        this.runGlowEffect(3, duration, maxVal);
        break;
      case "Spin":
        const FRAME_4_LEDs = [
          lightColor,
          lightColor,
          lightColor,
          "rgb(0,0,0)",
          "rgb(0,0,0)",
          "rgb(0,0,0)",
          "rgb(0,0,0)",
          "rgb(0,0,0)",
        ];
        await this.sendGBRtoFrame(4, FRAME_4_LEDs, true);
        this.runSpinEffect(4, duration);
        break;
      case "Spectrum":
        this.SpectrumAlgoFunction(duration);
        break;
      case "Unicorn":
        this.discoAlgoFunction(duration);
        break;
      case "Flare":
        this.fireAlgoFunction(duration);
        break;
      case "Sparkle":
        this.sparkleAlgoFunction(duration);
        break;
      case "Strobe":
        this.twinkleAlgoFunction(duration);
        break;
      case "Supernova":
        this.rgbLoopFunction(duration);
        break;
      case "Galaxy":
        this.hsvLoopFunction(duration);
        break;
      case "Rainbow":
        this.RainbowFunction(duration);
        break;
      default:
        return;
    }
  }

  async processTipRange(totalDuration, intensity, lightShow, lightColor) {
    this.startMode(totalDuration, intensity, intensity);
    this.playLightShow(totalDuration, lightShow, hexToRgb(lightColor));
  }

  async processTipPattern(pattern) {
    const {
      mode,
      duration: totalDuration,
      intensity: maxIntensity,
      lightShow,
      lightColor,
      periodDuration = 1000,
    } = pattern;

    if (!pattern.intensitiesPerStep) {
      this.startMode(totalDuration, 0, maxIntensity, mode, periodDuration);
    }
    this.playLightShow(totalDuration, lightShow, hexToRgb(lightColor));
  }
}
