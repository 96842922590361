import React, { createContext, useEffect, useCallback, useState } from "react";

const ChromeExtensionContext = createContext();

const useChromeExtension = () => {
  const context = React.useContext(ChromeExtensionContext);
  if (context === undefined) {
    throw new Error(
      "`useChromeExtension` hook must be used within a `ChromeExtensionContextProvider` component"
    );
  }
  return context;
};

export const EXTENSION_VERSION_UNKNOWN = "__unknown__";
export const EXTENSION_NOT_INSTALLED = "__not_installed__";
export const EXTENSION_MIN_VERSION = [1, 0, 5]; // [major, miner, revision]

const ChromeExtensionContextProvider = ({ children }) => {
  const [extensionVersion, setExtensionVersion] = useState(
    EXTENSION_VERSION_UNKNOWN
  );

  useEffect(() => {
    const requestVersion = () => {
      const request = {
        what: "CHROME_EXTENSION_VERSION_REQUEST",
      };
      window.postMessage(request, "*");
    };
    // Request version after the page load
    // The delay is needed to wait until the content script is started
    setTimeout(requestVersion, 300);
    // If extensition didn't report its version within 400 msecs, consider that it's not installed
    setTimeout(() => {
      setExtensionVersion((oldVersion) =>
        oldVersion === EXTENSION_VERSION_UNKNOWN
          ? EXTENSION_NOT_INSTALLED
          : oldVersion
      );
    }, 400);
  }, []);

  const onMessageHandler = useCallback((event) => {
    const { data } = event;
    const { payload, what } = data;
    if (what === "CHROME_EXTENSION_VERSION") {
      const { version } = payload;
      setExtensionVersion(version);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", onMessageHandler);
    return () => {
      window.removeEventListener("message", onMessageHandler);
    };
  }, [onMessageHandler]);

  const isExtensionInstalled = () => {
    if (extensionVersion !== EXTENSION_NOT_INSTALLED) {
      return true;
    }
  };

  const isExtensionUpToDate = () => {
    if (
      extensionVersion === EXTENSION_VERSION_UNKNOWN ||
      extensionVersion === EXTENSION_NOT_INSTALLED
    ) {
      return false;
    }
    const parts = extensionVersion.split(".");
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] < EXTENSION_MIN_VERSION[i]) {
        return false;
      } else if (parts[i] > EXTENSION_MIN_VERSION[i]) {
        return true;
      }
    }
    return true;
  };

  return (
    <ChromeExtensionContext.Provider
      value={{ extensionVersion, isExtensionInstalled, isExtensionUpToDate }}
    >
      {children}
    </ChromeExtensionContext.Provider>
  );
};

export { ChromeExtensionContextProvider, useChromeExtension };
