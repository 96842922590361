import { Amplify } from "aws-amplify";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { AuthContextProvider } from "hooks/AuthHook";
import { TipSettingsContextProvider } from "hooks/TipSettingsHook";
import { TipPatternsContextProvider } from "hooks/TipPatternsHook";
import { ConnectedDevicesContextProvider } from "hooks/ConnectedDevicesHook";
import { WebsitesContextProvider } from "hooks/WebsitesHook";
import { TipsContextProvider } from "hooks/TipsHook";
import { ChromeExtensionContextProvider } from "hooks/ChromeExtensionHook";
import { WidgetServerContextProvider } from "hooks/WidgetServerHook";
import { WidgetSettingsContextProvider } from "hooks/WidgetSettingsHook";
import { WheelOfFortuneContextProvider } from "hooks/WheelOfFortuneHook";
import { UserSettingsContextProvider } from "hooks/UserSettingsHook";
import { DeviceBatteryContextProvider } from "hooks/DeviceBatteryHook";
import { PageTitleContextProvider } from "hooks/PageTitleHook";
import { KlaviyoContextProvider } from "hooks/KlaviyoHook";

import awsconfig from "./aws-exports";

import "./App.scss";
import { WidgetClientContextProvider } from "./hooks/WidgetClientHook";
import Routes from "./routes/Routes";

Amplify.configure(awsconfig);

const App = () => {
  return (
    <div id="main">
      <WidgetClientContextProvider>
        <UserSettingsContextProvider>
          <ChromeExtensionContextProvider>
            <ConnectedDevicesContextProvider>
              <PageTitleContextProvider>
                <DeviceBatteryContextProvider>
                  <WheelOfFortuneContextProvider>
                    <TipSettingsContextProvider>
                      <TipPatternsContextProvider>
                        <WebsitesContextProvider>
                          <TipsContextProvider>
                            <AuthContextProvider>
                              <KlaviyoContextProvider>
                                <WidgetSettingsContextProvider>
                                  <WidgetServerContextProvider>
                                    <Routes />
                                    <ToastContainer
                                      hideProgressBar
                                      draggable
                                      theme="colored"
                                      style={{ width: "fit-content" }}
                                    />
                                  </WidgetServerContextProvider>
                                </WidgetSettingsContextProvider>
                              </KlaviyoContextProvider>
                            </AuthContextProvider>
                          </TipsContextProvider>
                        </WebsitesContextProvider>
                      </TipPatternsContextProvider>
                    </TipSettingsContextProvider>
                  </WheelOfFortuneContextProvider>
                </DeviceBatteryContextProvider>
              </PageTitleContextProvider>
            </ConnectedDevicesContextProvider>
          </ChromeExtensionContextProvider>
        </UserSettingsContextProvider>
      </WidgetClientContextProvider>
    </div>
  );
};

export default App;
