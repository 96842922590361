import { useEffect, useState } from "react";

const UPDATE_INTERVAL_MSEC = 60;

export const useTipCountdown = (tips) => {
  const [prevTipCount, setPrevTipCount] = useState(tips?.length);
  const [tipEndTimestamp, setTipEndTimestamp] = useState(0);
  // Tip remaining duration in msecs
  const [tipRemainingDuration, setTipRemainingDuration] = useState(0);
  const [activeTip, setActiveTip] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!tipEndTimestamp) {
        return;
      }
      const durationLeft = tipEndTimestamp - Date.now();
      if (durationLeft <= 0) {
        setTipRemainingDuration(0);
        setTipEndTimestamp(0);
        setActiveTip(null);
        return;
      }

      setTipRemainingDuration(tipEndTimestamp - Date.now());
    }, UPDATE_INTERVAL_MSEC);
    return () => {
      clearInterval(interval);
    };
  }, [tipEndTimestamp]);

  useEffect(() => {
    const tipCount = tips?.length;
    if (prevTipCount < tipCount) {
      // Incoming tip
      const tip = tips[tips.length - 1];
      setActiveTip(tip);
      const { duration } = tip;
      setTipEndTimestamp(Date.now() + duration * 1000);
    }
    setPrevTipCount(tipCount);
  }, [tips, prevTipCount]);
  return { tipRemainingDuration, activeTip };
};
