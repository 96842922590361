import React from "react";
import { cloneDeep } from "lodash";

import styles from "./style.module.scss";

import OBSTemplate from "../common/components/OBSTemplate";
import PatternsRows from "../common/components/PatternsRows";
import TipSettingsAnimation from "../common/components/TipSettingsAnimation";

import imgKiirooLogo from "./img/Kiiroo.png";
import imgSvakomLogo from "./img/svakom.png";
import imgCoin from "./img/i-coin.png";
import pattern1 from "./img/pattern-1.png";
import pattern2 from "./img/pattern-2.png";
import pattern3 from "./img/pattern-3.png";
import pattern4 from "./img/pattern-4.png";
import pattern5 from "./img/pattern-5.png";
import durImg from "./img/i-timer.png";
import countdownBg from "../template7/img/OBS-template-7-counter.png";

const settings12 = {
  coinImg: imgCoin,
  imgLogo: imgKiirooLogo,
  coinStyle: { height: 100 },
  tipValueTextColor: "-webkit-linear-gradient(#FFF6B1, #FFEC4A, #FFD928)",
  durationBgImg: durImg,
  durationBgImgStyle: { height: 100 },
  durationTextColor: "-webkit-linear-gradient(#DDFDFF, #73BAFF)",
  durationTextFontSize: 38,
  durationTextFrontWeight: 700,
  durationPadding: ["35px", "20px"],
  thumbnailStyle: { height: 100, maxWidth: 140, paddingRight: 20 },
};

const settings13 = {
  ...settings12,
  imgLogo: imgSvakomLogo,
  imgLogoStyle: { paddingTop: 100, paddingBottom: 80 },
};

const animSettings = {
  tipSenderColor: "#fff",
  countdownBgImg: countdownBg,
  countdownTextPadding: 10,
};

const WidgetComponent = ({
  preset,
  tips,
  isPreview,
  connectedDevices,
  settings,
}) => {
  const clonePreset = cloneDeep(preset);
  clonePreset.levels[0].thumbnail = pattern1;
  clonePreset.levels[1].thumbnail = pattern2;
  clonePreset.levels[2].thumbnail = pattern3;
  clonePreset.levels[3].thumbnail = pattern4;
  clonePreset.levels[4].thumbnail = pattern5;

  // hide Spot patterns for this widget
  delete clonePreset.levels[5];
  delete clonePreset.levels[6];
  delete clonePreset.levels[7];
  delete clonePreset.levels[8];

  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img
          className={styles.logo}
          src={settings.imgLogo}
          alt=""
          style={settings.imgLogoStyle}
        />
        <TipSettingsAnimation tips={tips} settings={animSettings} />
        <PatternsRows
          preset={clonePreset}
          isPreview={isPreview}
          settings={settings}
        />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"#0A0410"}
      tipSettingsRenderFunction={render}
      tipSettingsWidth={900}
      isPreview={isPreview}
    />
  );
};

export const Template12 = ({ preset, tips, connectedDevices, isPreview }) => (
  <WidgetComponent
    preset={preset}
    tips={tips}
    settings={settings12}
    connectedDevices={connectedDevices}
    isPreview={isPreview}
  />
);

export const Template13 = ({ preset, tips, connectedDevices, isPreview }) => (
  <WidgetComponent
    preset={preset}
    tips={tips}
    settings={settings13}
    connectedDevices={connectedDevices}
    isPreview={isPreview}
  />
);
