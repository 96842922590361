import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import imageEsca from "images/devices/esca-promotion.png";
import imageLumen from "images/devices/lumen-promotion.png";

const serviceUuid = "a0d70001-4c16-4ba7-977a-d394920e13a3";
const characteristicUuid = "a0d70002-4c16-4ba7-977a-d394920e13a3";

const devices = [
  { name: "ESCA2", image: imageEsca },
  { name: "Esca2", image: imageEsca },
  { name: "OhMiBod ESCA", image: imageEsca },
  { name: "OhMiBod 4.0", image: imageEsca },
  { name: "OhMiBod LUMEN", image: imageLumen },
];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Esca2 extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    const deviceRec = devices.find((d) => d.name === device.name);
    if (!deviceRec) {
      throw Error(`Device ${device.name} is not supported`);
    }
    const { image } = deviceRec;
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
  }

  static get deviceNames() {
    return devices.map((d) => d.name);
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "OhMiBod";
  }

  async write(percent) {
    const value = Math.max(0, Math.min(100, Math.round(percent)));
    const array = new Uint8Array([0x01, value]);
    await this.motorChar.writeValue(array);
  }

  async connect() {
    try {
      await super.connect(this.getBatteryCharacteristic.bind(this));
    } catch (e) {
      console.log("connect Esca2 e", e);
      return Promise.reject("connect Esca2 e: " + e);
    }
  }

  async getBattery() {
    const value = await this.batteryChar.readValue();
    return value.getUint8(0);
  }
}
