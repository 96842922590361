import React from "react";
import styles from "./style.module.scss";

import OBSTemplate from "../common/components/OBSTemplate";
import TipSettingRows from "../common/components/TipSettingRows";

import imgLogo from "./img/OBS-template-2-logo-kiiroo.png";
import imgCoin from "./img/OBS-template-2-coin.png";
import imgTimer from "./img/OBS-template-2-timer.png";
import imgToy from "./img/OBS-template-2-toy-cropped.png";

const settings = {
  coinImg: imgCoin,
  tipValueTextColor: "-webkit-linear-gradient(#fffefc, #ffec4a, #ffc400)",
  durationBgImg: imgTimer,
  durationTextColor: "-webkit-linear-gradient(#dffdff, #7ad6ff, #70aeff)",
  durationPadding: [
    ["30px", "23px"],
    ["30px", "20px"],
  ],
  intensityAssets: [
    [imgToy, null, "-webkit-linear-gradient(#fff, #ff49a3)"],
    [imgToy, null, "-webkit-linear-gradient(#fff, #ff49a3)"],
    [imgToy, null, "-webkit-linear-gradient(#fff, #ff49a3)"],
    [imgToy, null, "-webkit-linear-gradient(#fff, #ff49a3)"],
  ],
};

const WidgetComponent = ({ preset, tips, isPreview, connectedDevices }) => {
  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img className={styles.logo} src={imgLogo} alt="" />
        <TipSettingRows
          preset={preset}
          isPreview={isPreview}
          settings={settings}
        />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"#6a0e96"}
      tipSettingsRenderFunction={render}
      tipSettingsWidth={800}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
