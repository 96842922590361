import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { translations } from "i18n";
import { useTipCountdown } from "hooks/WidgetTipCountdownHook";
import { msecsToTime } from "../../../../TemplateUtils";

/* Settings 
{
  tipSenderColor: color -> (optional) Color of the text displaying who send the tip and for how many coins. (default: white)
  countdownBgImg: img -> Background image of the countdown timer.
  countdownTextColor: color -> (optional) Color of the text of the countdown timer. (default: white)
  countdownTextPadding: int -> (optional) extra padding top to vertically align the countdown text.
}
*/

const COUNTDOWNTEXT_PADDING_TOP = 40;

const TipSettingsAnimation = ({ tips, settings }) => {
  const { tipRemainingDuration, activeTip } = useTipCountdown(tips);
  const [tipSenderStyle, setTipSenderStyle] = useState({});
  const [cdContainerStyle, setCdContainerStyle] = useState({});
  const [cdTextStyle, setCdTextStyle] = useState({});

  useEffect(() => {
    if (settings.tipSenderColor) {
      if (settings.tipSenderColor.includes("gradient")) {
        setTipSenderStyle({
          backgroundImage: settings.tipSenderColor,
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          color: "transparent",
        });
      } else {
        setTipSenderStyle({
          color: settings.tipSenderColor,
        });
      }
    }
    setCdContainerStyle({
      backgroundImage: `url(${settings.countdownBgImg})`,
    });

    if (settings.countdownTextColor) {
      if (settings.countdownTextColor.includes("gradient")) {
        setCdTextStyle({
          backgroundImage: settings.countdownTextColor,
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          color: "transparent",
        });
      } else {
        setCdTextStyle({
          color: settings.countdownTextColor,
        });
      }
    }
    const padding =
      COUNTDOWNTEXT_PADDING_TOP + (settings.countdownTextPadding || 0);
    setCdTextStyle({
      ...cdTextStyle,
      paddingTop: padding,
    });
  }, [settings]);

  return (
    <React.Fragment>
      {activeTip ? (
        <div className={styles.containerTip}>
          <p className={styles.infoTipSender} style={tipSenderStyle}>
            {activeTip.sender} {translations("ObsTemplate.Sent")}&nbsp;
            {activeTip.amount} {translations("ObsTemplate.Tokens")}
          </p>
          <div className={styles.countdownContainer} style={cdContainerStyle}>
            <p className={styles.countdownText} style={cdTextStyle}>
              {msecsToTime(tipRemainingDuration)}
            </p>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default TipSettingsAnimation;
