import { useRef } from "react";
import cn from "classnames";
import { translations } from "i18n";
import Lottie from "lottie-react";

import styles from "./style.module.scss";

import matchAnimations from "../common/utils/spotMatchAnimationsHelper";
import imgLogo from "./img/logo.png";

import imgClock from "./img/clock.png";
import imgCoins from "./img/coins.png";
import imgVibration from "./img/vibration.png";

import pattern1 from "./img/I-Pattern-1.png";
import pattern2 from "./img/I-Pattern-2.png";
import pattern3 from "./img/I-Pattern-3.png";
import pattern4 from "./img/I-Pattern-4.png";
import pattern5 from "./img/I-Pattern-5.png";
import spotPattern1 from "./img/I-Pattern-6.png";
import spotPattern2 from "./img/I-Pattern-7.png";
import spotPattern3 from "./img/I-Pattern-8.png";
import spotPattern4 from "./img/I-Pattern-9.png";

const PATTERNS_IMAGES = [
  pattern1,
  pattern2,
  pattern3,
  pattern4,
  pattern5,
  spotPattern1,
  spotPattern2,
  spotPattern3,
  spotPattern4,
];

const render = (
  preset,
  tips,
  isPreview,
  widgetStep,
  templateBackground,
  templateBackgroundInner
) => {
  const animationRef = useRef();

  return (
    <>
      {(isPreview || widgetStep === 0) && (
        <div className={cn(styles.settingsContainer, templateBackground)}>
          <img className={styles.logo} src={imgLogo} alt="" />
          <div className={cn(styles.innerContainer, templateBackgroundInner)}>
            <div className={styles.tipSettingRowsHeader}>
              <div className={styles.itemContainer}>
                <img className={styles.imgCoins} src={imgCoins} alt="" />
                <div className={styles.tipSettingRowsHeaderText}>
                  {translations("SpotOBStemplate.Tokens")}
                </div>
              </div>
              <div className={styles.itemContainer}>
                <img className={styles.imgRound} src={imgClock} alt="" />
                <div className={styles.tipSettingRowsHeaderText}>
                  {translations("SpotOBStemplate.Time")}
                </div>
              </div>
              <div
                className={cn(
                  styles.itemContainer,
                  styles.itemContainerPaddingLeft
                )}
              >
                <img className={styles.imgRound} src={imgVibration} alt="" />
                <div className={styles.tipSettingRowsHeaderText}>
                  {translations("SpotOBStemplate.Vibration")}
                </div>
              </div>
            </div>
            {preset.levels.map((level, index) => {
              if (level.toy === "" || !level.active) {
                // Do not display the row if it's inactive or no toys are selected for this range
                return null;
              }
              return (
                <div
                  className={cn(
                    styles.rowContainer,
                    isPreview && styles.rowContainerWithoutOuterContainer
                  )}
                  key={index}
                >
                  <div className={styles.itemContainer}>
                    <div className={styles.priceRow}>{level.tokensAmount}</div>
                  </div>
                  <div className={styles.itemContainer}>
                    <div className={styles.durationRow}>{level.duration}s</div>
                  </div>
                  <div className={styles.itemContainer}>
                    <img
                      src={PATTERNS_IMAGES[index]}
                      alt="pattern"
                      className={styles.patternImg}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {widgetStep === 1 && !isPreview && (
        <div
          className={cn(
            styles.settingsContainer,
            styles.animationContainer,
            templateBackground
          )}
        >
          <div className={styles.senderTipped}>
            {translations("SpotOBStemplate.ThanksYouSender", {
              sender: tips[tips.length - 1]?.sender,
            })}
          </div>
          <Lottie
            lottieRef={animationRef}
            animationData={matchAnimations(tips)}
            autoplay={false}
            loop={false}
            className={styles.animation}
            onDOMLoaded={() => {
              animationRef.current.setSpeed(
                2 / tips[tips.length - 1]?.duration
              );
              animationRef.current.play();
            }}
          />
        </div>
      )}
    </>
  );
};

const createTemplate = (backgroundStyle, innerBackgroundStyle) => {
  return ({ preset, tips, isPreview, widgetStep }) => (
    <div>
      {isPreview ? (
        render(
          preset,
          tips,
          isPreview,
          widgetStep,
          backgroundStyle,
          innerBackgroundStyle
        )
      ) : (
        <div className={styles.outerContainer}>
          {render(
            preset,
            tips,
            isPreview,
            widgetStep,
            backgroundStyle,
            innerBackgroundStyle
          )}
        </div>
      )}
    </div>
  );
};

export const Template26 = createTemplate(
  styles.template21background,
  styles.template21backgroundInner
);

export const Template27 = createTemplate(
  styles.template22background,
  styles.template22backgroundInner
);

export const Template28 = createTemplate(
  styles.template23background,
  styles.template23backgroundInner
);

export const Template29 = createTemplate(
  styles.template24background,
  styles.template24backgroundInner
);

export const Template30 = createTemplate(
  styles.template25background,
  styles.template25backgroundInner
);
