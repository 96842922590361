import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

import { sendToAnalytics } from "lib/googleAnalytics";
import * as DeviceStates from "lib/devices/wrappers/DeviceStates";
import DeviceReconnectionToast from "components/DeviceReconnectionToast";
import { getDeviceDisplayName } from "lib/devices/connect";
import { translations } from "i18n";

// function that we use for one-time reconnection attepmt
// and showing notifications in background/foreground mode about device connection status
export const onGattServerDisconnected = async (objRef) => {
  // since "gattserverdisconnected" emmited on all disconnects we to come up with custom solution of detecting a way how it was disconnected
  // wait to know the way how it was disconnected, by user click or in another way (by pressing device button or out of range)
  await new Promise((r) => setTimeout(r, 100));
  const deviceName = getDeviceDisplayName(objRef.device.name);
  if (objRef.connectionState === DeviceStates.MANUALLY_DISCONNECTED) {
    // was disconnected by clicking Disconnect button on FeelPerformer.com
    // Update state in case of device disconnection
    objRef.setConnectionState(DeviceStates.DISCONNECTED);
    return;
  }

  // all others way of disconnected event
  objRef.setConnectionState(DeviceStates.DISCONNECTED);
  if (objRef.isSendingData) {
    Sentry.captureException(
      new Error(`OnGATTServerDisconnected - ${deviceName}`)
    );
    sendToAnalytics("OnGATTServerDisconnected", "device", deviceName);
  }

  if (document.visibilityState === "hidden") {
    // create a new system notification
    const notification = new Notification(
      translations("Notifications.DeviceWasDisconnected", {
        deviceName: deviceName,
      }),
      {
        body: translations("Notifications.TryingToReconnect"),
        icon: "/favicon.ico",
      }
    );
    setTimeout(() => {
      notification.close();
    }, 10000);
  }

  const toastId = toast(<DeviceReconnectionToast deviceName={deviceName} />, {
    closeButton: true,
    autoClose: false,
    style: { backgroundColor: "#ff8e88" },
  });

  // There is a bug from chromium where we cannot reconnect to the device after ~2,5 minutes of attempting
  // we need to reload the page
  // see details here https://bugs.chromium.org/p/chromium/issues/detail?id=681435
  const refreshPageAfter2Minutes = setTimeout(() => {
    window.location.reload();
  }, 140 * 1000);

  objRef
    .connect()
    .then((result) => {
      objRef.setConnectionState(DeviceStates.CONNECTED);

      if (document.visibilityState === "hidden") {
        // create a new system notification
        const notification = new Notification(
          translations("Notifications.DeviceIsConnected", {
            deviceName: deviceName,
          }),
          {
            icon: "/favicon.ico",
          }
        );

        setTimeout(() => {
          notification.close();
        }, 5000);
      }
      toast.update(toastId, {
        render: () => (
          <div>
            {translations("Notifications.DeviceIsConnected", {
              deviceName: deviceName,
            })}
          </div>
        ),
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        style: { backgroundColor: "#07bc0c" },
      });
    })
    .catch((err) => {
      objRef.setConnectionState(DeviceStates.DISCONNECTED);
      if (document.visibilityState === "hidden") {
        // create a new system notification
        const notification = new Notification(
          translations("Notifications.DeviceWasDisconnected", {
            deviceName: deviceName,
          }),
          {
            body: translations("Notifications.TryToReconnectManually"),
            icon: "/favicon.ico",
          }
        );
        setTimeout(() => {
          notification.close();
        }, 5000);
      }
      toast.update(toastId, {
        render: () => <DeviceReconnectionToast deviceName={deviceName} fail />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        style: { backgroundColor: "#e74c3c" },
      });
    })
    .finally(() => {
      clearTimeout(refreshPageAfter2Minutes);
    });
};
