/**
 * This hook updates the window title based on the connected devices
 */
import React, { createContext, useEffect, useState } from "react";

import { CreateHook } from "utils/utils";
import { useConnectedDevices } from "hooks/ConnectedDevicesHook";

type Props = {
  children: React.ReactNode;
};

export interface PageTitleContextInterface {
  pageTitle: string;
}

const WINDOW_TITLE = "FeelPerformer";

const PageTitleContext = createContext<PageTitleContextInterface | null>(null);
const usePageTitle = () => CreateHook("usePageTitle", PageTitleContext);

const PageTitleContextProvider = (props: Props) => {
  const { connectedDevices } = useConnectedDevices();

  const [pageTitle, setPageTitle] = useState(WINDOW_TITLE);

  useEffect(() => {
    if (connectedDevices.length) {
      // Prefix the page title with the connected devices
      const deviceList = connectedDevices.map((device) => device.name);
      const deviceListStr = deviceList.join(", ");
      setPageTitle(`${deviceListStr} - ${WINDOW_TITLE}`);
    } else {
      setPageTitle(WINDOW_TITLE);
    }
  }, [connectedDevices.length]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <PageTitleContext.Provider value={{ pageTitle }}>
      {props.children}
    </PageTitleContext.Provider>
  );
};

export { PageTitleContextProvider, usePageTitle };
