import starlight from "../animations/spot-light-shows/Starlight.json";
import comet from "../animations/spot-light-shows/Comet.json";
import glow from "../animations/spot-light-shows/Glow.json";
import spin from "../animations/spot-light-shows/Spin.json";
import spectrum from "../animations/spot-light-shows/Spectrum.json";
import unicorn from "../animations/spot-light-shows/Unicorn.json";
import flare from "../animations/spot-light-shows/Flare.json";
import sparkle from "../animations/spot-light-shows/Sparkle.json";
import strobe from "../animations/spot-light-shows/Twinkle.json";
import supernova from "../animations/spot-light-shows/Supernova.json";
import galaxy from "../animations/spot-light-shows/Galaxy.json";
import rainbow from "../animations/spot-light-shows/Rainbow.json";

import blinkBlue from "../animations/spot-light-shows/Blink/Blink-blue.json";
import blinkCyan from "../animations/spot-light-shows/Blink/Blink-cyan.json";
import blinkGreen from "../animations/spot-light-shows/Blink/Blink-green.json";
import blinkMagenta from "../animations/spot-light-shows/Blink/Blink-magenta.json";
import blinkOrange from "../animations/spot-light-shows/Blink/Blink-orange.json";
import blinkPink from "../animations/spot-light-shows/Blink/Blink-pink.json";
import blinkRed from "../animations/spot-light-shows/Blink/Blink-red.json";
import blinkViolet from "../animations/spot-light-shows/Blink/Blink-violet.json";
import blinkYellow from "../animations/spot-light-shows/Blink/Blink-yellow.json";

import glowBlue from "../animations/spot-light-shows/Glow/Glow-blue.json";
import glowCyan from "../animations/spot-light-shows/Glow/Glow-cyan.json";
import glowGreen from "../animations/spot-light-shows/Glow/Glow-green.json";
import glowMagenta from "../animations/spot-light-shows/Glow/Glow-magenta.json";
import glowOrange from "../animations/spot-light-shows/Glow/Glow-orange.json";
import glowPink from "../animations/spot-light-shows/Glow/Glow-pink.json";
import glowRed from "../animations/spot-light-shows/Glow/Glow-red.json";
import glowViolet from "../animations/spot-light-shows/Glow/Glow-violet.json";
import glowYellow from "../animations/spot-light-shows/Glow/Glow-yellow.json";

import spinBlue from "../animations/spot-light-shows/Spin/Spin-blue.json";
import spinCyan from "../animations/spot-light-shows/Spin/Spin-cyan.json";
import spinGreen from "../animations/spot-light-shows/Spin/Spin-green.json";
import spinMagenta from "../animations/spot-light-shows/Spin/Spin-magenta.json";
import spinOrange from "../animations/spot-light-shows/Spin/Spin-orange.json";
import spinPink from "../animations/spot-light-shows/Spin/Spin-pink.json";
import spinRed from "../animations/spot-light-shows/Spin/Spin-red.json";
import spinViolet from "../animations/spot-light-shows/Spin/Spin-violet.json";
import spinYellow from "../animations/spot-light-shows/Spin/Spin-yellow.json";

const LIGHT_SHOW_TITLES = [
  "Starlight",
  "Comet",
  "Blink",
  "Glow",
  "Spin",
  "Spectrum",
  "Unicorn",
  "Flare",
  "Sparkle",
  "Strobe",
  "Supernova",
  "Galaxy",
  "Rainbow",
];

export default (tips) => {
  if (!tips[tips.length - 1]) {
    return;
  }
  const lightColor = (
    tips[tips.length - 1].lightColor || "Blink"
  ).toUpperCase();

  // Blink
  if (tips[tips.length - 1].lightShow === "Blink") {
    if (lightColor === "#CC0000") {
      return blinkRed;
    } else if (lightColor === "#CC8400") {
      return blinkOrange;
    } else if (lightColor === "#CCCC00") {
      return blinkYellow;
    } else if (lightColor === "#00CC00") {
      return blinkGreen;
    } else if (lightColor === "#00CCCC") {
      return blinkCyan;
    } else if (lightColor === "#0000CC") {
      return blinkBlue;
    } else if (lightColor === "#6600CC") {
      return blinkViolet;
    } else if (lightColor === "#CC00CC") {
      return blinkMagenta;
    } else if (lightColor === "#CC99A3") {
      return blinkPink;
    }
  }

  // // Glow
  // if (tips[tips.length - 1].lightShow === "Glow") {
  //   if (lightColor === "#CC0000") {
  //     return glowRed;
  //   } else if (lightColor === "#CC8400") {
  //     return glowOrange;
  //   } else if (lightColor === "#CCCC00") {
  //     return glowYellow;
  //   } else if (lightColor === "#00CC00") {
  //     return glowGreen;
  //   } else if (lightColor === "#00CCCC") {
  //     return glowCyan;
  //   } else if (lightColor === "#0000CC") {
  //     return glowBlue;
  //   } else if (lightColor === "#6600CC") {
  //     return glowViolet;
  //   } else if (lightColor === "#CC00CC") {
  //     return glowMagenta;
  //   } else if (lightColor === "#CC99A3") {
  //     return glowPink;
  //   }
  // }

  // // Spin
  // if (tips[tips.length - 1].lightShow === "Spin") {
  //   if (lightColor === "#CC0000") {
  //     return spinRed;
  //   } else if (lightColor === "#CC8400") {
  //     return spinOrange;
  //   } else if (lightColor === "#CCCC00") {
  //     return spinYellow;
  //   } else if (lightColor === "#00CC00") {
  //     return spinGreen;
  //   } else if (lightColor === "#00CCCC") {
  //     return spinCyan;
  //   } else if (lightColor === "#0000CC") {
  //     return spinBlue;
  //   } else if (lightColor === "#6600CC") {
  //     return spinViolet;
  //   } else if (lightColor === "#CC00CC") {
  //     return spinMagenta;
  //   } else if (lightColor === "#CC99A3") {
  //     return spinPink;
  //   }
  // }

  // eslint-disable-next-line default-case
  switch (tips[tips.length - 1].lightShow) {
    case LIGHT_SHOW_TITLES[0]:
      return starlight;
    case LIGHT_SHOW_TITLES[1]:
      return comet;
    case LIGHT_SHOW_TITLES[3]:
      return glow;
    case LIGHT_SHOW_TITLES[4]:
      return spin;
    case LIGHT_SHOW_TITLES[5]:
      return spectrum;
    case LIGHT_SHOW_TITLES[6]:
      return unicorn;
    case LIGHT_SHOW_TITLES[7]:
      return flare;
    case LIGHT_SHOW_TITLES[8]:
      return sparkle;
    case LIGHT_SHOW_TITLES[9]:
      return strobe;
    case LIGHT_SHOW_TITLES[10]:
      return supernova;
    case LIGHT_SHOW_TITLES[11]:
      return galaxy;
    case LIGHT_SHOW_TITLES[12]:
      return rainbow;
  }
};
