import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/pearl2-promotion.png";

const serviceUuid = "88f82580-0000-01e6-aace-0002a5d5c51b"; // ble service id for sensor data
const characteristicUuid = "88f82581-0000-01e6-aace-0002a5d5c51b"; // motor controlling characteristic
const names = ["Pearl2"];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Pearl2 extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
  }

  static get deviceNames() {
    return names;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "Kiiroo";
  }

  async write(percent) {
    const value = Math.max(0, Math.min(99, Math.round(percent)));
    const array = new Uint8Array([value, value, 0]);
    return this.motorChar.writeValue(array);
  }
}
