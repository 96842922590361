import Template1 from "./templates/template1";
import Template2 from "./templates/template2";
import Template3 from "./templates/template3";
import Template4 from "./templates/template4";
import {
  Template5,
  Template6,
  Template15,
  Template16,
} from "./templates/template5&6&15&16";
import Template7 from "./templates/template7";
import Template8 from "./templates/template8";
import Template9 from "./templates/template9";

import { Template10, Template11 } from "./templates/template10&11";
import { Template12, Template13 } from "./templates/template12&13";
import Template14 from "./templates/template14";
import Template17 from "./templates/template17";
import Template18 from "./templates/template18";
import Template19 from "./templates/template19";
import Template20 from "./templates/template20";
import {
  Template21,
  Template22,
  Template23,
  Template24,
  Template25,
} from "./templates/template21&22&23&24&25";
import {
  Template26,
  Template27,
  Template28,
  Template29,
  Template30,
} from "./templates/template26&27&28&29&30";

const templates = {
  0: Template1,
  1: Template2,
  2: Template3,
  3: Template4,
  4: Template5,
  5: Template6,
  6: Template7,
  7: Template8,
  8: Template9,
  9: Template10,
  10: Template11,
  11: Template12,
  12: Template13,
  13: Template14,
  14: Template15,
  15: Template16,
  16: Template18,
  17: Template17,
  18: Template19,
  19: Template20,
  20: Template21,
  21: Template22,
  22: Template23,
  23: Template24,
  24: Template25,
  25: Template26,
  26: Template27,
  27: Template28,
  28: Template29,
  29: Template30,
};

/**
 * This component selects which template to show based on
 * |template| parameter
 */
const WidgetComponent = ({
  preset,
  template,
  widgetStep,
  tips,
  isPreview,
  connectedDevices,
}) => {
  const templateId = template.id;
  const Template = templates[templateId];

  return (
    <Template
      preset={preset}
      template={template}
      widgetStep={widgetStep}
      tips={tips}
      isPreview={isPreview}
      connectedDevices={connectedDevices}
    />
  );
};

export default WidgetComponent;
