import React from "react";

import styles from "./style.module.scss";
import { ReactComponent as Icon } from "images/battery.svg";
import { translations } from "i18n";

const DeviceBatteryNotification = ({ device }) => {
  return (
    <div className={styles.container}>
      <img src={device.image} alt="device" className={styles.deviceImg} />
      <div className={styles.indicator} />
      <div className={styles.infoContainer}>
        <div className={styles.titleContainer}>
          <Icon />
          <div className={styles.title}>
            {translations("Notifications.OopsYourBatteryIsLow", {
              deviceName: device.name,
            })}
          </div>
        </div>
        <div className={styles.description}>
          {translations(
            "Notifications.ToAvoidDisappointmentPutYourDeviceOnCharge"
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceBatteryNotification;
