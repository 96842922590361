import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { KLAVIYO_ENDPOINT } from "data/constants";
import { useAuth } from "./AuthHook";

const KlaviyoContext = createContext();

const useKlaviyo = () => {
  const context = useContext(KlaviyoContext);
  if (!context) {
    throw new Error("`useKlaviyo` must be used within `KlaviyoContextProvider`");
  }
  return context;
};

const KlaviyoContextProvider = ({ children }) => {
  const { authenticatedUser } = useAuth();
  const [token, setToken] = useState("");

  // Simplify localStorage key management by directly using user email
  const getLocalStorageKey = (type, email) => `fp_klaviyo_${type}:${email}`;

  const updateToken = useCallback(async () => {
    if (authenticatedUser) {
      const sessionToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      setToken(sessionToken);
    } else {
      setToken("");
    }
  }, [authenticatedUser]);

  useEffect(() => {
    updateToken();
  }, [updateToken]);

  const isSubscribed = async () => {
    if (!authenticatedUser) return false;
    try {
      const email = authenticatedUser.attributes.email;
      const response = await fetch(`${KLAVIYO_ENDPOINT}?email=${email}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": token,
        },
      });
      const json = await response.json();
      return json.message.includes("YES");
    } catch (error) {
      console.error("Error checking subscription status:", error);
      return false;
    }
  };

  const checkSubscription = async () => {
    return await isSubscribed();
  };

  const subscribe = async () => {
    if (!authenticatedUser) return;
    try {
      const email = authenticatedUser.attributes.email;
      await fetch(KLAVIYO_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": token,
        },
        body: JSON.stringify({
          action: "subscribe",
          email,
        }),
      });
    } catch (error) {
      console.error("Error subscribing user:", error);
    }
  };

  const hasDenied = () => {
    if (!authenticatedUser) return false;
    const deniedKey = getLocalStorageKey("denied", authenticatedUser.attributes.email);
    return !!localStorage.getItem(deniedKey);
  };

  const setDenied = () => {
    if (!authenticatedUser) return;
    const deniedKey = getLocalStorageKey("denied", authenticatedUser.attributes.email);
    localStorage.setItem(deniedKey, "1");
  };

  const hasAccepted = () => {
    if (!authenticatedUser) return false;
    const acceptedKey = getLocalStorageKey("accepted", authenticatedUser.attributes.email);
    return !!localStorage.getItem(acceptedKey);
  };

  const setAccepted = () => {
    if (!authenticatedUser) return;
    const acceptedKey = getLocalStorageKey("accepted", authenticatedUser.attributes.email);
    localStorage.setItem(acceptedKey, "1");
  };

  const removeAcceptedKey = () => {
    if (!authenticatedUser) return;
    const acceptedKey = getLocalStorageKey("accepted", authenticatedUser.attributes.email);
    localStorage.removeItem(acceptedKey);
  };

  return (
    <KlaviyoContext.Provider value={{
      subscribe,
      unsubscribe: async () => {}, // If needed
      checkSubscription,
      hasDenied,
      setDenied,
      hasAccepted,
      setAccepted,
      removeAcceptedKey,
    }}>
      {children}
    </KlaviyoContext.Provider>
  );
};

export { KlaviyoContextProvider, useKlaviyo };
