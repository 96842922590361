// TODO move to WidgetPage
// TODO separate general teplates and Spot templates
export const presetsTemplates = [
  { id: 20, name: "Spot - Aqua" },
  { id: 21, name: "Spot - Desert Sand" },
  { id: 22, name: "Spot - Rose" },
  { id: 23, name: "Spot - Lavender" },
  { id: 24, name: "Spot - Heather Haze" },
  { id: 0, name: "Default - Svakom" },
  { id: 1, name: "Purple Brick Wall - Kiiroo" },
  { id: 2, name: "Snowy Tips" },
  { id: 3, name: "Winter Wonderland" },
  { id: 4, name: "Lovers - Kiiroo" },
  { id: 5, name: "Lovers - Svakom" },
  { id: 6, name: "White - Kiiroo" },
  { id: 7, name: "Gray - Kiiroo" },
  { id: 8, name: "Moody - Kiiroo" },
];

export const patternsTemplates = [
  { id: 25, name: "Spot - Aqua" },
  { id: 26, name: "Spot - Desert Sand" },
  { id: 27, name: "Spot - Rose" },
  { id: 28, name: "Spot - Lavender" },
  { id: 29, name: "Spot - Heather Haze" },
  { id: 9, name: "Default - Kiiroo" },
  { id: 10, name: "Default - Svakom" },
  { id: 11, name: "Black - Kiiroo" },
  { id: 12, name: "Black - Svakom" },
  { id: 13, name: "Snowy Tips" },
  { id: 14, name: "Lovers - Kiiroo" },
  { id: 15, name: "Lovers - Svakom" },
];

export const wheelOfFortuneTemplates = [
  { id: 16, name: "Default - Kiiroo" },
  { id: 17, name: "Default - Kiiroo" },
  { id: 18, name: "Default - Kiiroo" },
  { id: 19, name: "Default - Kiiroo" },
];
