/**
 * Find vibration level for the given amount
 * @param {array} levels - array of vibration levels
 * @param {int} amount - tip amount
 * @returns vibration level corresponding to this amount
 */
export const findLevelForAmount = (levels, amount) => {
  const sortedLevels = [...levels];
  // Sort by rangeMax
  sortedLevels.sort((a, b) => {
    if (a.rangeMax === "Max") {
      return 1;
    }
    if (b.rangeMax === "Max") {
      return -1;
    }
    return Number(a.rangeMax) - Number(b.rangeMax);
  });

  let result = null;
  // Find the first range where amount falls in
  sortedLevels.some((level) => {
    if (amount <= Number(level.rangeMax) || level.rangeMax === "Max") {
      result = level;
      return true;
    }

    return false;
  });
  return result;
};

/**
 * Find vibration pattern for the given amount. It includes the check if we searching pattern for lightShow (the Spot) devices or not
 * @param {array} levels - array of vibration levels
 * @param {int} amount - tip amount
 * @param {boolean} skipLightShow - flag indicating if we should skip patterns for lightShow devices or not
 * @returns vibration level corresponding to this amount
 */
export const findClosestPatternWithHigherPrice = (
  levels,
  amount,
  skipLightShow
) => {
  const foundClosest = levels.reduce(
    (prev, { tokensAmount, active, intensitiesPerStep }) => {
      if (skipLightShow && !intensitiesPerStep) {
        // skip levels that do support lightShow when no lightShow devices are connected
        return prev;
      }
      if (!active) {
        return prev;
      }
      if (prev > +tokensAmount) {
        return prev;
      }
      if (+amount >= +tokensAmount && (!prev || +amount > prev)) {
        return +tokensAmount;
      }
      return prev;
    },
    null
  );

  return levels.find(({ tokensAmount }) => +tokensAmount === foundClosest);
};
