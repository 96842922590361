import React, { lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { ROUTE_PATHS } from "data/constants";
import Loader from "components/Loader/Loader";
import PrivateRoute from "./PrivateRoute";

const LandingPage = lazy(() => import("containers/LandingPage"));
const SignUpPage = lazy(() => import("containers/SignUpPage"));
const LoginPage = lazy(() => import("containers/LoginPage"));
const ForgotPasswordPage = lazy(() => import("containers/ForgotPasswordPage"));
const ConfirmEmailPage = lazy(() => import("containers/ConfirmEmailPage"));
const ResendConfirmationEmailPage = lazy(() =>
  import("containers/ResendConfirmationEmail")
);
const RecoveryPasswordPage = lazy(() =>
  import("containers/RecoveryPasswordPage")
);
const ConfirmEmailFormPage = lazy(() =>
  import("containers/ConfirmEmailFormPage")
);
const MenuContainer = lazy(() => import("containers/MenuContainer"));
const DocumentPage = lazy(() => import("containers/DocumentPage"));

const Routes = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <PrivateRoute
            path={ROUTE_PATHS.LANDING}
            component={LandingPage}
            exact
            shouldBeLoggedIn={false}
          />
          <PrivateRoute
            path={ROUTE_PATHS.SIGN_UP}
            component={isMobile ? LandingPage : SignUpPage}
            exact
            shouldBeLoggedIn={false}
          />
          <PrivateRoute
            path={ROUTE_PATHS.RESEND_CONFIRMATION_EMAIL}
            component={ResendConfirmationEmailPage}
            exact
            shouldBeLoggedIn={false}
          />
          <PrivateRoute
            path={ROUTE_PATHS.CONFIRM_EMAIL_FORM}
            component={ConfirmEmailFormPage}
            exact
            shouldBeLoggedIn={false}
          />
          <PrivateRoute
            path={ROUTE_PATHS.LOGIN}
            component={isMobile ? LandingPage : LoginPage}
            exact
            shouldBeLoggedIn={false}
          />
          <PrivateRoute
            path={ROUTE_PATHS.FORGOT_PASSWORD}
            component={ForgotPasswordPage}
            exact
            shouldBeLoggedIn={false}
          />
          <PrivateRoute
            path={ROUTE_PATHS.CONFIRM_EMAIL}
            component={ConfirmEmailPage}
            exact
            shouldBeLoggedIn={false}
          />
          <PrivateRoute
            path={ROUTE_PATHS.RECOVERY_PASSWORD}
            component={RecoveryPasswordPage}
            exact
            shouldBeLoggedIn={false}
          />
          <PrivateRoute
            path={ROUTE_PATHS.DASHBOARD}
            component={MenuContainer}
            shouldBeLoggedIn={true}
          />
          <Route
            path={ROUTE_PATHS.TERMS_AND_CONDITIONS}
            component={DocumentPage}
            exact
          />
          <Route
            path={ROUTE_PATHS.PRIVACY_POLICY}
            component={DocumentPage}
            exact
          />
        </Switch>
      </Router>
    </React.Suspense>
  );
};

export default Routes;
