import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { translations } from "i18n";

import { selectIntensity } from "../../../../TemplateUtils";

/* Settings Object:
{
  coinImg: img -> (optional) Image of the coin displayed in from of tip ranges.
  tipValueTextColor: color -> color of the text displaying the tip ranges.
  durationBgImg: img -> Background image to display the duration of a tip setting.
  durationTextColor: color -> Color of the text displaying the duration of a tip 
                            setting.
  durationPadding: [] -> Array containing the padding value for the duration text. 
                        format: [
                          [padding_top_preview, padding_top_normal], 
                          [padding_right_preview, padding_right_normal]
                        ]
  intensityAssets: [] -> Array containing the art assets needed to build the 
                         intensity column of the tip settings display. Depending on 
                         the type of tipdisplay (regular or image only) this array 
                         needs to have the following structure:
                         regular: [
                           [left_image, right_image, text_color] -> one row per intensity (low, medium, high)
                         ]
                         image only: [
                          intensity_image_low,
                          intensity_image_medium,
                          etc..
                         ]
} */

const TipSettingRows = ({ preset, settings, isPreview = false }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const levels = preset.levels || [];
    setRows(
      levels.map((level, index) => {
        if (level.toy === "") {
          // Do not display the row if no toys are selected for this range
          return null;
        }

        const [text, imgLeft, imgRight, styleIntensity, intensityTextColor] =
          getIntensityAssets(level, settings.intensityAssets);

        if (!text) {
          return null;
        }

        const durationBgImg = `url(${settings.durationBgImg})`;
        let timeStyle = {
          color: settings.durationTextColor,
          paddingTop: isPreview
            ? settings.durationPadding[0][0]
            : settings.durationPadding[0][1],
          paddingRight: isPreview
            ? settings.durationPadding[1][0]
            : settings.durationPadding[1][1],
        };
        if (settings.durationTextColor.includes("gradient")) {
          timeStyle = {
            ...timeStyle,
            backgroundImage: settings.durationTextColor,
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          };
        }

        let tipValueTextStyle = {
          color: settings.tipValueTextColor,
        };
        if (settings.tipValueTextColor.includes("gradient")) {
          tipValueTextStyle = {
            backgroundImage: settings.tipValueTextColor,
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          };
        }

        let intensityStyle = {
          color: intensityTextColor,
        };
        if (intensityTextColor?.includes("gradient")) {
          intensityStyle = {
            backgroundImage: intensityTextColor,
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          };
        }

        const prev = index ? Number(levels[index - 1].rangeMax) + 1 : 1;
        const current = level.rangeMax; // Could be 'Max'
        return (
          <div className={styles.settingsRow} key={index}>
            <div className={styles.tipsRange}>
              <img className={styles.coin} src={settings.coinImg} alt="" />
              <p className={styles.tips} style={tipValueTextStyle}>
                {prev === current ? prev : `${prev}-${current}`}
              </p>
            </div>
            <div
              className={styles.timer}
              style={{ backgroundImage: durationBgImg }}
            >
              <p className={styles.timeInfo} style={timeStyle}>
                {level.duration}
                {translations("ObsTemplate.Seconds")}
              </p>
            </div>
            <div className={styles.intensity}>
              <img
                className={styles.intensityImg}
                src={imgLeft}
                alt=""
                style={{ height: intensityTextColor ? "60px" : "80px" }}
              />
              {intensityTextColor && <p style={intensityStyle}>{text}</p>}
              <img className={styles.intensityImg} src={imgRight} alt="" />
            </div>
          </div>
        );
      })
    );
  }, [settings, isPreview, preset.levels]);

  return <React.Fragment>{rows}</React.Fragment>;
};

export default TipSettingRows;

function getIntensityAssets(level, array) {
  if (getArrayDepth(array) > 1) {
    // The current widget is one with text for intensity
    return selectIntensity(level.intensity, [
      [null, null, null, null, null],
      [
        translations("ObsTemplate.Low"),
        array[0][0],
        array[0][1],
        styles.intensityLow,
        array[0][2],
      ],
      [
        translations("ObsTemplate.Medium"),
        array[1][0],
        array[1][1],
        styles.intensityMedium,
        array[1][2],
      ],
      [
        translations("ObsTemplate.High"),
        array[2][0],
        array[2][1],
        styles.intensityHigh,
        array[2][2],
      ],
      [
        translations("ObsTemplate.UltraHigh"),
        array[3][0],
        array[3][1],
        styles.intensityHigh,
        array[3][2],
      ],
    ]);
  } else {
    // The current widget is one with only an image for intensity
    return selectIntensity(level.intensity, [
      [null, null, null, null, null],
      [
        translations("ObsTemplate.Low"),
        array[0],
        null,
        styles.intensityLow,
        null,
      ],
      [
        translations("ObsTemplate.Medium"),
        array[1],
        null,
        styles.intensityMedium,
        null,
      ],
      [
        translations("ObsTemplate.High"),
        array[2],
        null,
        styles.intensityHigh,
        null,
      ],
      [
        translations("ObsTemplate.UltraHigh"),
        array[3],
        null,
        styles.intensityHigh,
        null,
      ],
    ]);
  }
}

function getArrayDepth(value) {
  return Array.isArray(value) ? 1 + Math.max(...value.map(getArrayDepth)) : 0;
}
