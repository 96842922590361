import React from "react";
import styles from "./style.module.scss";

import OBSTemplate from "../common/components/OBSTemplate";
import TipSettingRows from "../common/components/TipSettingRows";
import TipSettingsAnimation from "../common/components/TipSettingsAnimation";

import imgLogo from "./img/OBS-template-3-logo.png";
import imgCoin from "./img/OBS-template-3-coin.png";
import imgLeft1 from "./img/OBS-template-3-left-1.png";
import imgRight1 from "./img/OBS-template-3-right-1.png";
import imgLeft2 from "./img/OBS-template-3-left-2.png";
import imgRight2 from "./img/OBS-template-3-right-2.png";
import durationBgImg from "./img/OBS-template-3-timer.png";

import countdownBg from "./img/OBS-template-3-counter.png";

const settings = {
  coinImg: imgCoin,
  durationBgImg: durationBgImg,
  durationTextColor: "#fff",
  durationPadding: [
    ["30px", "25px"],
    ["10px", "10px"],
  ],
  tipValueTextColor: "-webkit-linear-gradient(#ffffff, #d0e3ff, #d0e3ff)",
  intensityAssets: [
    [imgLeft1, imgRight1, "-webkit-linear-gradient(#ffffff, #d0e3ff, #d0e3ff)"],
    [imgLeft1, imgRight1, "-webkit-linear-gradient(#ffffff, #d0e3ff, #d0e3ff)"],
    [imgLeft2, imgRight2, "-webkit-linear-gradient(#f6d9ff, #f6d9ff, #e7b0ff)"],
    [imgLeft2, imgRight2, "-webkit-linear-gradient(#f6d9ff, #f6d9ff, #e7b0ff)"],
  ],
};

const animSettings = {
  tipSenderColor: "-webkit-linear-gradient(#f6d9ff, #f6d9ff, #e7b0ff)",
  countdownBgImg: countdownBg,
  countdownTextColor: "#fff",
};

const WidgetComponent = ({ preset, tips, isPreview, connectedDevices }) => {
  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img className={styles.logo} src={imgLogo} alt="" />
        <TipSettingRows
          preset={preset}
          settings={settings}
          isPreview={isPreview}
        />
        <TipSettingsAnimation tips={tips} settings={animSettings} />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      isPreview={isPreview}
      animationBgColor={"#222585"}
      animationTextColor={"#d0e3ff"}
      tipSettingsRenderFunction={render}
      tipSettingsWidth={850}
    />
  );
};

export default WidgetComponent;
