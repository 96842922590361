import React, { useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

const PrivateRoute = (props) => {
  let location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsAuthenticated(true);
        setUser(user);
        setIsLoading(false);
      })
      .catch(() => {
        setIsAuthenticated(false);
        setUser(null);
        setIsLoading(false);
      });
  }, [location]);

  if (isLoading) {
    return null;
  }

  const { component: Component, shouldBeLoggedIn, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => {
        return shouldBeLoggedIn === isAuthenticated ? (
          <Component {...props} user={user} />
        ) : (
          <Redirect to={shouldBeLoggedIn ? "/login" : "/dashboard"} />
        );
      }}
    />
  );
};

export default PrivateRoute;
