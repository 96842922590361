import { sendToAnalytics } from "lib/googleAnalytics";
import { BaseDeviceWrapper } from "./BaseDeviceWrapper";

import * as DeviceStates from "./DeviceStates";
import image from "images/devices/prowand-promotion.png";

const names = ["PowerShot"];

const POWERSHOT_SERVICE_UUID = 0x1400;
const ACTUATOR_MOTOR_UUID = 0x1801;
const CHANGE_NAME_UUID = 0x2a01;
const BATTERY_LEVEL_UUID = 0x2a19;

export default class PowerShot extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(
      device,
      POWERSHOT_SERVICE_UUID,
      ACTUATOR_MOTOR_UUID,
      image,
      isSendingData
    );
    this.defaultDeviceName = "PowerShot";
  }

  setSendingData(isSendingData) {
    return !!isSendingData;
  }

  static get deviceNames() {
    return names;
  }

  static get services() {
    return [POWERSHOT_SERVICE_UUID];
  }

  get companyName() {
    return "Kiiroo";
  }

  /**
   * Set new connection state and notify the callback
   * @param {string} newState - new state from DeviceStates.*
   */
  setConnectionState(newState) {
    this.connectionState = newState;
    this.connectionStateCallback?.();
  }

  /**
   * Connect to the device and discover the service and characteristic
   */
  async connect() {
    try {
      await super.connect(async () => {
        // change name
        this.overrideNameCharacteristic =
          await this.sensorService.getCharacteristic(CHANGE_NAME_UUID);
        // battery
        this.batteryCharacteristic = await this.sensorService.getCharacteristic(
          BATTERY_LEVEL_UUID
        );
      });
    } catch (e) {
      return Promise.reject("connect PleasureDrive e: " + e);
    }
  }

  /**
   * Disconnect from the device
   */
  async disconnect() {
    this.setConnectionState(DeviceStates.DISCONNECTING);
    if (this.device.gatt.connected) {
      await this.device.gatt.disconnect();
    }
    this.setConnectionState(DeviceStates.MANUALLY_DISCONNECTED);
    sendToAnalytics("device_disconnect", "device", this.device.name);
  }

  async getBattery() {
    const value = await this.batteryCharacteristic.readValue();
    return value.getUint8(0);
  }

  // working only on Windows
  async setDeviceName(newName) {
    const hexDeviceName = newName.split("").map((c) => c.charCodeAt(0));
    await this.overrideNameCharacteristic.writeValue(
      new Uint8Array(hexDeviceName)
    );
  }

  /**
   * activate motor
   * @param {number} periodDuration - periodDuration is multiplied by 50, e.g. 10 * 50 = 500ms*
   */
  async startMode(low, high, mode = 0, periodDuration = 0) {
    const frameData = [
      mode,
      Math.round(low * 2.5), // left motor
      Math.round(low * 2.5), // right motor
      Math.round(high * 2.5), // left motor
      Math.round(high * 2.5), // right motor
      periodDuration,
    ];
    await this.motorChar.writeValue(new Uint8Array(frameData));
  }

  async write(percent) {
    if (!percent) {
      await this.motorChar.writeValue(new Uint8Array([0x00]));
      return;
    }

    await this.startMode(percent, 0);
  }
}
