import bongaCams from "images/logoBongaCams.png";
import camSoda from "images/logoCamSoda.png";
import chaturbate from "images/logoChaturbate.png";
import myFreeCams from "images/logoMyFreeCams.png";
import stripchat from "images/logoStripChat.png";
import xcams from "images/logoXcams.png";
import cam4 from "images/logoCam4.png";
import streamate from "images/logoStreamate.png";
import loyalfans from "images/logoLoyalfans.png";
import livestrip from "images/logoLiveStrip.png";
import eplay from "images/logoEplay.png";

const extract = (regEx, text, senderFirst) => {
  const m = regEx.exec(text);
  if (!m) {
    return {};
  }
  const sender = m[senderFirst ? 1 : 2];
  const tipAmount = m[senderFirst ? 2 : 1];
  return {
    sender,
    tipAmount,
  };
};
/**
 * List of the websites supported by the system
 * id - website ID, should NOT be changed in the future versions of the system in order
 *      to read settings from the local storage correctly.
 *      We can add more websites with unique IDs in the future.
 * name - website name displayed to the users
 * logo - logo image
 * hosts - hostnames of broadcasting URL, e.g. ['chaturbate.com', 'es.chaturbate.com', 'en.chaturbate.com']
 *        if broacast url is es.chaturbate.com/b/modelname
 */
const DEFAULT_WEBSITES = [
  {
    id: "bongacams",
    name: "BongaCams",
    logo: bongaCams,
    homePageOrder: 3,
    tipAmountExtractorFromText: function (text) {
      const regexes = [
        /(.*)Tipped ([0-9]*) Token.*/g,
        /(.*)Подарил ([0-9]*) Токен.*/g,
        /(.*)¡Dio ([0-9]*) Token.*/g,
        /(.*)A oferit ([0-9]*) Jeton.*/g,
        /(.*)A oferit ([0-9]*) Jetoane.*/g,
        /(.*)Hat ([0-9]*) Token.*/g,
        /(.*)A envoyé ([0-9]*) Token.*/g,
      ];

      return (
        regexes
          .map((regEx) => {
            // Try to extract sender and tip amount from the text
            // using all regexes
            return extract(regEx, text, true);
          })
          .find(({ sender, tipAmount }) => {
            // Find the result where sender and tip amount
            // are not empty
            return sender && tipAmount;
          }) || {}
      );
    },
    hosts: [
      "bongamodels.com",
      "en.bongamodels.com",
      "ru.bongamodels.com",
      "es.bongamodels.com",
      "ro.bongamodels.com",
      "de.bongamodels.com",
      "fr.bongamodels.com",
    ],
  },
  {
    id: "camsoda",
    name: "CamSoda",
    logo: camSoda,
    homePageOrder: 2,
    watchLastMessageInTextList: true,
    tipAmountExtractorFromText: function (text) {
      const regEx = /(.*) tipped ([0-9]*) tokens/g;
      return extract(regEx, text, true);
    },
    hosts: [
      "camsoda.com",
      "www.camsoda.com",
      "de.camsoda.com",
      "en.camsoda.com",
      "es.camsoda.com",
      "fr.camsoda.com",
      "it.camsoda.com",
      "nl.camsoda.com",
    ],
  },
  {
    id: "chaturbate",
    name: "Chaturbate",
    logo: chaturbate,
    homePageOrder: 0,
    deduplicate: true,
    tipAmountExtractorFromText: function (text) {
      const regEx = /([^\"].*) tipped ([0-9]*) token/g;
      return extract(regEx, text, true);
    },
    hosts: ["chaturbate.com"],
  },
  {
    id: "myfreecams",
    name: "MyFreeCams",
    logo: myFreeCams,
    homePageOrder: 1,
    activated: false,
    deduplicate: true,
    tipAmountExtractorFromText: function (text) {
      const regEx = /Received a (.*) token tip from (.*)!/g;
      return extract(regEx, text, false);
    },
    hosts: ["myfreecams.com", "www.myfreecams.com"],
  },
  {
    id: "stripchat",
    name: "Stripchat",
    logo: stripchat,
    homePageOrder: 4,
    activated: false,
    tipAmountExtractorFromText: function (text) {
      const regexes = [
        /(.*) tipped ([0-9]*) tk/g,
        /(.*) ha dato una mancia di ([0-9]*) token/g,
        /(.*) heeft een fooi gegeven ([0-9]*) tk/g,
        /(.*) har dricksat ([0-9]*) tokens/g,
        /(.*) dal\/a dýško ([0-9]*) žet./g,
        /(.*) hat ([0-9]*) tk Trinkgeld gegeben/g,
        /(.*) отправил ([0-9]*) тк/g,
        /(.*) dał(a) napiwek w wysokości ([0-9]*) żt/g,
        /Ο χρήστης (.*) άφησε φιλοδώρημα ([0-9]*) tk/g,
        /(.*) ([0-9]*) jet bahşiş verdi/g,
        /(.*) a laissé un pourboire de ([0-9]*) jt/g,
        /(.*) deu gorjeta de ([0-9]*) tk/g,
        /(.*) a oferit bacșiș ([0-9]*) jetoane/g,
        /(.*) منح ([0-9]*) عملات بقشيشًا/g,
        /(.*) 已支付([0-9]*)代币的小费/g,
        /(.*) ha dado ([0-9]*) tk de propina/g,
        /(.*) が([0-9]*)コイン をプレゼントしました/g,
        /(.*) har tipset ([0-9]*) polletter/g,
        /(.*) ([0-9]*)token jattot adott/g,
      ];

      return (
        regexes
          .map((regEx) => {
            // Try to extract sender and tip amount from the text
            // using all regexes
            return extract(regEx, text, true);
          })
          .find(({ sender, tipAmount }) => {
            // Find the result where sender and tip amount
            // are not empty
            return sender && tipAmount;
          }) || {}
      );
    },
    hosts: [
      "",
      "it",
      "nl",
      "sv",
      "cs",
      "de",
      "ru",
      "pl",
      "el",
      "tr",
      "fr",
      "pt",
      "ro",
      "ar",
      "zh",
      "es",
      "ja",
      "no",
      "hu",
    ].map((p) => (p ? `${p}.stripchat.com` : "stripchat.com")),
  },
  {
    id: "xcams",
    name: "XCams",
    logo: xcams,
    homePageOrder: 5,
    activated: false,
    deduplicate: true,
    watch5LastMessagesInTextList: true, // the tip message can be in any of the last 5 positions in the textList array (specific only for Xcams)
    tipAmountExtractorFromText: (text) => {
      const regEx = /(.*) just gave (.*) a tip: (.*) \(([0-9]*) credits\)/g;
      const m = regEx.exec(text);
      if (!m) {
        return {};
      }
      return {
        sender: m[1],
        tipAmount: m[4],
      };
    },
    hosts: ["chat.xmodels.com"],
  },
  {
    id: "cam4",
    name: "Cam4",
    logo: cam4,
    homePageOrder: 6,
    activated: false,
    deduplicate: true,
    tipAmountExtractorFromText: function (text) {
      const regEx = /(.*) tipped ([0-9]*) Token/g;
      return extract(regEx, text, true);
    },
    hosts: ["www.cam4.com"],
  },
  {
    id: "streamate",
    name: "Streamate",
    logo: streamate,
    homePageOrder: 8,
    activated: false,
    tipAmountExtractorFromText: function (text) {
      const regEx = /([0-9]*).00 GOLD from (.*)/g;
      return extract(regEx, text, false);
    },
    hosts: ["performerclient.streamatemodels.com"],
  },
  {
    id: "loyalfans",
    name: "Loyalfans",
    logo: loyalfans,
    homePageOrder: 9,
    activated: false,
    deduplicate: true,
    tipAmountExtractorFromHtml: function (text) {
      const regEx =
        /data-tip-value="([0-9]+[.]?[0-9]*)">([\s\S]*)<strong>(.*)<\/strong>/g;
      const m = regEx.exec(text);
      if (!m || m.length < 4) {
        return {};
      }
      const tipAmount = m[1]?.trim();
      const sender = m[3]?.trim();
      return {
        sender,
        tipAmount,
      };
    },
    hosts: ["beta.loyalfans.com", "loyalfans.com", "www.loyalfans.com"],
  },
  {
    id: "livestrip",
    name: "Live-Strip",
    logo: livestrip,
    homePageOrder: 10,
    activated: false,
    tipAmountExtractorFromText: function (text) {
      const regEx = /(.*) has sent you a tip of ([0-9]*) Coins/g;
      return extract(regEx, text, true);
    },
    hosts: ["livestrip.com", "guppysender.live", "sender.livestrip.com"],
  },
  {
    id: "eplay",
    name: "Eplay",
    logo: eplay,
    homePageOrder: 11,
    activated: false,
    tipAmountExtractorFromText: function (text) {
      const regEx =
        /(\w+)(?: speed)? tipped(?: (\d+)x(\d+)Speed tip now|(\d+))/;

      // Using a custom extract function here because ePlay has 2 types of tips.
      // normal tips and speed tips that come in the form of 10x13 (for a total of 130)
      const match = text.match(regEx);
      if (match) {
        const username = match[1];
        let amount;
        if (match[2] && match[3]) {
          // speed tip
          amount = parseInt(match[2]) * parseInt(match[3]);
        } else {
          // normal tip
          amount = parseInt(match[4]);
        }
        return { sender: username, tipAmount: amount };
      } else {
        return {};
      }
    },
    hosts: ["www.eplay.com"],
  },
];

export default DEFAULT_WEBSITES;
