import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { translations } from "i18n";

/* Settings Object:
{
  coinImg: img -> (optional) Image of the coin displayed in from of tip ranges.
  tipValueTextColor: color -> color of the text displaying the tip ranges.
  durationBgImg: img -> Background image to display the duration of a tip setting.
  durationTextColor: color -> Color of the text displaying the duration of a tip 
                            setting.
  durationPadding: [] -> Array containing the padding value for the duration text. 
                        format: [padding_top, padding_right]
} */

const PatternsRows = ({ preset, settings, isPreview = false }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const levels = preset.levels || [];
    setRows(
      levels.map((level, index) => {
        if (level.toy === "" || !level.active) {
          // Do not display the row if it's inactive or no toys are selected for this range
          return null;
        }

        const durationBgImg = `url(${settings.durationBgImg})`;
        const durBgImgStyles = {
          ...settings.durationBgImgStyle,
          backgroundImage: durationBgImg,
        };
        let timeStyle = {
          color: settings.durationTextColor,
          paddingTop: settings.durationPadding[0],
          paddingRight: settings.durationPadding[1],
        };
        if (settings.durationTextColor.includes("gradient")) {
          timeStyle = {
            ...timeStyle,
            backgroundImage: settings.durationTextColor,
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          };
        }
        if (settings.durationTextFontSize) {
          timeStyle = {
            ...timeStyle,
            fontSize: settings.durationTextFontSize,
            fontWeight: settings.durationTextFrontWeight,
          };
        }

        let tipValueTextStyle = {
          color: settings.tipValueTextColor,
        };
        if (settings.tipValueTextColor.includes("gradient")) {
          tipValueTextStyle = {
            backgroundImage: settings.tipValueTextColor,
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          };
        }
        if (settings.tipValueTextFontSize) {
          tipValueTextStyle = {
            ...tipValueTextStyle,
            fontSize: settings.tipValueTextFontSize,
          };
        }

        return (
          <div className={styles.settingsRow} key={index}>
            <div className={styles.tipsRange}>
              <img
                className={styles.coin}
                src={settings.coinImg}
                alt=""
                style={settings.coinStyle}
              />
              <p className={styles.tips} style={tipValueTextStyle}>
                {level.tokensAmount}
              </p>
            </div>
            <div className={styles.timer} style={durBgImgStyles}>
              <p className={styles.timeInfo} style={timeStyle}>
                {level.duration}
                {translations("ObsTemplate.Seconds")}
              </p>
            </div>
            <div className={styles.intensity}>
              <img
                className={styles.intensityImg}
                src={level.thumbnail}
                alt=""
                style={settings.thumbnailStyle}
              />
            </div>
          </div>
        );
      })
    );
  }, [settings, isPreview, preset.levels]);

  return <React.Fragment>{rows}</React.Fragment>;
};

export default PatternsRows;
