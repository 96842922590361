import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import imageElla from "images/devices/ella-promotion.png";
import imageEmma from "images/devices/emma-promotion.png";
import imageVick from "images/devices/vick-promotion.png";
import imagePhoenix from "images/devices/phoenix-promotion.png";
import imagePhoenix2 from "images/devices/phoenix2-promotion.png";
import imagePulseLite from "images/devices/pulselite-promotion.png";
import imageAvaNeo from "images/devices/ava-neo-promotion.png";
import imageMoraNeo from "images/devices/mora-neo.png";
import imageTrystaNeo from "images/devices/trysta-promotion.png";
import imageEmmaMini from "images/devices/emma-mini-promotion.png";

const serviceUuid = 0xffe0;
const characteristicUuid = 0xffe1;

const devices = [
  { name: "Aogu SUV", image: imageElla },
  { name: "Aogu SCB", image: imageElla },
  { name: "Ella NEO", image: imageElla },
  { name: "Vick NEO", image: imageVick },
  { name: "Emma NEO", image: imageEmma },
  { name: "Phoenix NEO", image: imagePhoenix },
  { name: "Phoenix Neo 2", image: imagePhoenix2 },
  { name: "116", image: imagePhoenix },
  { name: "Pulse Lite Neo", image: imagePulseLite },
  { name: "SWK-SX013A", image: imagePulseLite },
  { name: "MLC-186-B", image: imageAvaNeo },
  { name: "QH-SA059-B", image: imageAvaNeo },
  { name: "Ava NEO", image: imageAvaNeo },
  { name: "Mora Neo", image: imageMoraNeo },
  { name: "Trysta Neo", image: imageTrystaNeo },
  { name: "SA299C", image: imageEmmaMini },
];

const MAX_INTENSITY = 0x0a;

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Svakom extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    const deviceRec = devices.find((d) => d.name === device.name);
    if (!deviceRec) {
      throw Error(`Device ${device.name} is not supported`);
    }
    const { image } = deviceRec;
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
  }

  static get deviceNames() {
    return devices.map((d) => d.name);
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "Svakom";
  }

  async write(percent) {
    const intensity = Math.max(
      0,
      Math.min(MAX_INTENSITY, (percent * MAX_INTENSITY) / 100)
    );
    const data = intensity
      ? [0x55, 0x03, 0x00, 0x00, 0x01, intensity]
      : [0x55, 0x03, 0x00, 0x00, 0x00, 0x00];
    const array = new Uint8Array(data);
    await this.motorChar.writeValue(array);
  }
}
