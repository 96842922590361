import React, { createContext, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import EventEmitter from "events";
import { takeRight } from "lodash";

import { useConnectedDevices } from "./ConnectedDevicesHook";
import { useTipSettings } from "./TipSettingsHook";
import { useWebsites } from "./WebsitesHook";
import {
  findLevelForAmount,
  findClosestPatternWithHigherPrice,
} from "./LevelUtils";
import { sendToAnalytics } from "lib/googleAnalytics";
import { sendAnalytics } from "@feelrobotics/ftap-connector";
import { useTipPatterns } from "./TipPatternsHook";
import { useWheelOfFortune } from "./WheelOfFortuneHook";
import { useUserSettings } from "hooks/UserSettingsHook";

const TipsContext = createContext();
const ee = new EventEmitter();
const EVENT_TIP = "TIP";

const useTips = () => {
  const context = React.useContext(TipsContext);
  if (context === undefined) {
    throw new Error(
      "`useTips` hook must be used within a `TipsContextProvider` component"
    );
  }
  return context;
};

const TipsContextProvider = ({ children }) => {
  const { websites } = useWebsites();
  const { presets } = useTipSettings();
  const { patterns } = useTipPatterns();
  const { options, widgetStep, setWidgetStep } = useWheelOfFortune();
  const {
    vibrateDevices,
    playPatternForGenericDevices,
    playPatternForLighShowDevices,
    connectedLightshowDevices,
  } = useConnectedDevices();
  const { isSendingData } = useUserSettings();

  const tipQueue = useRef([]);

  /**
   * Add a tip to the Queue. All tips in the queue will be played until the queue is empty.
   * @param {Object} tip Tip object to add to the Queue.
   */
  const addToQueue = (tip) => {
    tipQueue.current.push(tip);

    // If this is the only tip in the queue, start processing. (tip only gets dequeued after it's done playing)
    if (tipQueue.current.length === 1) {
      processTipQueue();
    }
  };

  // Callback, invoked after pattern/preset is done playing on the device.
  const callback = async () => {
    // dequeue the tip.
    tipQueue.current.shift();

    // stop Spot OBS templates
    setWidgetStep(0);

    if (tipQueue.current.length) {
      // sleep 30ms, because of Spot firmware; otherwise LEDs and Motor work will be unstable
      await new Promise((r) => setTimeout(r, 30));
      // If there are tips left, process them
      processTipQueue();
    }
  };

  /**
   * Start processing the tip queue. This function invokes itself until there is no queue left.
   * @returns null
   */
  const processTipQueue = () => {
    // Escape function if there is no Queue
    if (!tipQueue.current.length) {
      return;
    }

    // Gather tip info
    const tip = tipQueue.current[0];
    const { tipSettingsId } = tip.website;
    const preset = presets.find((p) => p.id === tipSettingsId);
    const patternsSet = patterns.find((p) => p.id === tipSettingsId);

    // set up event parameters.
    let eventParams = {
      website: tip.website,
      amount: tip.amount,
      sender: tip.sender,
    };

    if (preset) {
      // Simple tips

      // Get correct level for the tip amount.
      const { levels } = preset;
      const level = findLevelForAmount(levels, Number(tip.amount));

      // Set event parameters
      eventParams.duration = level.duration;
      eventParams.intensity = level.intensity;
      eventParams.toy = level.toy;
      eventParams.lightShow = level.lightShow;
      eventParams.lightColor = level.lightColor;

      // execute vibration logics
      vibrateDevices(level, callback);

      // start Spot OBS templates
      setWidgetStep(1);
    } else if (patternsSet) {
      // Patterns
      const { levels } = patternsSet;
      const foundLevel = findClosestPatternWithHigherPrice(
        levels,
        tip.amount,
        !connectedLightshowDevices.length
      );
      if (foundLevel) {
        // Set event parameters
        eventParams.duration = foundLevel.duration;
        eventParams.toy = foundLevel.toy;
        eventParams.intensity = foundLevel.intensity;
        eventParams.lightShow = foundLevel.lightShow;
        eventParams.lightColor = foundLevel.lightColor;
      }

      if (foundLevel?.intensitiesPerStep) {
        // play pattern for devices without lightShow support
        playPatternForGenericDevices(
          {
            ...foundLevel,
            timeStampEnd: Date.now() + foundLevel.duration * 1000,
            duration: foundLevel.duration * 1000,
          },
          callback
        );
      }

      if (foundLevel?.mode) {
        playPatternForLighShowDevices(
          {
            ...foundLevel,
            duration: foundLevel.duration * 1000,
          },
          callback
        );
      }

      if (!foundLevel) {
        callback();
      }
      // start Spot OBS templates
      setWidgetStep(1);
    } else if (options.id === tipSettingsId) {
      // Wheel of Fortune
      if (+options.buyIn <= +tip.amount) {
        setWidgetStep(1);
        const ANIMATION_DURATION = 7000; // msec, approximate duration of spinning wheel of fortune
        setTimeout(() => setWidgetStep(2), ANIMATION_DURATION);
      } else {
        // tips is less than minimal buy-in -> skip this tip and go to the next tip in the queue
        callback();
      }
    } else {
      console.error("Could not match tip with current settings. Skipping");
      return;
    }

    // Send out event notifying any listeners that there is toy activity.
    ee.emit(EVENT_TIP, eventParams);
  };

  const onMessageHandler = useCallback(
    (event) => {
      const { data } = event;
      const { payload, what } = data;

      if (what === "DOM_MUTATION") {
        websites.forEach((website) => {
          const {
            enabled,
            hosts,
            tipAmountExtractorFromText,
            tipAmountExtractorFromHtml,
            tipAmountExtractorFromDivInnerText,
            deduplicate,
            watchLastMessageInTextList,
            watch5LastMessagesInTextList,
          } = website;
          if (!enabled) {
            return;
          }

          const { text, html, divInnerText, hostname } = payload;
          const textList = JSON.parse(text || "[]").filter((text) => text);
          const htmlList = JSON.parse(html || "[]");
          const divInnerTextList = JSON.parse(divInnerText || "[]");

          // Find if the hostname provided with the message corresponds to
          // any of the hosts listed for the current website
          if (hosts.find((host) => hostname.includes(host))) {
            // TODO put hostname into website
            let result = [];
            if (tipAmountExtractorFromText) {
              if (watchLastMessageInTextList) {
                // whole chat container has rerended -> watch only the last message and ignore stale tips from history
                const { sender, tipAmount } = tipAmountExtractorFromText(
                  textList[textList.length - 1]
                );
                if (tipAmount) {
                  result.push({ sender, tipAmount });
                }
              } else if (watch5LastMessagesInTextList) {
                takeRight(textList, 5).forEach((txt) => {
                  const { sender, tipAmount } = tipAmountExtractorFromText(txt);
                  if (tipAmount) {
                    result.push({ sender, tipAmount });
                  }
                });
              } else {
                textList.forEach((txt) => {
                  const { sender, tipAmount } = tipAmountExtractorFromText(txt);
                  if (tipAmount) {
                    result.push({ sender, tipAmount });
                  }
                });
              }
            }

            if (tipAmountExtractorFromHtml) {
              htmlList.forEach((txt) => {
                const { sender, tipAmount } = tipAmountExtractorFromHtml(txt);
                if (tipAmount) {
                  result.push({ sender, tipAmount });
                }
              });
            }

            if (tipAmountExtractorFromDivInnerText) {
              divInnerTextList.forEach((txt) => {
                const { sender, tipAmount } =
                  tipAmountExtractorFromDivInnerText(txt);
                if (tipAmount) {
                  result.push({ sender, tipAmount });
                }
              });
            }

            if (deduplicate) {
              // Deduplicate values in result
              result = result.reduce((acc, current) => {
                const found = acc.find(
                  (item) =>
                    item.sender === current.sender &&
                    item.tipAmount === current.tipAmount
                );
                if (!found) {
                  return acc.concat([current]);
                }
                return acc;
              }, []);
            }
            result.forEach(({ sender, tipAmount }) => {
              toast.info(
                `${sender} tipped you ${tipAmount} token(s) on ${website.name}`
              );

              if (isSendingData) {
                sendToAnalytics(
                  "tip_recieved",
                  "tips",
                  website.name,
                  tipAmount
                );

                sendAnalytics("tip", "", "", {
                  tip_amount: tipAmount,
                  tip_source: website.name,
                });
              }

              addToQueue({ website, amount: tipAmount, sender });
            });
          }
        });
      }
    },
    [
      presets,
      vibrateDevices,
      websites,
      patterns,
      playPatternForGenericDevices,
      options,
      widgetStep,
      playPatternForLighShowDevices,
    ]
  );

  const subscribeTipEvents = (handler) => {
    ee.addListener(EVENT_TIP, handler);
  };

  const unsubscribeTipEvents = (handler) => {
    ee.removeListener(EVENT_TIP, handler);
  };

  useEffect(() => {
    window.addEventListener("message", onMessageHandler);
    return () => {
      window.removeEventListener("message", onMessageHandler);
    };
  }, [onMessageHandler]);

  return (
    <TipsContext.Provider
      value={{
        subscribeTipEvents,
        unsubscribeTipEvents,
        processTipQueueCallback: callback,
      }}
    >
      {children}
    </TipsContext.Provider>
  );
};

export { TipsContextProvider, useTips };
