import styles from "./style.module.scss";
import OBSTemplate from "../common/components/OBSTemplate";
import TipSettingRows from "../common/components/TipSettingRows";
import TipSettingsAnimation from "../common/components/TipSettingsAnimation";

import imgLogo from "./img/OBS-template-7-logo.png";
import imgCoin from "./img/OBS-template-7-coin.png";
import timeBgImg from "./img/OBS-template-7-timer.png";

import leftLow from "./img/OBS-template-7-left-low.png";
import rightLow from "./img/OBS-template-7-right-low.png";
import leftMedium from "./img/OBS-template-7-left-medium.png";
import rightMedium from "./img/OBS-template-7-right-medium.png";
import leftHigh from "./img/OBS-template-7-left-high.png";
import rightHigh from "./img/OBS-template-7-right-high.png";

import countdownBg from "./img/OBS-template-7-counter.png";

const settings = {
  coinImg: imgCoin,
  logoImg: imgLogo,
  tipValueTextColor: "#705300",
  durationBgImg: timeBgImg,
  durationTextColor: "#fff",
  durationPadding: [
    ["30px", "10px"],
    ["30px", "10px"],
  ],
  intensityAssets: [
    [leftLow, rightLow, "#008d00"],
    [leftMedium, rightMedium, "#b88c0b"],
    [leftHigh, rightHigh, "#ff0000"],
    [leftHigh, rightHigh, "#ff0000"],
  ],
};

const animSettings = {
  tipSenderColor: "#24272e",
  countdownBgImg: countdownBg,
  countdownTextPadding: -10,
};

const WidgetComponent = ({
  preset,
  tips,
  isPreview = false,
  connectedDevices,
}) => {
  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img className={styles.logo} src={imgLogo} alt="" />
        <TipSettingRows
          preset={preset}
          settings={settings}
          isPreview={isPreview}
        />
        <TipSettingsAnimation tips={tips} settings={animSettings} />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"#5b95d8"}
      tipSettingsRenderFunction={render}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
