/* global gtag */

/**
 * See https://web.dev/vitals/
 */
export const sendWebVitalsToAnalytics = ({ id, name, value }) => {
  sendToAnalytics(
    name,
    "Web Vitals",
    id, // id unique to current page load
    Math.round(name === "CLS" ? value * 1000 : value) // values must be integers
  );
};

export const sendToAnalytics = (action, category, label, intValue) => {
  gtag("event", action, {
    event_category: category,
    event_label: label,
    value: intValue,
  });
};

export const sendToAnalyticsCustomEventObj = (action, params) => {
  gtag("event", action, params);
};
