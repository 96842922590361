import { sendToAnalytics } from "lib/googleAnalytics";
import { sendAnalytics } from "@feelrobotics/ftap-connector";

import {
  shouldSendAnalytics,
  convertPercentToCommandValue,
  convertValueToUint16,
  hexToRgb,
} from "utils/utils";
import * as DeviceStates from "./DeviceStates";
import { onGattServerDisconnected } from "lib/deviceReconnectingNotification";
import image from "images/devices/prowand-promotion.png";

const names = ["ProWand"];

const PROWAND_SERVICE_UUID = 0x1400;
const ACTUATOR_MOTOR_UUID = 0x1401;
const LED_CONTROL_UUID = 0x1402;
const CHANGE_NAME_UUID = 0x1403;

const BATTERY_SERVICE_UUID = 0x180f;
const BATTERY_LEVEL_UUID = 0x2a19;
const DEVICE_SPECIFIC_INFO = 0x2a29;

const PERIOD_DURATION = 1000; // ms; default value for non-constant vibraion mode

/**
 * Abstract Class BaseDeviceWrapper.
 * @class BaseDeviceWrapper
 *
 * Device wrapper over the Web Bluetooth device object
 * @respons Responsible for
 * - holding the WebBluetooth object, its service and charactristics
 * - Connecting and disconnecting to the Web Bluetooth device
 * - Discovering its service and characteristic during the connection process
 * - holding Web Bluetooth device connectin state (connected, disconnected, connecting)
 * - Handling Web Bluetooth device disconnection
 *
 * @collab Is a base class for concrete device classes like Pearl2, Cliona, etc.
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class ProWand {
  constructor(device) {
    this.device = device;
    this.image = image;
    this.server = null;

    this.prowandService = null;
    this.actuatorCharacteristic = null;
    this.ledCharacteristic = null;
    this.overrideNameCharacteristic = null;

    this.batteryService = null;
    this.batteryCharacteristic = null;

    this.isSendingData = true;
    this.connectionState = DeviceStates.DISCONNECTED;
    this.connectionStateCallback = null;
    this.defaultDeviceName = "ProWand";

    device.addEventListener("gattserverdisconnected", () => {
      onGattServerDisconnected(this);
      shouldSendAnalytics() &&
        sendAnalytics("disconnect", this.device.name, this.device.id);
    });
  }

  setSendingData(isSendingData) {
    return !!isSendingData;
  }

  static get deviceNames() {
    return names;
  }

  static get services() {
    return [PROWAND_SERVICE_UUID, BATTERY_SERVICE_UUID];
  }

  get companyName() {
    return "Kiiroo";
  }

  /**
   * Set new connection state and notify the callback
   * @param {string} newState - new state from DeviceStates.*
   */
  setConnectionState(newState) {
    this.connectionState = newState;
    this.connectionStateCallback?.();
  }

  /**
   * Connect to the device and discover the service and characteristic
   */
  async connect() {
    async function innerConnect() {
      const startTime = performance.now();

      this.setConnectionState(DeviceStates.CONNECTING);
      this.server = await this.device.gatt.connect();

      this.prowandService = await this.server.getPrimaryService(
        PROWAND_SERVICE_UUID
      );

      // motor
      this.actuatorCharacteristic = await this.prowandService.getCharacteristic(
        ACTUATOR_MOTOR_UUID
      );
      // LEDs
      this.ledCharacteristic = await this.prowandService.getCharacteristic(
        LED_CONTROL_UUID
      );
      // change name
      this.overrideNameCharacteristic =
        await this.prowandService.getCharacteristic(CHANGE_NAME_UUID);

      // battery
      this.batteryService = await this.server.getPrimaryService(
        BATTERY_SERVICE_UUID
      );
      this.batteryCharacteristic = await this.batteryService.getCharacteristic(
        BATTERY_LEVEL_UUID
      );

      this.setConnectionState(DeviceStates.CONNECTED);
      clearTimeout(this.connectionTimeout);

      // Report to google analytics
      const milliseconds = Math.round(performance.now() - startTime);
      sendToAnalytics(
        "device_connect",
        "device",
        this.device.name,
        milliseconds
      );
      shouldSendAnalytics() &&
        sendAnalytics("connect", this.device.name, this.device.id);
    }

    return await Promise.race([innerConnect.bind(this)()]);
  }

  /**
   * Disconnect from the device
   */
  async disconnect() {
    this.setConnectionState(DeviceStates.DISCONNECTING);
    if (this.device.gatt.connected) {
      await this.device.gatt.disconnect();
    }
    this.setConnectionState(DeviceStates.MANUALLY_DISCONNECTED);
    sendToAnalytics("device_disconnect", "device", this.device.name);
  }

  async getBattery() {
    const value = await this.batteryCharacteristic.readValue();
    return value.getUint8(0);
  }

  // working only on Windows
  async setDeviceName(newName) {
    const hexDeviceName = newName.split("").map((c) => c.charCodeAt(0));
    await this.overrideNameCharacteristic.writeValue(
      new Uint8Array(hexDeviceName)
    );
  }

  // motor control
  async startMode(totalDuration, low, high, mode = 1, periodDuration = 500) {
    const frameData = [
      mode,
      ...convertValueToUint16(periodDuration),
      Math.round(totalDuration / 1000),
      convertPercentToCommandValue(low),
      convertPercentToCommandValue(high),
    ];
    await this.actuatorCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async write(percent) {
    const periodDuration = 100;
    const STOP_VALUE = 0;
    const FOREVER_VIBRATE_VALUE = 255;

    const frameData = [
      0,
      ...convertValueToUint16(periodDuration),
      percent ? FOREVER_VIBRATE_VALUE : STOP_VALUE,
      convertPercentToCommandValue(percent),
      convertPercentToCommandValue(percent),
    ];

    await this.actuatorCharacteristic.writeValue(new Uint8Array(frameData));
  }

  getRGBarray(rgbColor) {
    return rgbColor.match(/\d+/g).map((value) => parseInt(value, 10));
  }

  async runBlinkEffect(totalDuration, rgbColor) {
    const timePeriod = 250;

    const frameData = [
      1,
      timePeriod,
      Math.round(totalDuration / 1000),
      ...this.getRGBarray(rgbColor),
      100,
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async runGlowEffect(totalDuration, rgbColor) {
    const timePeriod = 250;

    const frameData = [
      2,
      timePeriod,
      Math.round(totalDuration / 1000),
      ...this.getRGBarray(rgbColor),
      100,
    ];
    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async discoAlgoFunction(totalDuration) {
    const frameIndex = 3;
    let timePeriod = 180;

    const frameData = [
      frameIndex,
      timePeriod,
      Math.round(totalDuration / 1000),
      0,
      0,
      0,
      100,
    ];

    await this.ledCharacteristic.writeValue(new Uint8Array(frameData));
  }

  async playLightShow(duration, lightShow, lightColor) {
    switch (lightShow) {
      case "Blink":
        this.runBlinkEffect(duration, lightColor);
        break;
      case "Glow":
        this.runGlowEffect(duration, lightColor);
        break;
      case "Unicorn":
        this.discoAlgoFunction(duration);
        break;
      default:
        // fallback to Unicorn
        this.discoAlgoFunction(duration);
        return;
    }
  }

  async processTipRange(totalDuration, intensity, lightShow, lightColor) {
    this.startMode(totalDuration, intensity, intensity);
    this.playLightShow(totalDuration, lightShow, hexToRgb(lightColor));
  }

  async processTipPattern(pattern) {
    const {
      mode,
      duration: totalDuration,
      intensity: maxIntensity,
      lightShow,
      lightColor,
      periodDuration = 1000,
    } = pattern;

    if (!pattern.intensitiesPerStep) {
      this.startMode(totalDuration, 0, maxIntensity, mode, periodDuration);
    }
    this.playLightShow(totalDuration, lightShow, hexToRgb(lightColor));
  }
}
