import React, { createContext, useEffect, useState } from "react";
import { useWebsites } from "./WebsitesHook";
import { useWheelOfFortune } from "./WheelOfFortuneHook";

const LOCAL_STORAGE_KEY = "widget-settings_V2";

const INITIAL_SETTINGS = {
  selectedTemplateId: 0,
  selectedWebsiteId: localStorage.getItem("widgetSelectedWebsite"), // Backward compatibility
};

const getLocalStorageSettings = () => {
  const localStorageSettingsStr = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!localStorageSettingsStr) {
    return { ...INITIAL_SETTINGS };
  }

  try {
    return JSON.parse(localStorageSettingsStr);
  } catch (e) {
    return { ...INITIAL_SETTINGS };
  }
};

const WidgetSettingsContext = createContext();

const useWidgetSettings = () => {
  const context = React.useContext(WidgetSettingsContext);
  if (context === undefined) {
    throw new Error(
      "`useWidgetSettings` hook must be used within a `WidgetSettingsContextProvider` component"
    );
  }
  return context;
};

const WidgetSettingsContextProvider = ({ children }) => {
  const [settings, setSettings] = useState(getLocalStorageSettings());
  const { websites } = useWebsites();
  const { setWidgetStep } = useWheelOfFortune();

  useEffect(() => {
    // Save to the local storage on each change
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settings));
  }, [settings]);

  useEffect(() => {
    if (settings.selectedWebsiteId) {
      return;
    }

    // If selectedWebsiteId is not set, assign the first one from the websites list
    setSelectedWebsiteId(websites[0].id);
  }, [settings.selectedWebsiteId, websites]);

  const setSelectedWebsiteId = (websiteId) => {
    setSettings((old) => ({
      ...old,
      selectedWebsiteId: websiteId,
    }));
    setWidgetStep(0);
  };

  /**
   * Set current template
   * @param {int} idx - Template index, [0...n]
   */
  const setSelectedTemplateId = (id) => {
    setSettings((old) => ({
      ...old,
      selectedTemplateId: id,
    }));
    setWidgetStep(0);
  };

  return (
    <WidgetSettingsContext.Provider
      value={{
        selectedWebsiteId: settings.selectedWebsiteId,
        selectedTemplateId: settings.selectedTemplateId,
        setSelectedWebsiteId,
        setSelectedTemplateId,
      }}
    >
      {children}
    </WidgetSettingsContext.Provider>
  );
};

export { WidgetSettingsContextProvider, useWidgetSettings };
