import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/esca-promotion.png";

const serviceUuid = 0xffe5;
const motorCharUuid = 0xffe9; // motor controlling characteristic

const infoServiceUuid = 0xff90;
const deviceNameCharUiid = 0xff91;

const names = ["PleasureDrive"];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class PleasureDrive extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, motorCharUuid, image, isSendingData);
    this.defaultDeviceName = "PleasureDrive";
  }

  static get deviceNames() {
    return names;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid, infoServiceUuid];
  }

  get companyName() {
    return "Kiiroo";
  }

  async connect() {
    try {
      await super.connect(async () => {
        // renaming service
        this.overrideService = await this.server.getPrimaryService(
          infoServiceUuid
        );
        this.overrideNameCharacteristic =
          await this.overrideService.getCharacteristic(deviceNameCharUiid);
      });
    } catch (e) {
      return Promise.reject("connect PleasureDrive e: " + e);
    }
  }

  async write(percent) {
    const value = Math.max(0, Math.min(99, Math.round(percent)));
    const array = new Uint8Array([0xcc, 0x03, value, value + 3]);
    return this.motorChar.writeValue(array);
  }

  // working only on Windows
  async setDeviceName(newName) {
    const hexDeviceName = newName.split("").map((c) => c.charCodeAt(0));
    await this.overrideNameCharacteristic.writeValue(
      new Uint8Array(hexDeviceName)
    );
  }
}
