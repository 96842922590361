import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { translations } from "i18n";
import Lottie from "lottie-react";

import styles from "./style.module.scss";

import OBSTemplateWithoutDevices from "../common/components/OBSTemplateWithoutDevices";
import WheelOfFortuneRows from "../common/components/WheelOfFortuneRows";
import { useWheelOfFortune } from "hooks/WheelOfFortuneHook";

import imgLogo from "./img/logo.png";
import imgGift from "./img/gift.png";
import imgWin from "./img/win.png";

import blue from "./animations/blue.json";
import purple from "./animations/purple.json";
import white from "./animations/white.json";
import yellow from "./animations/yellow.json";

// wheel consist of 4 colored sectors
const animations = [blue, purple, white, yellow];

const settings = {
  imgGift,
  imgLogo,
  imgWin,
  giftStyle: { height: 90 },
  tipValueTextColor:
    "radial-gradient(circle at 45% 48%, #f7edff 0%, #f8d0ff 17%)",
  tipValueTextFontSize: 48,
};

const WidgetComponent = ({ preset, tips = [], isPreview, widgetStep }) => {
  const animationRef = useRef();
  const { randomOption, getRandomOption, getRandomAnimationKey } =
    useWheelOfFortune();

  const render = () => {
    return (
      <>
        {(isPreview || widgetStep === 0) && (
          <div
            className={cn(
              styles.settingsContainer,
              styles.backgroundDefault,
              isPreview && styles.preview
            )}
          >
            <img className={styles.logo} src={settings.imgLogo} alt="" />
            <div className={styles.price}>
              {translations("WheelOfFortune.TipTokens", {
                amount: preset.buyIn,
              })}
            </div>
            <WheelOfFortuneRows
              preset={preset}
              isPreview={isPreview}
              settings={settings}
              showGiftImg
            />
          </div>
        )}
        {widgetStep === 1 && !isPreview && (
          <div
            className={cn(
              styles.settingsContainer,
              styles.backgroundSpinningWheel
            )}
          >
            <img className={styles.logo} src={settings.imgLogo} alt="" />
            <div className={styles.senderTipped}>
              {translations("WheelOfFortune.TippedTokens", {
                sender: tips[tips.length - 1]?.sender,
                amount: tips[tips.length - 1]?.amount,
              })}
            </div>
            <Lottie
              lottieRef={animationRef}
              animationData={animations[getRandomAnimationKey()]}
              autoplay={true}
              loop={false}
              className={styles.wheel}
            />
          </div>
        )}
        {widgetStep === 2 && !isPreview && (
          <div
            className={cn(styles.settingsContainer, styles.backgroundNoWheel)}
          >
            <img className={styles.logo} src={settings.imgLogo} alt="" />
            <img className={styles.win} src={settings.imgWin} alt="" />
            <WheelOfFortuneRows
              preset={preset}
              isPreview={isPreview}
              settings={settings}
              indexWon={randomOption}
              showGiftImg
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (widgetStep === 1) {
      getRandomOption(preset);
    }
  }, [widgetStep]);

  return (
    <OBSTemplateWithoutDevices
      tipSettingsRenderFunction={render}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
