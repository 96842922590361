import React, { createContext, useEffect, useState, useRef } from "react";
import { debounce, cloneDeep } from "lodash";

import { DEFAULT_PRESETS } from "data/constants";

const DEFAULT_LEVEL = {
  rangeMax: 100,
  duration: 1,
  intensity: 50,
  toy: "all",
  lightShow: "Blink",
  lightColor: "#DBDF00",
};

const DEFAULT_MAX_LEVEL = {
  rangeMax: "Max",
  duration: 1,
  intensity: 50,
  toy: "all",
  lightShow: "Blink",
  lightColor: "#DBDF00",
};

const DEFAULT_PRESET = {
  name: "",
  id: null, // Auto-generated unique ID
  levels: [DEFAULT_LEVEL, DEFAULT_MAX_LEVEL],
};

const CHANGES_SAVED_VISIBILITY_TIMEOUT = 500;
const CHANGES_SAVED_VISIBILITY_DURATION = 1000;

const PRESETS_KEY = "presets_V3";

// Load translations into memory statically so src2json.js doesn't delete
// the terms from the translations files because it can't find any uses.

const TipSettingsContext = createContext();

const useTipSettings = () => {
  const context = React.useContext(TipSettingsContext);
  if (context === undefined) {
    throw new Error(
      "`useTipSettings` hook must be used within a `TipSettingsContextProvider` component"
    );
  }
  return context;
};

const getLocalStoragePresets = () => {
  const localStoragePresetsStr = localStorage.getItem(PRESETS_KEY);
  if (!localStoragePresetsStr) {
    return [...DEFAULT_PRESETS];
  }

  try {
    return JSON.parse(localStoragePresetsStr);
  } catch (e) {
    return [...DEFAULT_PRESETS];
  }
};

const TipSettingsContextProvider = ({ children }) => {
  const [presets, setPresets] = useState(getLocalStoragePresets());
  const [changesSavedVisible, setChangesSavedVisible] = useState(false);
  const changesSavedVisibleRef = useRef(0);

  const [showSliders, setShowSliders] = useState(false);

  // Save settings to the local storage on each update
  useEffect(() => {
    localStorage.setItem(PRESETS_KEY, JSON.stringify(presets));
  }, [presets]);

  const makeChangesSavedVisible = debounce(() => {
    setChangesSavedVisible(true);
    clearTimeout(changesSavedVisibleRef.current);
    changesSavedVisibleRef.current = setTimeout(() => {
      setChangesSavedVisible(false);
    }, CHANGES_SAVED_VISIBILITY_DURATION);
  }, CHANGES_SAVED_VISIBILITY_TIMEOUT);

  const resetPresetToDefault = (index) => {
    const newPresets = cloneDeep(presets);
    let preset;

    preset = cloneDeep(DEFAULT_PRESETS)[index];
    newPresets[index] = preset;

    setPresets(newPresets);
  };

  const addLevel = (presetIndex) => {
    const newPresets = cloneDeep(presets);
    const preset = { ...newPresets[presetIndex] };
    newPresets[presetIndex] = preset;
    preset.levels[preset.levels.length] = [...preset.levels][
      preset.levels.length - 1
    ];

    if (preset.levels.length === 2) {
      preset.levels[preset.levels.length - 2] = DEFAULT_LEVEL;
    } else {
      preset.levels[preset.levels.length - 2] = {
        ...DEFAULT_LEVEL,
        rangeMax: Number(preset.levels[preset.levels.length - 3].rangeMax) + 1,
      };
    }

    setPresets(newPresets);
  };

  const deleteLevel = (presetIndex, levelIndex) => {
    const newPresets = [...presets];
    const preset = newPresets[presetIndex];
    preset.levels = [...preset.levels];
    preset.levels.splice(levelIndex, 1);
    setPresets(newPresets);
  };

  const updateLevel = (presetIndex, levelIndex, newLevel) => {
    if (newLevel.rangeMax !== "Max") {
      newLevel.rangeMax = Math.max(1, newLevel.rangeMax);
    }
    newLevel.duration = Math.max(1, newLevel.duration);
    const newPresets = [...presets];
    const preset = newPresets[presetIndex];
    preset.levels = [...preset.levels];
    preset.levels[levelIndex] = newLevel;
    setPresets(newPresets);
    makeChangesSavedVisible();
  };

  const clearSettings = () => {
    setPresets([...DEFAULT_PRESETS]);
  };

  /**
   * Check if preset levels are equal to the default preset at this
   * positon.
   * First presets are defined in DEFAULT_PRESETS
   * The next presets default state is defined in DEFAULT_PRESET
   * @param {int} index - Preset index
   * @returns true if the preset levels are default
   */
  const isDefaultPreset = (index) => {
    const preset = presets[index];
    const defaultPreset =
      index < DEFAULT_PRESETS.length ? DEFAULT_PRESETS[index] : DEFAULT_PRESET;
    return (
      JSON.stringify(preset.levels) === JSON.stringify(defaultPreset.levels)
    );
  };

  return (
    <TipSettingsContext.Provider
      value={{
        resetPresetToDefault,
        presets,
        addLevel,
        deleteLevel,
        updateLevel,
        changesSavedVisible,
        showSliders,
        setShowSliders,
        clearSettings,
        isDefaultPreset,
      }}
    >
      {children}
    </TipSettingsContext.Provider>
  );
};

export { TipSettingsContextProvider, useTipSettings };
