import React, { createContext, useEffect, useState } from "react";
import { CreateHook } from "utils/utils";

export const LOCAL_STORAGE_KEY = "diagnostic_data";

const UserSettingsContext = createContext();

const useUserSettings = () =>
  CreateHook("useUserSettings", UserSettingsContext);

const getIsSendingDataFromLocalStorage = () => {
  let result = localStorage.getItem(LOCAL_STORAGE_KEY) || "";
  if (result === "true") {
    return true;
  }
  if (result === "false") {
    return false;
  }
  return result;
};

const UserSettingsContextProvider = ({ children }) => {
  const [isSendingData, setIsSendingData] = useState(
    getIsSendingDataFromLocalStorage()
  );

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, isSendingData);
  }, [isSendingData]);

  return (
    <UserSettingsContext.Provider
      value={{
        isSendingData,
        setIsSendingData,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export { UserSettingsContextProvider, useUserSettings };
