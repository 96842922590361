import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PortalApp from "./PortalApp";
import WidgetApp from "./WidgetApp";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path={"/widget/:channelId"}>
          <WidgetApp />
        </Route>
        <Route path="/">
          <PortalApp />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
