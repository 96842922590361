import React, {
  createContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

import { discoverDevice } from "lib/devices/connect";
import * as ConnectionState from "lib/devices/wrappers/DeviceStates";
import {
  rangeSubscribe,
  patternSubscribe,
  onTipRange,
  onTipPattern,
} from "lib/TipToDeviceEmitter.js";
import { sendToAnalyticsCustomEventObj } from "lib/googleAnalytics";
import { useUserSettings } from "hooks/UserSettingsHook";
import { getDeviceDisplayName } from "lib/devices/connect";
import { hexToRgb } from "utils/utils";
import {
  LIGHT_SHOWS_SPOT,
  LIGHT_SHOWS_PROWAND,
  LIGHT_SHOWS_EMMA2,
} from "data/constants";

const DEFAULT_DEVICE = {
  name: null,
  id: null,
  wrapper: null,
  image: null,
  error: null,
};

const LOCAL_STORAGE_KEY = "devices";
const TEST_DEVICE_DURATION_SEC = 3;
const TEST_DEVICE_INTENSITY_PERCENT = 40;

const ConnectedDevicesContext = createContext();

export const ALL_DEVICES_DEVICE_ID = "all";

const useConnectedDevices = () => {
  const context = React.useContext(ConnectedDevicesContext);
  if (context === undefined) {
    throw new Error(
      "`useConnectedDevices` hook must be used within a `ConnectedDevicesContextProvider` component"
    );
  }
  return context;
};

const getLocalStorageDevices = () => {
  const localStorageDevicesStr =
    localStorage.getItem(LOCAL_STORAGE_KEY) || "[]";
  try {
    return JSON.parse(localStorageDevicesStr);
  } catch (e) {
    return [];
  }
};

const ConnectedDevicesContextProvider = ({ children }) => {
  const [devices, setDevices] = useState(getLocalStorageDevices());
  const [reloadPage, setReloadPage] = useState(false);
  const [recommendationsVisible, setRecommendationsVisible] = useState(false);
  const vibrationQueue = useRef([]);
  const patternTimoutIdRef = useRef(null);
  const patternIntervalIdRef = useRef(null);
  const { isSendingData } = useUserSettings();

  const getConnectionState = useCallback((device) => {
    return device?.wrapper?.connectionState || ConnectionState.DISCONNECTED;
  }, []);

  const connectedDevices = useMemo(() => {
    return devices.filter(
      (s) => getConnectionState(s) === ConnectionState.CONNECTED
    );
  }, [devices]);

  useEffect(() => {
    devices.forEach((device) => {
      device.wrapper?.setSendingData(isSendingData);
    });
  }, [isSendingData]);

  // Save settings to the local storage on each update
  useEffect(() => {
    const devicesToSave = devices.map((device) => {
      const result = { ...device };
      result.wrapper = null;
      return result;
    });
    const devicesStr = JSON.stringify(devicesToSave);
    localStorage.setItem(LOCAL_STORAGE_KEY, devicesStr);
  }, [devices]);

  useEffect(() => {
    if (connectedDevices.length) {
      // request notification permissions until user makes his choice (maximum 3 times)
      Notification.requestPermission();
    }
  }, [connectedDevices.length]);

  const [isLightShowDeviceConnected, setIsLightShowDeviceConnected] =
    useState(false);
  const [connectedLightshowDevices, setConnectedLightshowDevices] = useState(
    []
  );
  const [isSpotConnected, setIsSpotConnected] = useState(false);
  const [isProwandConnected, setIsProwandConnected] = useState(false);

  useEffect(() => {
    const lightShowDevices = connectedDevices.filter(
      (s) => s.wrapper?.playLightShow
    );
    setConnectedLightshowDevices(lightShowDevices);
    setIsLightShowDeviceConnected(!!lightShowDevices.length);
    setIsSpotConnected(
      lightShowDevices.some((s) => s.wrapper.defaultDeviceName === "Spot")
    );
    setIsProwandConnected(
      lightShowDevices.some((s) => s.wrapper.defaultDeviceName === "ProWand")
    );
  }, [connectedDevices.length]);

  const clearError = (device) => {
    device.error = null;
  };

  const deleteDevice = async (index) => {
    await disconnect(index);
    setDevices((oldDevices) => {
      const newDevices = [...oldDevices];
      newDevices.splice(index, 1);
      return newDevices;
    });
  };

  const disconnect = async (index) => {
    const device = devices[index];
    clearError(device);
    if (getConnectionState(device) !== ConnectionState.CONNECTED) {
      // Not connected
      return;
    }

    await device.wrapper?.disconnect();
    setDevices([...devices]);
  };

  const makeDeviceFromWrapper = (base, deviceWrapper) => {
    return {
      ...base,
      id: deviceWrapper.device.id,
      name: deviceWrapper.device.name,
      company: deviceWrapper.companyName,
      image: deviceWrapper.image,
      wrapper: deviceWrapper,
    };
  };

  const discoverDevices = async () => {
    const deviceWrapper = await discoverDevice(() => {
      // Refresh devices on every device state change
      setDevices((oldDevices) => [...oldDevices]);
    }, isSendingData);

    if (!deviceWrapper) {
      // No device connected
      return;
    }

    const index = devices.findIndex(
      (dev) => dev.id === deviceWrapper.device.id
    );

    if (index >= 0) {
      // Device is already in the list
      const newDevices = [...devices];
      newDevices[index].wrapper?.deregisterAllEvents();
      newDevices[index] = makeDeviceFromWrapper(
        newDevices[index],
        deviceWrapper
      );
      clearError(newDevices[index]);
      setDevices(newDevices);
    } else {
      const newDevice = makeDeviceFromWrapper(DEFAULT_DEVICE, deviceWrapper);
      setDevices([...devices, newDevice]);
    }

    // Now we need to connect to the device that has been just discovered

    await deviceWrapper.connect().catch((e) => {
      console.log("Connect err:", e);
      setReloadPage(false);
      setRecommendationsVisible(true);
    });
  };

  const reconnectDevice = async (index) => {
    const device = devices[index];
    clearError(device);
    if (!device?.wrapper) {
      await discoverDevices();
      return;
    }

    try {
      await device?.wrapper?.connect();
    } catch (error) {
      // Display error
      device.error = error.toString();
      // Reset device completely
      device.wrapper = null;
      if (isSendingData) {
        Sentry.captureException(error);
      }

      setReloadPage(true);
      setRecommendationsVisible(true);
    }
    setDevices([...devices]);
  };

  /**
   * Vibrate the device for a short amount of time
   * @param {int} index - device index in devices
   */
  const testDevice = async (index) => {
    const device = devices[index];
    clearError(device);
    if (getConnectionState(device) !== ConnectionState.CONNECTED) {
      return;
    }
    vibrateDevices({
      toy: device.id,
      duration: TEST_DEVICE_DURATION_SEC,
      intensity: TEST_DEVICE_INTENSITY_PERCENT,
      lightShow: "Unicorn",
      lightColor: "#FF0000",
    });
  };

  useEffect(() => {
    const writeToDevice = async (device, value) => {
      try {
        await device?.wrapper?.write(value);
      } catch (error) {
        // Display error
        device.error = error.toString();

        if (!device.wrapper) {
          const deviceName = getDeviceDisplayName(device.name);
          toast.error(
            `Something happened with your ${deviceName}, please try again`
          );
        }
        if (isSendingData) {
          Sentry.captureException(error);
        }

        // Reset device completely
        device.wrapper = null;
        // Notify the UI
        setDevices([...devices]);
      }
    };

    /**
     * Process the vibration queue elements - send vibration commands to the
     * devices according to the queue.
     * Waits for each command to finish before starting the next one.
     * @returns Promise which resolves when the queue becomes empty
     */
    const processVibrationQueue = async () => {
      const queue = vibrationQueue.current;
      if (!queue.length) {
        // No elements left in the queue, do nothing
        return;
      }

      const { devicesToVibrate, intens } = queue[0];
      try {
        // Array of async functions to vibrate each devices
        const vibrateFunctions = devicesToVibrate.map(async (device) => {
          if (getConnectionState(device) !== ConnectionState.CONNECTED) {
            return;
          }
          await writeToDevice(device, intens);
        });
        // Wait until all devices start vibrating
        await Promise.all(vibrateFunctions);
      } catch (error) {
        // TODO log the error
      }
      queue.shift();

      // Process the next element
      await processVibrationQueue();
    };

    /**
     * Start vibration of given devices with given intensity
     * @param {array} devicesToVibrate - list of devices
     * @param {int} intens - Vibration intensity, 0..100 percent
     */
    const vibrate = async (devicesToVibrate, intens) => {
      const queue = vibrationQueue.current;
      const queueEmpty = queue.length === 0;
      queue.push({ devicesToVibrate, intens });
      if (!queueEmpty) {
        // If queue was not empty, it's being processed already,
        // no need to do anything
        return;
      }
      await processVibrationQueue();
    };

    const rangeUnsubscribe = rangeSubscribe((intensityPercent, deviceId) => {
      const device = devices.find((dev) => dev.id === deviceId);
      if (!device) {
        // Device not found
        return;
      }
      vibrate([device], intensityPercent);
    });

    const patternUnsubscribe = patternSubscribe((pattern) => {
      clearTimeout(patternTimoutIdRef.current);
      clearInterval(patternIntervalIdRef.current);

      const devicesToVibrate =
        pattern.toy === ALL_DEVICES_DEVICE_ID
          ? connectedDevices
          : [connectedDevices.find((d) => d.id === pattern.toy)];

      // stop device
      if (pattern.stop) {
        connectedDevices.forEach((device) => {
          vibrate([device], 0);
        });
        return;
      }

      const stepsAmount = pattern.intensitiesPerStep.length;
      const stepDuration = pattern.duration / stepsAmount;
      const timeLeft = pattern.timeStampEnd - Date.now();

      let currentStepIndexRight = Math.ceil(timeLeft / stepDuration);

      const vibrateCurrentStep = () => {
        if (
          pattern.intensitiesPerStep[
            pattern.intensitiesPerStep.length - currentStepIndexRight
          ] === undefined
        ) {
          return;
        }
        devicesToVibrate.forEach((device) => {
          vibrate(
            [device],
            pattern.intensitiesPerStep[
              pattern.intensitiesPerStep.length - currentStepIndexRight
            ]
          );
        });
      };

      vibrateCurrentStep();
      patternTimoutIdRef.current = setTimeout(() => {
        clearTimeout(patternTimoutIdRef.current);
        currentStepIndexRight--;

        if (currentStepIndexRight === 0) {
          devicesToVibrate.forEach((device) => {
            vibrate([device], 0);
          });
          clearInterval(patternIntervalIdRef.current);
          return;
        }

        vibrateCurrentStep();

        patternIntervalIdRef.current = setInterval(() => {
          currentStepIndexRight--;

          if (currentStepIndexRight === 0) {
            clearInterval(patternIntervalIdRef.current);
            devicesToVibrate.forEach((device) => {
              vibrate([device], 0);
            });
            return;
          }

          vibrateCurrentStep();
        }, stepDuration);
      }, timeLeft - (currentStepIndexRight - 1) * stepDuration);
    });

    return () => {
      rangeUnsubscribe();
      patternUnsubscribe();
    };
  }, [connectedDevices]);

  /**
   * Vibrate given device(s) for given amount of time
   * @param {string} deviceId - device ID, if 'all', then vibrate all devices, if '' then vibrate none
   * @param {int} duration - duration in seconds
   * @param {int} intensity - intensity percentage, 0..100
   */
  const vibrateDevices = async (level, callback = null) => {
    const { toy, duration, intensity, lightShow, lightColor } = level;
    if (!toy) {
      // No device selected
      return;
    }

    const devicesToVibrate =
      toy === ALL_DEVICES_DEVICE_ID
        ? connectedDevices
        : [connectedDevices.find((d) => d.id === toy)];
    const durationMsec = duration * 1000;

    devicesToVibrate.forEach((dev) => {
      // Spot devices have embedded queue that process commands to Motor and LEDs, we can use this one
      if (dev.wrapper?.playLightShow) {
        dev.wrapper?.processTipRange(
          durationMsec,
          intensity,
          lightShow,
          lightColor
        );
      } else {
        onTipRange(durationMsec, intensity, dev.id);
      }
      if (isSendingData) {
        sendToAnalyticsCustomEventObj("tip_ranges_activation", {
          deviceName: dev.name,
        });
      }
    });

    // Call the callback when the toy is done vibrating.
    if (callback) {
      setTimeout(() => {
        callback();
      }, durationMsec);
    }
  };

  const playPatternForGenericDevices = async (pattern, callback = null) => {
    if (!pattern.toy) {
      // No device selected
      return;
    }

    if (pattern.mode) {
      // skip embedded pattern
      return;
    }

    const devicesToVibrate =
      pattern.toy === ALL_DEVICES_DEVICE_ID
        ? connectedDevices
        : [connectedDevices.find((d) => d.id === pattern.toy)];

    onTipPattern({ ...pattern, deviceId: devicesToVibrate[0]?.id });
    devicesToVibrate.forEach((dev) => {
      if (dev.wrapper?.playLightShow) {
        dev.wrapper.playLightShow(
          pattern.duration,
          pattern.lightShow,
          hexToRgb(pattern.lightColor)
        );
      }
      if (isSendingData) {
        sendToAnalyticsCustomEventObj("tip_pattern_activation", {
          deviceName: dev.name,
        });
      }
    });

    // Call the callback function when the pattern is done playing.
    if (callback) {
      setTimeout(() => {
        callback();
      }, pattern.duration);
    }
  };

  const playPatternForLighShowDevices = async (pattern, callback = null) => {
    if (!pattern.toy) {
      // No device selected
      return;
    }

    const devicesToVibrate =
      pattern.toy === ALL_DEVICES_DEVICE_ID
        ? connectedDevices
        : [connectedDevices.find((d) => d.id === pattern.toy)];

    devicesToVibrate.forEach((dev) => {
      if (
        !dev.wrapper?.playLightShow ||
        // TODO: Find less hacky way to handle this.
        connectedLightshowDevices.some((s) => s.name === "Emma Neo 2")
      ) {
        // filter the devices that do not support light show
        return;
      }
      dev.wrapper?.processTipPattern(pattern);
      if (isSendingData) {
        sendToAnalyticsCustomEventObj("tip_pattern_activation", {
          deviceName: dev.name,
        });
      }
    });

    // Call the callback function when the pattern is done playing.
    if (callback) {
      setTimeout(() => {
        callback();
      }, pattern.duration);
    }
  };

  /**
   * Gets the lightshows compatible with the current collection of connected devices.
   * Always returns the lowest common denomination between connected devices.
   * @returns {Object} object containing kv pairs of the available lightshows.
   */
  const getLightShows = () => {
    if (!isLightShowDeviceConnected) {
      return [];
    }

    // The code below is very hacky but basically it does the following:
    // If only 1 device is connected it will send back the light shows of that device
    // If multiple devices are connected it will return light shows specific for the lowest
    // common denominator.
    // e.g. ProWand has 3 shows, Spot has 12. So if a spot and a ProWand are connected return the
    // lightshows of ProWand.
    // However, since the Emma Neo 2 is made so that it ignores the 'lightShow' param we can still show
    // Spot or ProWand when either of them is also connected.
    const lightShowDevices = connectedDevices.filter(
      (s) => s.wrapper.playLightShow
    );

    const isProWand =
      lightShowDevices.findIndex(
        (s) => s.wrapper.defaultDeviceName === "ProWand"
      ) > -1;
    if (isProWand) {
      return LIGHT_SHOWS_PROWAND;
    }

    const isSpot =
      lightShowDevices.findIndex(
        (s) => s.wrapper.defaultDeviceName === "Spot"
      ) > -1;
    if (isSpot) {
      return LIGHT_SHOWS_SPOT;
    }

    const isEmmaNeo2 =
      lightShowDevices.findIndex(
        (s) => s.wrapper.defaultDeviceName === "Emma Neo 2"
      ) > -1;
    if (isEmmaNeo2) {
      return LIGHT_SHOWS_EMMA2;
    }

    // because of the async nature of device connections, sometimes this function
    // is fired before the devices array get's updated.
    // Here we return something that doesn't break the code until devices is properly
    // updated and the code can't reach this point anymore.
    return { DontKnow: "nothing" };
  };

  /**
   * Holds the current return value of getLightShows() based on currently connected devices
   */
  const lightShows = useMemo(() => {
    return getLightShows();
  }, [getLightShows]);

  const clearDevices = () => {
    setDevices([]);
  };

  return (
    <ConnectedDevicesContext.Provider
      value={{
        deleteDevice,
        devices,
        discoverDevices,
        disconnect,
        getConnectionState,
        reconnectDevice,
        testDevice,
        vibrateDevices,
        playPatternForGenericDevices,
        playPatternForLighShowDevices,
        clearDevices,
        connectedDevices,
        reloadPage,
        recommendationsVisible,
        setRecommendationsVisible,
        isLightShowDeviceConnected,
        isSpotConnected,
        isProwandConnected,
        connectedLightshowDevices,
        lightShows,
      }}
    >
      {children}
    </ConnectedDevicesContext.Provider>
  );
};

export { ConnectedDevicesContextProvider, useConnectedDevices };
