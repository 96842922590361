import React from "react";
import { cloneDeep } from "lodash";

import styles from "./style.module.scss";

import OBSTemplate from "../common/components/OBSTemplate";
import PatternsRows from "../common/components/PatternsRows";
import TipSettingsAnimation from "../common/components/TipSettingsAnimation";

import imgLogoKiiroo from "./img/Kiiroo.png";
import imgLogoSvakom from "./img/svakom.png";
import imgCoin from "./img/i-coin.png";
import pattern1 from "./img/pattern-1.png";
import pattern2 from "./img/pattern-2.png";
import pattern3 from "./img/pattern-3.png";
import pattern4 from "./img/pattern-4.png";
import pattern5 from "./img/pattern-5.png";
import durImg from "./img/i-timer.png";
import countdownBg from "./img/counter.png";

const settings10 = {
  imgLogo: imgLogoKiiroo,
  coinImg: imgCoin,
  tipValueTextColor: "-webkit-linear-gradient(#ffe2b2, #ffa334, #fe8b24)",
  durationBgImg: durImg,
  durationTextColor: "#fff",
  durationPadding: ["25px", "50px"],
};

const settings11 = {
  ...settings10,
  imgLogo: imgLogoSvakom,
  imgLogoStyle: { paddingTop: 100, paddingBottom: 80 },
};

const animSettings = {
  tipSenderColor: "-webkit-linear-gradient(#fff, #fba8ae)",
  countdownBgImg: countdownBg,
  countdownTextColor: "-webkit-linear-gradient(#fff, #fba8ae)",
};

const WidgetComponent = ({
  preset,
  tips,
  isPreview,
  connectedDevices,
  settings,
}) => {
  const clonePreset = cloneDeep(preset);
  clonePreset.levels[0].thumbnail = pattern1;
  clonePreset.levels[1].thumbnail = pattern2;
  clonePreset.levels[2].thumbnail = pattern3;
  clonePreset.levels[3].thumbnail = pattern4;
  clonePreset.levels[4].thumbnail = pattern5;

  // hide Spot patterns for this widget
  delete clonePreset.levels[5];
  delete clonePreset.levels[6];
  delete clonePreset.levels[7];
  delete clonePreset.levels[8];

  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img
          className={styles.logo}
          src={settings.imgLogo}
          alt=""
          style={settings.imgLogoStyle}
        />
        <TipSettingsAnimation tips={tips} settings={animSettings} />
        <PatternsRows
          preset={clonePreset}
          isPreview={isPreview}
          settings={settings}
        />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"#444"}
      tipSettingsRenderFunction={render}
      tipSettingsWidth={900}
      isPreview={isPreview}
    />
  );
};

export const Template10 = ({ preset, tips, connectedDevices, isPreview }) => (
  <WidgetComponent
    preset={preset}
    tips={tips}
    settings={settings10}
    connectedDevices={connectedDevices}
    isPreview={isPreview}
  />
);

export const Template11 = ({ preset, tips, connectedDevices, isPreview }) => (
  <WidgetComponent
    preset={preset}
    tips={tips}
    settings={settings11}
    connectedDevices={connectedDevices}
    isPreview={isPreview}
  />
);
