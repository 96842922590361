const TOGGLE_OVERLAY = "TOGGLE_OVERLAY";

const widgetReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_OVERLAY:
      localStorage.setItem("overlayActive", String(action.payload));
      return action.payload;
    default:
      break;
  }
};

export { TOGGLE_OVERLAY, widgetReducer };
