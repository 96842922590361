import EventEmitter from "events";
const ee = new EventEmitter();
const EVENT_RANGE_TIP = "RANGE_TIP";
const EVENT_PATTERN_TIP = "PATTERN_TIP";

// Map with device ID as a key.
// Each element of it is another map, where timeout is a key, intensity is a value.
// e.g.
// {
//   "deviceId1": {
//     <timeout1>: 100,
//     <timeout2>: 0,
//   }
//   "deviceId2": {
//     <timeout3>: 0,
//   }
// }
const activeRangeTipsPerDevice = {};
const activePatternTipsPerDevice = {};
let activePatternTimestampEnd = null;

/**
 * Subscribe to the device events
 * @param {func} callback - callback to be called when a message to the device should be sent:
 *  - callback(intensity), where intensity is a percentage
 * @returns function to unsubscribe from the event
 */
export const rangeSubscribe = (callback) => {
  ee.addListener(EVENT_RANGE_TIP, callback);
  return () => {
    ee.removeListener(EVENT_RANGE_TIP, callback);
  };
};

export const patternSubscribe = (callback) => {
  ee.addListener(EVENT_PATTERN_TIP, callback);
  return () => {
    ee.removeListener(EVENT_PATTERN_TIP, callback);
  };
};

/**
 * Function to be called when a tip is received
 * @param {float} duration - duration of the vibration in milliseconds
 * @param {int} intensity - intensity of the vibration in percentage
 */
// TODO need to hadnle devices separately
export const onTipRange = (duration, intensity, deviceId) => {
  // Start the vibration if the new tip is stronger than the active ones
  const activeTips = activeRangeTipsPerDevice[deviceId] || {};
  activeRangeTipsPerDevice[deviceId] = activeTips;
  const maxIntensity = Object.values(activeTips).reduce(
    (prev, cur) => Math.max(prev, cur),
    0
  );
  if (intensity > maxIntensity) {
    ee.emit(EVENT_RANGE_TIP, intensity, deviceId);
  }
  const timeout = setTimeout(() => {
    // Delete current tip from the active tips
    delete activeTips[timeout];
    // Find max strength of remaining tips
    const maxIntensity = Object.values(activeTips).reduce(
      (prev, cur) => Math.max(prev, cur),
      0
    );
    // Keep the vibration at max strength or stop
    // it if there are no more tips
    // TODO if the device is already vibrating at maxIntensity, do not emit.
    ee.emit(EVENT_RANGE_TIP, maxIntensity, deviceId);
  }, duration);

  // Add the active tip to the map
  activeTips[timeout] = intensity;
};

export const onTipPattern = (pattern) => {
  // Start the vibration if the new tip is stronger than the active ones
  const activeTips = activePatternTipsPerDevice[pattern.deviceId] || {};
  activePatternTipsPerDevice[pattern.deviceId] = activeTips;

  const maxTokensAmount = Object.values(activeTips).reduce(
    (prev, cur) => Math.max(prev, cur.tokensAmount),
    0
  );

  if (pattern.tokensAmount > maxTokensAmount) {
    ee.emit(EVENT_PATTERN_TIP, pattern);
    activePatternTimestampEnd = pattern.timeStampEnd;
  }

  const timeout = setTimeout(() => {
    // Delete current tip from the active tips
    delete activeTips[timeout];
    // Find max strength of remaining tips
    const maxTokensAmount = Object.values(activeTips).reduce(
      (prev, cur) => Math.max(prev, cur.tokensAmount),
      0
    );
    if (maxTokensAmount === 0) {
      ee.emit(EVENT_PATTERN_TIP, { stop: true, deviceId: pattern.deviceId });
      activePatternTimestampEnd = null;
      return;
    }

    const foundPattern = Object.values(activeTips).find(
      ({ tokensAmount }) => +tokensAmount === +maxTokensAmount
    );

    if (activePatternTimestampEnd !== foundPattern.timeStampEnd) {
      ee.emit(EVENT_PATTERN_TIP, foundPattern);
      activePatternTimestampEnd = foundPattern.timeStampEnd;
    }
  }, pattern.duration);

  // Add the active tip to the map
  activeTips[timeout] = pattern;
};
