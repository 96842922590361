import React, { createContext, useEffect, useReducer, useState } from "react";
import PubNub from "pubnub";
import { publishKey, subscribeKey } from "lib/pubnub";
import { TOGGLE_OVERLAY, widgetReducer } from "./reducers/widgetReducer";

const WidgetClientContext = createContext();

const useWidgetClient = () => {
  const context = React.useContext(WidgetClientContext);
  if (context === undefined) {
    throw new Error(
      "`useWidgetClient` hook must be used within a `WidgetClientContextProvider` component"
    );
  }
  return context;
};

const WidgetClientContextProvider = ({ children }) => {
  const [channelId, setChannelId] = useState(null);
  const [tips, setTips] = useState([]);
  const [preset, setPreset] = useState(null);
  const [template, setTemplate] = useState(null);
  const [connectedDevices, setConnectedDevices] = useState([]);
  const [widgetStep, setWidgetStep] = useState(0);
  const [overlayActive, dispatchOverlay] = useReducer(
    widgetReducer,
    true,
    () => {
      return localStorage.getItem("overlayActive") !== "false";
    }
  );

  useEffect(() => {
    if (!channelId) {
      // Only initialize PubNub if channel ID is set
      return;
    }

    const pubNubUser = `widget-${channelId}`;
    const pubnub = new PubNub({
      publishKey,
      subscribeKey,
      uuid: pubNubUser,
    });

    const updatePortalState = (state) => {
      if (!state) {
        // No Portal state
        setPreset(null);
        setTemplate(null);
        setConnectedDevices([]);
        setWidgetStep(0);
        return;
      }
      const { preset, template, connectedDevices, widgetStep, overlayActive } =
        state;
      dispatchOverlay({ type: TOGGLE_OVERLAY, payload: overlayActive });
      setPreset(preset);
      setTemplate(template);
      setConnectedDevices(connectedDevices);
      setWidgetStep(widgetStep ?? 0);
    };

    pubnub.addListener({
      message: function (msg) {
        console.log("RECEIVED", msg.message);
        const { what } = msg.message;
        // TODO move const to a separate file
        if (what === "tip") {
          const { payload } = msg.message;
          setTips((oldTips) => [...oldTips, payload]);
        }
      },
      presence: function (presenceEvent) {
        // TODO move to the shared file
        const portalPubNubUser = `portal-${channelId}`;
        if (presenceEvent.uuid === portalPubNubUser) {
          // TODO move to a separate function
          const { state } = presenceEvent;
          updatePortalState(state);
        }
      },
    });
    pubnub.subscribe({
      channels: [channelId],
      withPresence: true,
    });
    // Request current portal state
    // TODO move to the shared file
    const portalPubNubUser = `portal-${channelId}`;
    pubnub.getState(
      {
        uuid: portalPubNubUser,
        channels: [channelId],
      },
      function (status, response) {
        const state = response?.channels?.[channelId];
        updatePortalState(state);
      }
    );
  }, [channelId, overlayActive]);

  return (
    <WidgetClientContext.Provider
      value={{
        preset,
        template,
        widgetStep,
        tips,
        setChannelId,
        connectedDevices,
        overlayActive,
        dispatchOverlay,
      }}
    >
      {children}
    </WidgetClientContext.Provider>
  );
};

export { WidgetClientContextProvider, useWidgetClient };
