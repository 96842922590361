import React from "react";

import styles from "./style.module.scss";
import { translations } from "i18n";

const DeviceReconnectionToast = ({ deviceName, fail }) => {
  return (
    <div className={styles.container}>
      <div>
        {translations("Notifications.DeviceWasDisconnected", { deviceName })}
      </div>
      {fail ? (
        <div>{translations("Notifications.TryToReconnectManually")}</div>
      ) : (
        <div>{translations("Notifications.TryingToReconnect")}</div>
      )}
    </div>
  );
};

export default DeviceReconnectionToast;
