import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/pearl2-promotion.png";

const serviceUuid = "00001900-0000-1000-8000-00805f9b34fb"; // ble service id for sensor data
const motorCharUuid = 0x1902; // motor controlling characteristic

const names = ["Pearl2.1"];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Pearl21 extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, motorCharUuid, image, isSendingData);
  }

  static get deviceNames() {
    return names;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "Kiiroo";
  }

  async write(percent) {
    const value = Math.max(0, Math.min(99, Math.round(percent)));
    const array = new Uint8Array([0x01, value]);
    return this.motorChar.writeValue(array);
  }
}
