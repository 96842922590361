import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import TipAnimator from "../TipAnimator";
import { useTipCountdown } from "hooks/WidgetTipCountdownHook";

const OBSTemplate = ({
  tips,
  connectedDevices,
  animationBgColor,
  animationTextColor = "#fff",
  tipSettingsRenderFunction,
  tipSettingsWidth = 750,
  isPreview = false,
  showAnimation = true,
}) => {
  const { activeTip } = useTipCountdown(tips);
  const animRef = useRef();
  const [isConnected, setConnected] = useState(false);

  useEffect(() => {
    if (!isPreview) {
      const settingsContainer = document.querySelector(
        '[class^="style_settingsContainer"]'
      );
      if (settingsContainer) {
        settingsContainer.style.width = `${tipSettingsWidth}px`;
        settingsContainer.style.zoom = isPreview ? "100%" : "40%";
      }
    }
  }, [isPreview, tipSettingsWidth]);

  useEffect(() => {
    if (activeTip !== null && activeTip.intensity !== "0") {
      animRef.current.play(activeTip.duration);
    }
  }, [activeTip]);

  useEffect(() => {
    setConnected(connectedDevices?.length > 0);
  }, [connectedDevices]);

  return isPreview ? (
    tipSettingsRenderFunction()
  ) : (
    <div className={styles.outerContainer}>
      {tipSettingsRenderFunction()}
      {showAnimation && (
        <div className={styles.animationContainer}>
          <TipAnimator
            ref={animRef}
            isConnected={isConnected}
            backgroundColor={animationBgColor}
            textColor={animationTextColor}
          />
        </div>
      )}
    </div>
  );
};

export default OBSTemplate;
