import {
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  forwardRef,
} from "react";
import Lottie from "lottie-react";
import styles from "./style.module.scss";

import connectedAnim from "../../animations/connected.json";
import disconnectedAnim from "../../animations/disconnected.json";
import progressAnim from "../../animations/counter.json";
import vibrateAnim from "../../animations/vibrate.json";

import vibrator_white from "./img/vibrator_white.svg";

const VIBRATE_START_FRAMES = [1, 37];
const VIBRATE_LOOP_FRAMES = [37, 50];
const VIBRATE_END_FRAMES = [117, 126];
const PROGRESS_FRAMES = [5, 151];
const PROGRESS_LENGTH = 5;
const VIBRATE_END_LENGTH = 300;

const TipAnimator = forwardRef(
  ({ backgroundColor, isConnected, width = 150, textColor = "#fff" }, ref) => {
    const progressRef = useRef();
    const connectionRef = useRef();
    const vibrateRef = useRef();
    const [timer, setTimer] = useState(-1);
    const [startTimer, setStartTimer] = useState(false);
    const [connectionAnim, setConnectionAnim] = useState(disconnectedAnim);
    const [isVibratorVisible, setVibratorVisible] = useState(true);

    useEffect(() => {
      setConnectionAnim(isConnected ? connectedAnim : disconnectedAnim);
      if (startTimer) {
        if (timer > -1) {
          setTimeout(() => {
            setTimer(timer - 1);
          }, 1000);
        } else {
          setStartTimer(false);
        }
      }
    }, [isConnected, startTimer, timer]);

    // #region Public Functions
    useImperativeHandle(ref, () => ({
      play(duration) {
        playVibrate(duration);
        setTimer(duration - 1);
        setStartTimer(true);
      },
    }));

    const playVibrate = (duration) => {
      setVibratorVisible(false);
      vibrateRef.current.playSegments(VIBRATE_START_FRAMES, true);
      vibrateRef.current.playSegments(VIBRATE_LOOP_FRAMES, false);
      const speed = PROGRESS_LENGTH / duration;
      progressRef.current.setSpeed(speed);
      progressRef.current.playSegments(PROGRESS_FRAMES, false);

      setTimeout(() => {
        vibrateRef.current.playSegments(VIBRATE_END_FRAMES, true);
        vibrateRef.current.playSegments([0, 1], false);
        progressRef.current.playSegments([0, 1], true);
        setTimeout(() => {
          setVibratorVisible(true);
        }, VIBRATE_END_LENGTH);
      }, duration * 1000);
    };
    // #endregion Public Functions

    return (
      <div
        style={{
          width: `${width}px`,
          height: `${width * 1.4}px`,
        }}
        className={styles.background}
      >
        <div
          style={{
            backgroundColor: backgroundColor,
          }}
          className={styles.circle}
        />
        <Lottie
          lottieRef={progressRef}
          animationData={progressAnim}
          autoplay={false}
          loop={false}
          className={styles.progress}
        />
        {isVibratorVisible && (
          <img
            src={vibrator_white}
            alt="Vibrator"
            className={styles.vibrator}
          />
        )}
        {timer > -1 && (
          <p
            className={styles.timer}
            style={{
              color: textColor,
              WebkitTextStroke: `1px ${backgroundColor}`,
            }}
          >
            {timer}
          </p>
        )}
        <Lottie
          lottieRef={connectionRef}
          animationData={connectionAnim}
          autoplay={true}
          loop={true}
          className={styles.connection}
        />
        <Lottie
          lottieRef={vibrateRef}
          animationData={vibrateAnim}
          autoplay={false}
          loop={true}
          className={styles.vibrate}
        />
      </div>
    );
  }
);

export default TipAnimator;
