import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import cn from "classnames";

const WheelOfFortuneRows = ({
  preset,
  settings,
  indexWon,
  isPreview = false,
  showGiftImg = false,
}) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const levels = preset.levels || [];
    setRows(
      levels.map((level, index) => {
        if (!level.active) {
          // Do not display if the row is not active
          return null;
        }

        if (typeof indexWon === "number" && indexWon !== index) {
          // show only final option after tipped buy-in
          return null;
        }

        let tipValueTextStyle = {
          color: settings.tipValueTextColor,
        };
        if (settings.tipValueTextColor.includes("gradient")) {
          tipValueTextStyle = {
            backgroundImage: settings.tipValueTextColor,
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          };
        }
        if (settings.tipValueTextFontSize) {
          tipValueTextStyle = {
            ...tipValueTextStyle,
            fontSize: settings.tipValueTextFontSize,
          };
        }
        if (settings.tipValueTextFontFamily) {
          tipValueTextStyle = {
            ...tipValueTextStyle,
            fontFamily: settings.tipValueTextFontFamily,
          };
        }
        if (settings.tipValueTextTransform) {
          tipValueTextStyle = {
            ...tipValueTextStyle,
            textTransform: settings.tipValueTextTransform,
          };
        }
        if (settings.tipValueTextFontWeight) {
          tipValueTextStyle = {
            ...tipValueTextStyle,
            fontWeight: settings.tipValueTextFontWeight,
          };
        }

        return (
          <div
            className={cn(
              styles.tipsRange,
              typeof indexWon === "number" && styles.oneOption
            )}
            key={index}
          >
            {showGiftImg && (
              <img
                className={styles.gift}
                src={settings.imgGift}
                alt=""
                style={settings.giftStyle}
              />
            )}
            {typeof indexWon !== "number" && settings.optionStyles && (
              <div style={settings.optionStyles}>{index + 1}</div>
            )}
            <p className={styles.option} style={tipValueTextStyle}>
              {`${
                typeof indexWon === "number" || !showGiftImg
                  ? ""
                  : index + 1 + " - "
              }${level.title}`}
            </p>
          </div>
        );
      })
    );
  }, [settings, isPreview, preset.levels]);

  return <React.Fragment>{rows}</React.Fragment>;
};

export default WheelOfFortuneRows;
