import React from "react";
import styles from "./style.module.scss";

import OBSTemplate from "../common/components/OBSTemplate";
import TipSettingRows from "../common/components/TipSettingRows";

import imgLogo from "./img/svakom-logo.png";
import imgCoin from "./img/i-coin.png";
import imgLowLeft from "./img/i-low-left.png";
import imgLowRight from "./img/i-low-right.png";
import imgMediumLeft from "./img/i-medium-left.png";
import imgMediumRight from "./img/i-medium-right.png";
import imgHighLeft from "./img/i-high-left.png";
import imgHighRight from "./img/i-high-right.png";

import durImg from "./img/i-timer.png";

const settings = {
  coinImg: imgCoin,
  tipValueTextColor: "-webkit-linear-gradient(#ffe2b2, #ffa334, #fe8b24)",
  durationBgImg: durImg,
  durationTextColor: "#fff",
  durationPadding: [
    ["25px", "25px"],
    ["5px", "15px"],
  ],
  intensityAssets: [
    [
      imgLowLeft,
      imgLowRight,
      "-webkit-linear-gradient(#d4ffb2, #55ff34, #4b9665)",
    ],
    [
      imgMediumLeft,
      imgMediumRight,
      "-webkit-linear-gradient(#fff6b2, #ffce85, #efbe65)",
    ],
    [imgHighLeft, imgHighRight, "-webkit-linear-gradient(#ff7878, #fd3a3a)"],
    [imgHighLeft, imgHighRight, "-webkit-linear-gradient(#ff7878, #fd3a3a)"],
  ],
};

const WidgetComponent = ({ preset, tips, isPreview, connectedDevices }) => {
  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img className={styles.logo} src={imgLogo} alt="" />
        <TipSettingRows
          preset={preset}
          isPreview={isPreview}
          settings={settings}
        />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"#444"}
      tipSettingsRenderFunction={render}
      tipSettingsWidth={900}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
