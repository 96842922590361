import React, { createContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";

const AuthContext = createContext();

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "`useAuth` hook must be used within a `AuthContextProvider` component"
    );
  }
  return context;
};

const AuthContextProvider = ({ children }) => {
  let [authenticatedUser, setAuthenticatedUser] = useState(null);

  useEffect(() => {
    const updateAuthUser = async () => {
      let user = null;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (error) {
        // Do nothing if user is not authenticated
      }
      setAuthenticatedUser(user);
    };
    updateAuthUser();
  }, []);

  const confirmSignUp = async (username, code) => {
    const result = {
      error: undefined,
      response: {},
    };
    try {
      result.response = await Auth.confirmSignUp(username, code);
      console.log(result.response);
    } catch (error) {
      console.log("error confirmSignUp:", error);
      result.error = error;
    }
    return result;
  };

  const resendSignUp = async (username) => {
    const result = {
      error: undefined,
      response: {},
    };
    try {
      result.response = await Auth.resendSignUp(username);
    } catch (error) {
      console.log("error resendSignUp:", error);
      result.error = error;
    }
    return result;
  };

  const signIn = async (usernameOrAlias, password) => {
    const cognitoUser = await Auth.signIn(usernameOrAlias, password);
    setAuthenticatedUser(cognitoUser);
  };

  const deleteUser = async () => {
    console.log(authenticatedUser);
    if (!authenticatedUser) {
      // User is not authenticated
      return;
    }

    return new Promise((resolve, reject) => {
      authenticatedUser.deleteUser((error) => {
        if (error) {
          return reject(error);
        }
        resolve();
      });
    });
  };

  const changePassword = async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        authenticatedUser,
        confirmSignUp,
        resendSignUp,
        deleteUser,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, useAuth };
