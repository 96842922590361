import image from "images/devices/emma2-promotion.png";
import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import AsyncWriteQueue from "../AsyncWriteQueue";
import { hexToRgb, rgbToHex } from "utils/utils";

const serviceUuid = 0xffe0;
const commandUuid = 0xffe1;
const readUuid = 0xffe2;

const MAX_INTENSITY = 0x0a;

const DEVICE_NAME = "Emma Neo 2";

const CONNECTED_COLOR = "#00aaFF"; // Cyan like Blue

export default class Emma2 extends BaseDeviceWrapper {
  private readChar: BluetoothRemoteGATTCharacteristic | null = null;
  private cmdQueue: AsyncWriteQueue;
  public defaultDeviceName: string = "Emma Neo 2";

  constructor(device: BluetoothDevice, isSendingData: boolean) {
    super(device, serviceUuid, commandUuid, image, isSendingData);
    this.cmdQueue = new AsyncWriteQueue(100);
  }

  /**
   * deviceNames
   * @returns {string[]} Array of string containing the possible device names.
   */
  static get deviceNames(): string[] {
    return [DEVICE_NAME, "Emma2"];
  }

  /**
   * Needed to request access to these services before connecting to the device
   * @returns {number[]} Array of hex strings.
   */
  static get services(): number[] {
    return [serviceUuid];
  }

  /**
   * companyName
   * @returns {string} String containing the name of the company that manufactured this device.
   */
  get companyName(): string {
    return "Svakom";
  }

  /**
   * Connects to this device.
   * @returns {Promise<void>} Promise that resolves when the device successfully connects
   */
  async connect(): Promise<void> {
    return await super.connect(async () => {
      this.readChar = await this.sensorService!.getCharacteristic(readUuid);
      this.cmdQueue.enqueue(async () => await this.setColor(CONNECTED_COLOR));
      this.cmdQueue.enqueue(async () => await this.setLightLevel(2));
    });
  }

  /**
   * Write a vibration command to the device.
   * @param {number} percent Intensity of the vibration (0-100)
   * @returns {Promise<void>} Result of the write action against the device
   */
  async write(percent: number): Promise<void> {
    const intensity = Math.max(
      0,
      Math.min(MAX_INTENSITY, (percent * MAX_INTENSITY) / 100)
    );
    const data = intensity
      ? [0x55, 0x03, 0x00, 0x00, 0x01, intensity]
      : [0x55, 0x03, 0x00, 0x00, 0x00, 0x00];
    const array = new Uint8Array(data);
    this.cmdQueue.enqueue(async () => await this.motorChar.writeValue(array));
  }

  /**
   * Lights up the device for the given duration and with the given color.
   * @param {number} duration Duration in msec.
   * @param {string} show This param is ignored by this device, it doesn't support on board lightshows.
   * @param {string} color RGB string color in the format 'rgb(255, 255, 255)'
   */
  async playLightShow(
    duration: number,
    show: string,
    color: string
  ): Promise<void> {
    const hexColor = rgbToHex(color);
    this.cmdQueue.enqueue(async () => await this.setColor(hexColor));
    this.cmdQueue.enqueue(async () => await this.setLightLevel(10));

    setTimeout(async () => {
      this.cmdQueue.enqueue(async () => this.setLightLevel(2));
      this.cmdQueue.enqueue(async () => await this.setColor(CONNECTED_COLOR));
    }, duration);
  }

  /**
   * Processes an incoming tip. It will vibrate the device with the given intensity and light up with
   * the given color. After totalDuration the device stops.
   * @param {number} totalDuration Duration in msec.
   * @param {number} intensity Vibrate intensity (0-100)
   * @param {string} lightShow This param is ignored on this device. It doesn't support on board lightshows
   * @param {string} lightColor Hex color (e.g. #FFFFFF)
   */
  async processTipRange(
    totalDuration: number,
    intensity: number,
    lightShow: string,
    lightColor: string
  ): Promise<void> {
    this.cmdQueue.enqueue(async () => await this.write(intensity));
    const color = hexToRgb(lightColor);
    this.playLightShow(totalDuration, "", color);

    setTimeout(async () => {
      this.cmdQueue.enqueue(async () => await this.write(0));
    }, totalDuration);
  }

  /**
   * Sets the color of the LED on the back of the device.
   * @param {string} color Color in hex format (e.g. #FFFFFF)
   * @returns {Promise<void>} Resolves to the result of the BLE write action
   */
  async setColor(color: string): Promise<void> {
    const hexValues = this.hexSplit(color);
    const arr = new Uint8Array([
      0x55,
      0xa1,
      0x00,
      hexValues[0],
      hexValues[1],
      hexValues[2],
    ]);
    return await this.motorChar.writeValue(arr);
  }

  /**
   * Sets the light level (intensity) of the LED on the back of the device.
   * @param {number} intensity Light intensity (0-10)
   */
  async setLightLevel(intensity: number): Promise<void> {
    const cmd = intensity < 1 ? 0x02 : 0x01;
    const arr = new Uint8Array([0x55, 0xa0, cmd, intensity, 0x00, 0x00, 0x00]);
    return await this.motorChar.writeValue(arr);
  }

  /**
   * Requests the device battery level.
   * @returns {Promise<number>} Returns a Promise that resolves to the battery level of the device (0-100)
   */
  async getBattery(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.cmdQueue.enqueue(
        async () => {
          await this.motorChar.writeValue(new Uint8Array([0x55, 0x02]));
          const value = await this.readChar!.readValue();
          return value.getUint8(2);
        },
        (value: number) => {
          resolve(value);
        }
      );
    });
  }

  /**
   * Splits the given color (HEX) into an array that is ready to be sent to the device.
   * @param {string} hexColor Color in HEX format (#FFFFFF)
   * @returns {number[]} Array containing the given color's R, G and B values
   */
  hexSplit(hexColor: string): number[] {
    const hexR = parseInt(`0x${hexColor[1]}${hexColor[2]}`, 16);
    const hexG = parseInt(`0x${hexColor[3]}${hexColor[4]}`, 16);
    const hexB = parseInt(`0x${hexColor[5]}${hexColor[6]}`, 16);
    return [hexR, hexG, hexB];
  }
}
