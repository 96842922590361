import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/fuse-promotion.png";

const serviceUuid = [
  "00001900-0000-1000-8000-00805f9b34fb",
  "c0961900-0000-1000-8000-00805f9b34fb",
]; // ble service id for sensor data
const characteristicUuid = 0x1902; // motor controlling characteristic

const names = ["Fuse1.1"];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Fuse extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
  }

  static get deviceNames() {
    return names;
  }

  static get services() {
    return [...serviceUuid];
  }

  get companyName() {
    return "Kiiroo";
  }

  async write(percent) {
    const value = Math.max(0, Math.min(100, Math.round(percent)));
    const array = new Uint8Array([0x01, value]);
    return this.motorChar.writeValue(array);
  }
}
