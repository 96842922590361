import React, { createContext, useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import { DEFAULT_PATTERNS } from "data/constants";

const PATTERNS_KEY = "patterns_V4";

const TipSettingsContext = createContext();

const useTipPatterns = () => {
  const context = React.useContext(TipSettingsContext);
  if (context === undefined) {
    throw new Error(
      "`useTipPatterns` hook must be used within a `TipPatternsContextProvider` component"
    );
  }
  return context;
};

const getLocalStoragePatterns = () => {
  const localStoragePatternsStr = localStorage.getItem(PATTERNS_KEY);
  if (!localStoragePatternsStr) {
    return DEFAULT_PATTERNS;
  }

  try {
    return JSON.parse(localStoragePatternsStr);
  } catch (e) {
    return DEFAULT_PATTERNS;
  }
};

const TipPatternsContextProvider = ({ children }) => {
  const [patterns, setPatterns] = useState(getLocalStoragePatterns());

  // Save patterns to the local storage on each update
  useEffect(() => {
    localStorage.setItem(PATTERNS_KEY, JSON.stringify(patterns));
  }, [patterns]);

  const resetPatternsToDefault = () => {
    setPatterns(DEFAULT_PATTERNS);
  };

  const updateLevel = (value, levelIndex) => {
    const patternsCopy = cloneDeep(patterns);
    patternsCopy[0].levels[levelIndex] = {
      ...patternsCopy[0].levels[levelIndex],
      ...value,
    };
    setPatterns(patternsCopy);
  };

  return (
    <TipSettingsContext.Provider
      value={{
        resetPatternsToDefault,
        updateLevel,
        patterns,
      }}
    >
      {children}
    </TipSettingsContext.Provider>
  );
};

export { TipPatternsContextProvider, useTipPatterns };
