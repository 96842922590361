import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/hannes-promotion.png";

const serviceUuid = 0xffe0;
const characteristicUuid = 0xffe1;

const WAIT_TIME_BETWEEN_COMMANDS = 100;

const DEVICE_NAME = "Hannes NEO";

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class SvakomHannes extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
    // We need to keep track of the last movement to avoid sending the same command
    // previousMovement is an object with two properties: ongoing (boolean) and direction (string: up or down)
    this.previousMovement = { ongoing: false, direction: 'down' };
  }

  static get deviceNames() {
    return DEVICE_NAME;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "Svakom";
  }

  async writeMode(mode, strength) {
    const data =
      mode === 0xff
        ? [0x55, 0x03, 0x00, 0x00, 0x00, 0x00]
        : [0x55, 0x03, 0x03, 0x00, mode, strength];
    const array = new Uint8Array(data);
    await this.motorChar.writeValue(array);
  }

  async write(percent) {
    const direction = percent > 0 ? 'up' : 'down';

    if (this.previousMovement.ongoing || this.previousMovement.direction === direction) {
      return;
    }
    this.previousMovement = { ongoing: true, direction: direction };
  
    setTimeout(() => {
      this.previousMovement.ongoing = false;
    }, WAIT_TIME_BETWEEN_COMMANDS);

    // Mode is basically the device's pattern 1..6
    // 0xff is the stop command
    const mode = percent <= 0 ? 0xff : Math.floor(percent/ (100/6));
    const strength = Math.floor(percent / 10);
    
    await this.writeMode(mode, strength);
  }
}
