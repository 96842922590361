import React from "react";
import styles from "./style.module.scss";

import OBSTemplate from "../common/components/OBSTemplate";
import TipSettingRows from "../common/components/TipSettingRows";

import imgLogo from "./img/OBS-template-3-logo.png";
import imgCoin from "./img/OBS-template-3-coin.png";
import imgIntens1 from "./img/OBS-template-4-low.png";
import imgIntens3 from "./img/OBS-template-4-medium.png";
import imgIntens5 from "./img/OBS-template-4-high.png";
import imgIntens6 from "./img/OBS-template-4-ultra-high.png";
import durBg from "./img/OBS-template-3-timer.png";

const settings = {
  coinImg: imgCoin,
  tipValueTextColor: "-webkit-linear-gradient(#ffffff, #d0e3ff, #d0e3ff)",
  durationBgImg: durBg,
  durationTextColor: "#fff",
  durationPadding: [
    ["25px", "25px"],
    ["15px", "15px"],
  ],
  intensityAssets: [imgIntens1, imgIntens3, imgIntens5, imgIntens6],
};

const WidgetComponent = ({ preset, tips, isPreview, connectedDevices }) => {
  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img className={styles.logo} src={imgLogo} alt="" />
        <TipSettingRows
          preset={preset}
          isPreview={isPreview}
          settings={settings}
        />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"#222585"}
      animationTextColor={"#d0e2ff"}
      tipSettingsRenderFunction={render}
      tipSettingsWidth={850}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
