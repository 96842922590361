import React from "react";
import { cloneDeep } from "lodash";

import styles from "./style.module.scss";

import OBSTemplate from "../common/components/OBSTemplate";
import TipSettingRows from "../common/components/TipSettingRows";
import TipSettingsAnimation from "../common/components/TipSettingsAnimation";
import PatternsRows from "../common/components/PatternsRows";

import imgLogo5 from "./img/OBS-template-5-logo.png";
import imgLogo6 from "./img/OBS-template-6-logo.png";

import imgCoin from "./img/OBS-template-5-coin.png";
import imgIntensLeftLow from "./img/OBS-template-5-left-low.png";
import imgIntensRightLow from "./img/OBS-template-5-right-low.png";
import imgIntensLeftMedium from "./img/OBS-template-5-left-low-medium.png";
import imgIntensRightMedium from "./img/OBS-template-5-right-low-medium.png";
import imgIntensLeftHigh from "./img/OBS-template-5-left-medium.png";
import imgIntensRightHigh from "./img/OBS-template-5-right-medium.png";
import imgIntensLeftUHigh from "./img/OBS-template-5-left-high.png";
import imgIntensRightUHigh from "./img/OBS-template-5-right-high.png";
import durBgImg from "./img/OBS-template-5-timer.png";

import pattern1 from "./img/pattern-1.png";
import pattern2 from "./img/pattern-2.png";
import pattern3 from "./img/pattern-3.png";
import pattern4 from "./img/pattern-4.png";
import pattern5 from "./img/pattern-5.png";

import countdownBg from "./img/OBS-template-5-counter.png";

const wrapPresetWithThumbnails = (preset) => {
  const clonePreset = cloneDeep(preset);
  clonePreset.levels[0].thumbnail = pattern1;
  clonePreset.levels[1].thumbnail = pattern2;
  clonePreset.levels[2].thumbnail = pattern3;
  clonePreset.levels[3].thumbnail = pattern4;
  clonePreset.levels[4].thumbnail = pattern5;

  // hide Spot patterns for this widget
  delete clonePreset.levels[5];
  delete clonePreset.levels[6];
  delete clonePreset.levels[7];
  delete clonePreset.levels[8];

  return clonePreset;
};

const settings5 = {
  coinImg: imgCoin,
  logoImg: imgLogo5,
  tipValueTextColor: "-webkit-linear-gradient(#ffedfd, #ffb2b3)",
  durationBgImg: durBgImg,
  durationTextColor: "#fff",
  durationPadding: [
    ["30px", "20px"],
    ["15px", "15px"],
  ],
  intensityAssets: [
    [
      imgIntensLeftLow,
      imgIntensRightLow,
      "-webkit-linear-gradient(#ffedfd, #ffb2b3)",
    ],
    [
      imgIntensLeftMedium,
      imgIntensRightMedium,
      "-webkit-linear-gradient(#f8b7cc, #fc9798)",
    ],
    [
      imgIntensLeftHigh,
      imgIntensRightHigh,
      "-webkit-linear-gradient(#fba8ae, #e64446)",
    ],
    [
      imgIntensLeftUHigh,
      imgIntensRightUHigh,
      "-webkit-linear-gradient(#fba8ae, #e64446)",
    ],
  ],
};

const settings6 = {
  ...settings5,
  logoImg: imgLogo6,
};

const settings15 = {
  ...settings5,
  durationPadding: ["25px", "30px"],
};

const settings16 = {
  ...settings6,
  durationPadding: ["25px", "30px"],
};

const animSettings = {
  tipSenderColor: "-webkit-linear-gradient(#fff, #fba8ae)",
  countdownBgImg: countdownBg,
  countdownTextColor: "-webkit-linear-gradient(#fff, #fba8ae)",
};

const WidgetComponent = ({
  preset,
  tips,
  settings,
  connectedDevices,
  isPreview,
  isForPatterns = false,
}) => {
  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img className={styles.logo} src={settings.logoImg} alt="" />
        <TipSettingsAnimation tips={tips} settings={animSettings} />
        {isForPatterns ? (
          <PatternsRows
            preset={preset}
            isPreview={isPreview}
            settings={settings}
          />
        ) : (
          <TipSettingRows
            preset={preset}
            settings={settings}
            isPreview={isPreview}
          />
        )}
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"#c7272e"}
      animationTextColor={"#ffcccc"}
      tipSettingsRenderFunction={render}
      tipSettingsWidth={850}
      isPreview={isPreview}
    />
  );
};

export const Template5 = ({ preset, tips, connectedDevices, isPreview }) => (
  <WidgetComponent
    preset={preset}
    tips={tips}
    settings={settings5}
    connectedDevices={connectedDevices}
    isPreview={isPreview}
  />
);

export const Template6 = ({ preset, tips, connectedDevices, isPreview }) => (
  <WidgetComponent
    preset={preset}
    tips={tips}
    settings={settings6}
    connectedDevices={connectedDevices}
    isPreview={isPreview}
  />
);

export const Template15 = ({ preset, tips, connectedDevices, isPreview }) => {
  const wrappedPreset = wrapPresetWithThumbnails(preset);

  return (
    <WidgetComponent
      preset={wrappedPreset}
      tips={tips}
      settings={settings15}
      connectedDevices={connectedDevices}
      isPreview={isPreview}
      isForPatterns
    />
  );
};

export const Template16 = ({ preset, tips, connectedDevices, isPreview }) => {
  const wrappedPreset = wrapPresetWithThumbnails(preset);

  return (
    <WidgetComponent
      preset={wrappedPreset}
      tips={tips}
      settings={settings16}
      connectedDevices={connectedDevices}
      isPreview={isPreview}
      isForPatterns
    />
  );
};
