import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useWidgetClient } from "hooks/WidgetClientHook";
import WidgetComponent from "./WidgetComponent";

import Loader from "components/Loader/Loader";

const WidgetPage = () => {
  const {
    preset,
    template,
    tips,
    connectedDevices,
    setChannelId,
    overlayActive,
    widgetStep,
  } = useWidgetClient();

  const { channelId } = useParams();

  useEffect(() => {
    setChannelId(channelId);
  }, [channelId, setChannelId]);

  if (!overlayActive) return null;

  if (!preset || !template) {
    // Data is not yet ready
    // TODO show a loader
    return <Loader />;
  }

  return (
    <WidgetComponent
      preset={preset}
      template={template}
      widgetStep={widgetStep}
      tips={tips}
      isPreview={false}
      connectedDevices={connectedDevices}
    />
  );
};

export default WidgetPage;
