import React from "react";

// Creating custom hook with context
const CreateHook = (name, context) => {
  const reactContext = React.useContext(context);
  if (!reactContext) {
    throw new Error(
      `${name} hook must be used within a ContextProvider component`
    );
  }
  return reactContext;
};

const shouldSendAnalytics = () => {
  const isLocalhost = () => {
    return (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1" ||
      window.location.hostname ===
        "dev-7233-add-spot-to-feel-performer.d3b7w7wvinzi8a.amplifyapp.com"
    );
  };
  return process.env.NODE_ENV === "production" && !isLocalhost();
};

// ----- converting values to bytes -----
const convertPercentToCommandValue = (percent) => {
  return Math.max(1, Math.round((percent / 100) * 255));
};

const convertValueToUint16 = (value) => {
  const lowByte = value & 0xff;
  const highByte = (value >> 8) & 0xff;
  return [highByte, lowByte];
};

const rgbStringToArray = (rgbString) => {
  // colors for LED has to be written in the following order: GREEN, BLUE, RED
  const rgbArray = rgbString.match(/\d+/g).map((value) => parseInt(value, 10));
  const firstElement = rgbArray.shift();
  rgbArray.push(firstElement);
  return [rgbArray[0], rgbArray[2], rgbArray[1]];
};

const mapHexColorsToNames = (hexColor) => {
  switch (hexColor.toUpperCase()) {
    case "#CC0000":
      return "Red";
    case "#CC8400":
      return "Orange";
    case "#CCCC00":
      return "Yellow";
    case "#00CC00":
      return "Green";
    case "#00CCCC":
      return "Cyan";
    case "#0000CC":
      return "Blue";
    case "#6600CC":
      return "Violet";
    case "#CC00CC":
      return "Magenta";
    case "#CC99A3":
      return "Pink";
    default:
      return hexColor.toUpperCase();
  }
};

const hexToRgb = (hex) => {
  if (!hex) {
    return;
  }
  // Remove the hash if present
  hex = hex.replace(/^#/, "");

  // Parse the hex values to RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgb(${r},${g},${b})`;
};

/**
 * Converts a given rgb colorstring back to a hex colorstring.
 * @param {string} rgb Color in rgb string format (rgb(255, 255, 255))
 * @returns {string} Hex color (#FFFFFF)
 */
const rgbToHex = (rgb) => {
  if (!rgb) {
    return;
  }

  // Extract the RGB values from the string
  const result = rgb.match(/\d+/g);

  if (!result || result.length !== 3) {
    return;
  }

  // Convert the RGB values to hexadecimal
  const r = parseInt(result[0], 10).toString(16).padStart(2, "0");
  const g = parseInt(result[1], 10).toString(16).padStart(2, "0");
  const b = parseInt(result[2], 10).toString(16).padStart(2, "0");

  return `#${r}${g}${b}`;
};

const findMaxRGBValue = (rgbArray) => {
  let max = 0;
  // Loop through each RGB string in the array
  rgbArray.forEach((rgbString) => {
    // Extract the values of red, green, and blue from the RGB string
    const matches = rgbString.match(/rgb\((\d+),(\d+),(\d+)\)/);

    if (matches) {
      // Convert the extracted values to numbers
      const [_, red, green, blue] = matches.map(Number);
      // Update the max value if any of x, y, or z is greater
      max = Math.max(max, red, green, blue);
    }
  });

  return max;
};

export {
  CreateHook,
  shouldSendAnalytics,
  convertPercentToCommandValue,
  convertValueToUint16,
  rgbStringToArray,
  mapHexColorsToNames,
  hexToRgb,
  rgbToHex,
  findMaxRGBValue,
};
