import React, { createContext, useEffect, useState } from "react";
import { useTipSettings } from "./TipSettingsHook";
import { useTipPatterns } from "./TipPatternsHook";
import { useWheelOfFortune } from "hooks/WheelOfFortuneHook";
import DEFAULT_WEBSITES from "./websites";

const LOCAL_STORAGE_KEY = "websites_V2";

const WebsitesContext = createContext();

const useWebsites = () => {
  const context = React.useContext(WebsitesContext);
  if (context === undefined) {
    throw new Error(
      "`useWebsites` hook must be used within a `WebsitesContextProvider` component"
    );
  }
  return context;
};

/**
 * Load websites settings from localStorage
 * @returns Array of websites DEFAULT_WEBSITES with variable attributes (tipSettingsId, enabled)
 *          updated from localStorage
 */
const getLocalStorageWebsites = () => {
  const localStorageWebsitesStr =
    localStorage.getItem(LOCAL_STORAGE_KEY) || "{}";
  try {
    const settingsMap = JSON.parse(localStorageWebsitesStr);
    // Update DEFAULT_WEBSITES variable settings from the local storage and return them
    return DEFAULT_WEBSITES.map((website) => {
      const { id } = website;
      const settings = settingsMap[id];
      return {
        ...website,
        tipSettingsId: settings ? settings.tipSettingsId : "Tip ranges",
        enabled: settings ? settings.enabled : true, // Enabled by default
      };
    });
  } catch (e) {
    return [];
  }
};

const WebsitesContextProvider = ({ children }) => {
  /**
   * List of the websites supported by the system
   * Based on DEFAULT_WEBSITES, but each website have extra properties,
   * which be changed and are stored in localStorage
   * tipSettingsId - id of Tip settings
   * enabled - boolean flag indicating if the website is enabled in UI (Webcam Sites page)
   */
  const [websites, setWebsites] = useState(getLocalStorageWebsites());
  const { presets } = useTipSettings();
  const { patterns } = useTipPatterns();
  const { options } = useWheelOfFortune();

  // Save settings to the local storage on each update
  useEffect(() => {
    const notifyExtension = () => {
      const websiteList = [];
      websites.forEach((website) => {
        website.hosts.forEach((hostname) => {
          websiteList.push({
            hostname,
            active: website.enabled,
          });
        });
      });

      const request = {
        what: "PORTAL_PAGE_CONFIG",
        payload: {
          websites: websiteList,
        },
      };
      window.postMessage(request, "*");
    };

    const settings = {};
    websites.forEach((website) => {
      const { id } = website;
      settings[id] = {
        tipSettingsId: website.tipSettingsId,
        enabled: website.enabled,
      };
    });
    const websitesStr = JSON.stringify(settings);
    localStorage.setItem(LOCAL_STORAGE_KEY, websitesStr);

    // Notify the extension
    // Delay is needed in order to handle initial page load
    // when content script doesn't load immediately
    setTimeout(notifyExtension, 300);
  }, [websites]);

  useEffect(() => {
    // Every time the presets, patterns or wheelOfFortune change, we need to update websites
    // to check if selected preset still exists
    let newWebsites = websites;
    const defaultPresetId = presets[0]?.id;
    websites.forEach((website, idx) => {
      const { tipSettingsId } = website;
      // Check if the website tip settings are still in the list of settings (presets)
      const found = presets
        .concat(patterns, options)
        .find((p) => p.id === tipSettingsId);
      if (!found) {
        newWebsites = [...newWebsites];
        newWebsites[idx].tipSettingsId = defaultPresetId;
      }
    });
    if (newWebsites !== websites) {
      setWebsites(newWebsites);
    }
  }, [presets, patterns, websites]);

  const enableWebsite = (index, enabled) => {
    const website = websites[index];
    website.enabled = enabled;
    setWebsites([...websites]);
  };

  const setTipSettingsId = (index, tipSettingsId) => {
    const website = websites[index];
    website.tipSettingsId = tipSettingsId;
    setWebsites([...websites]);
  };

  return (
    <WebsitesContext.Provider
      value={{
        websites,
        enableWebsite,
        setTipSettingsId,
      }}
    >
      {children}
    </WebsitesContext.Provider>
  );
};

export { WebsitesContextProvider, useWebsites };
