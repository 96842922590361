import React from "react";
import { cloneDeep } from "lodash";

import styles from "./style.module.scss";

import OBSTemplate from "../common/components/OBSTemplate";
import PatternsRows from "../common/components/PatternsRows";
import TipSettingsAnimation from "../common/components/TipSettingsAnimation";

import imgLogo from "./img/logo.png";
import imgCoin from "./img/coin.png";
import pattern1 from "./img/pattern-1.png";
import pattern2 from "./img/pattern-2.png";
import pattern3 from "./img/pattern-3.png";
import pattern4 from "./img/pattern-4.png";
import pattern5 from "./img/pattern-5.png";
import durImg from "./img/timer.png";
import countdownBg from "./img/counter.png";

const settings = {
  coinImg: imgCoin,
  imgLogo,
  coinStyle: { height: 90 },
  tipValueTextColor: "-webkit-linear-gradient(#ffffff, #d0e3ff, #d0e3ff)",
  tipValueTextFontSize: 46,
  durationBgImg: durImg,
  durationTextColor: "#fff",
  durationTextFontSize: 38,
  durationTextFrontWeight: 700,
  durationPadding: ["25px", "20px"],
  thumbnailStyle: { height: 100, maxWidth: 140, paddingRight: 20 },
};

const animSettings = {
  tipSenderColor: "-webkit-linear-gradient(#fff, #fba8ae)",
  countdownBgImg: countdownBg,
  countdownTextColor: "-webkit-linear-gradient(#fff, #fba8ae)",
};

const WidgetComponent = ({ preset, tips, isPreview, connectedDevices }) => {
  const clonePreset = cloneDeep(preset);
  clonePreset.levels[0].thumbnail = pattern1;
  clonePreset.levels[1].thumbnail = pattern2;
  clonePreset.levels[2].thumbnail = pattern3;
  clonePreset.levels[3].thumbnail = pattern4;
  clonePreset.levels[4].thumbnail = pattern5;

  // hide Spot patterns for this widget
  delete clonePreset.levels[5];
  delete clonePreset.levels[6];
  delete clonePreset.levels[7];
  delete clonePreset.levels[8];

  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img className={styles.logo} src={settings.imgLogo} alt="" />
        <TipSettingsAnimation tips={tips} settings={animSettings} />
        <PatternsRows
          preset={clonePreset}
          isPreview={isPreview}
          settings={settings}
        />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"#222585"}
      animationTextColor={"#d0e3ff"}
      tipSettingsRenderFunction={render}
      tipSettingsWidth={900}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
