import React from "react";

import { WidgetClientContextProvider } from "hooks/WidgetClientHook";
import WidgetPage from "containers/WidgetPage";
import { WheelOfFortuneContextProvider } from "hooks/WheelOfFortuneHook";

import "./App.scss";

const App = () => {
  return (
    <div id="main">
      <WheelOfFortuneContextProvider>
        <WidgetClientContextProvider>
          <WidgetPage />
        </WidgetClientContextProvider>
      </WheelOfFortuneContextProvider>
    </div>
  );
};

export default App;
