import React from "react";
import styles from "./style.module.scss";

const OBSTemplateWithoutDevices = ({
  tipSettingsRenderFunction,
  isPreview = false,
}) => {
  return isPreview ? (
    tipSettingsRenderFunction()
  ) : (
    <div className={styles.outerContainer}>{tipSettingsRenderFunction()}</div>
  );
};

export default OBSTemplateWithoutDevices;
