import * as Sentry from "@sentry/react";

import { sendToAnalytics } from "lib/googleAnalytics";
import { sendAnalytics } from "@feelrobotics/ftap-connector";
import { shouldSendAnalytics } from "utils/utils";
import * as DeviceStates from "./DeviceStates";
import { onGattServerDisconnected } from "lib/deviceReconnectingNotification";

/**
 * Abstract Class BaseDeviceWrapper.
 * @class BaseDeviceWrapper.
 * Device wrapper around the Web Bluetooth device object
 * @abstract
 *
 * @respons Responsible for
 * - holding the WebBluetooth object, its service and charactristics
 * - Connecting and disconnecting to the Web Bluetooth device
 * - Discovering its service and characteristic during the connection process
 * - holding Web Bluetooth device connectin state (connected, disconnected, connecting)
 * - Handling Web Bluetooth device disconnection
 *
 * @collab Is a base class for concrete device classes like Pearl2, Cliona, etc.
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export class BaseDeviceWrapper {
  constructor(device, serviceUuid, charUuid, image, isSendingData) {
    if (this.constructor === BaseDeviceWrapper) {
      throw new Error("Abstract classes can't be instantiated.");
    }
    this.device = device;
    this.serviceUuid = serviceUuid;
    this.charUuid = charUuid;
    this.image = image;

    this.server = null;
    this.sensorService = null;
    this.motorChar = null;
    this.sensorChar = null;
    this.batteryService = null;
    this.batteryChar = null;
    this.connectionState = DeviceStates.DISCONNECTED;
    this.connectionStateCallback = null;
    this.isSendingData = isSendingData;

    this.eventListeners = [];
    this.registerEvent("gattserverdisconnected", () => {
      onGattServerDisconnected(this);
      shouldSendAnalytics() &&
        sendToAnalytics("disconnect", this.device.name, this.device.id);
      sendAnalytics("disconnect", this.device.name, this.device.id);
    });
  }

  /**
   * Function to be used to register any events regarding this device.
   * Usable exactly like 'addEventListener'.
   * @param {string} eventName Name of the event you wish to subscribe to.
   * @param {Function} callback Function to be called when 'eventName' occurs.
   */
  registerEvent(eventName, callback) {
    this.device.addEventListener(eventName, callback);
    this.eventListeners.push({ eventName, callback });
  }

  /**
   * Function to be used for unsubscribing from any events regarding this device.
   * Usable exactly like 'removeEventListener'
   * @param {string} eventName Name of the event you wish to unsubscribe from.
   * @param {Function} callback Function to be unsubscribed.
   */
  deregisterEvent(eventName, callback) {
    this.device.removeEventListener(eventName, callback);

    const index = this.eventListeners.indexOf({ eventName, callback });
    if (index > -1) {
      this.eventListeners = this.eventListeners.splice(index, 1);
    }
  }

  /**
   * Removes all event listeners from this device.
   */
  deregisterAllEvents() {
    for (let i = 0; i < this.eventListeners.length; i++) {
      const listener = this.eventListeners[i];
      this.device.removeEventListener(listener.eventName, listener.callback);
    }
    this.eventListeners.length = 0;
  }

  setSendingData(isSendingData) {
    this.isSendingData = isSendingData;
  }

  /**
   * Set new connection state and notify the callback
   * @param {string} newState - new state from DeviceStates.*
   */
  setConnectionState(newState) {
    this.connectionState = newState;
    this.connectionStateCallback?.();
  }

  /**
   * Connect to the device and discover the service and characteristic
   */
  async connect(extendFunc = async () => {}) {
    async function innerConnect() {
      const startTime = performance.now();

      this.setConnectionState(DeviceStates.CONNECTING);
      this.server = await this.device.gatt.connect();
      if (Array.isArray(this.serviceUuid)) {
        // some devices may have different primary service UUID, like Fuse1.1
        const services = await this.server.getPrimaryServices();
        this.sensorService = await this.server.getPrimaryService(
          services[0].uuid
        );
      } else {
        this.sensorService = await this.server.getPrimaryService(
          this.serviceUuid
        );
      }

      this.motorChar = await this.sensorService.getCharacteristic(
        this.charUuid
      );
      await extendFunc();

      this.setConnectionState(DeviceStates.CONNECTED);
      clearTimeout(this.connectionTimeout);

      // Report to google analytics
      const milliseconds = Math.round(performance.now() - startTime);
      if (this.isSendingData) {
        sendToAnalytics(
          "device_connect",
          "device",
          this.device.name,
          milliseconds
        );
        Sentry.captureMessage(`${this.device.name} connected`);
      }
      sendAnalytics("connect", this.device.name, this.device.id);
    }

    return await Promise.race([innerConnect.bind(this)()]);
  }

  /**
   * Disconnect from the device
   */
  async disconnect() {
    this.setConnectionState(DeviceStates.DISCONNECTING);
    if (this.device.gatt.connected) {
      await this.device.gatt.disconnect();
      if (this.isSendingData) {
        Sentry.captureMessage(`${this.device.name} disconnected`);
      }
    }
    this.setConnectionState(DeviceStates.MANUALLY_DISCONNECTED);
    if (this.isSendingData) {
      sendToAnalytics("device_disconnect", "device", this.device.name);
    }
  }

  /**
   * Abstract method to vibrate the device, should be implemented in child classes
   * @param {int} percent - vibration intesity, 0..100
   */
  async write(percent) {
    throw new Error("Not implemented");
  }

  async getBatteryCharacteristic() {
    this.batteryService = await this.server.getPrimaryService(
      "battery_service"
    );
    this.batteryChar = await this.batteryService.getCharacteristic(
      "battery_level"
    );
  }
}
