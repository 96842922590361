import { translations } from "i18n";

export const ROUTE_PATHS = {
  LANDING: "/",
  SIGN_UP: "/signup",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
  CONFIRM_EMAIL: "/confirm-email",
  CONFIRM_EMAIL_FORM: "/confirm-form",
  RESEND_CONFIRMATION_EMAIL: "/resend-confirmation-email",
  RECOVERY_PASSWORD: "/recovery-password",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  TOYS: "/toys",
  TIP_SETTINGS: "/settings/tipping",
  WEBCAM_SITES: "/webcam-sites",
  OBS_TEMPLATES: "/obs-templates",
  OBS_INSTRUCTIONS: "/obs-instructions",
  FAQ: "/faq",
  WIDGET: "/widget",
};

// Initial presets (tip settings) for the new users
export const DEFAULT_PRESETS = [
  {
    name: "Tip Ranges",
    id: "tip_ranges",
    levels: [
      {
        rangeMax: 50,
        duration: 2,
        intensity: 50,
        toy: "all",
        lightShow: "Blink",
        lightColor: "#CC0000",
      },
      {
        rangeMax: 100,
        duration: 4,
        intensity: 50,
        toy: "all",
        lightShow: "Blink",
        lightColor: "#CC8400",
      },
      {
        rangeMax: 150,
        duration: 6,
        intensity: 50,
        toy: "all",
        lightShow: "Blink",
        lightColor: "#CCCC00",
      },
      {
        rangeMax: 200,
        duration: 8,
        intensity: 50,
        toy: "all",
        lightShow: "Blink",
        lightColor: "#00CC00",
      },
      {
        rangeMax: 250,
        duration: 10,
        intensity: 50,
        toy: "all",
        lightShow: "Blink",
        lightColor: "#00CCCC",
      },
      {
        rangeMax: 300,
        duration: 12,
        intensity: 50,
        toy: "all",
        lightShow: "Blink",
        lightColor: "#0000CC",
      },
      {
        rangeMax: 350,
        duration: 14,
        intensity: 50,
        toy: "all",
        lightShow: "Blink",
        lightColor: "#6600CC",
      },
      {
        rangeMax: "Max",
        duration: 16,
        intensity: 50,
        toy: "all",
        lightShow: "Blink",
        lightColor: "#CC00CC",
      },
    ],
  },
];

export const DEFAULT_PATTERNS = [
  {
    name: "Patterns",
    id: "Patterns",
    levels: [
      {
        title: "Patterns.Steps",
        active: true,
        intensity: 100,
        tokensAmount: 20,
        duration: 10,
        intensitiesPerStep: [20, 20, 40, 40, 60, 60, 80, 80, 100, 100],
        lightShow: "Blink",
        lightColor: "#CC0000",
        toy: "all",
      },
      {
        title: "Patterns.Hill",
        active: true,
        intensity: 100,
        tokensAmount: 25,
        duration: 10,
        intensitiesPerStep: [20, 40, 60, 80, 100, 100, 80, 60, 40, 20],
        lightShow: "Blink",
        lightColor: "#CC0000",
        toy: "all",
      },
      {
        title: "Patterns.Steps",
        active: true,
        intensity: 100,
        tokensAmount: 30,
        duration: 10,
        intensitiesPerStep: [20, 0, 40, 0, 60, 0, 80, 0, 100, 0],
        lightShow: "Blink",
        lightColor: "#CC0000",
        toy: "all",
      },
      {
        title: "Patterns.Stable",
        active: true,
        intensity: 100,
        tokensAmount: 35,
        duration: 10,
        intensitiesPerStep: [100, 0, 100, 0, 100, 0, 100, 0, 100, 0],
        lightShow: "Blink",
        lightColor: "#CC0000",
        toy: "all",
      },
      {
        title: "Patterns.Steps",
        active: true,
        intensity: 100,
        tokensAmount: 40,
        duration: 10,
        intensitiesPerStep: [20, 40, 60, 60, 60, 60, 60, 60, 40, 20],
        lightShow: "Blink",
        lightColor: "#CC0000",
        toy: "all",
      },
      {
        title: "Patterns.Pulse",
        mode: 1,
        active: true,
        intensity: 100,
        tokensAmount: 45,
        duration: 10,
        lightShow: "Blink",
        lightColor: "#00CCCC",
        toy: "all",
      },
      {
        title: "Patterns.SineWave",
        active: true,
        mode: 2,
        intensity: 100,
        tokensAmount: 50,
        duration: 10,
        lightShow: "Unicorn",
        lightColor: "#CC0000",
        toy: "all",
      },
      {
        title: "Patterns.Saw",
        active: true,
        mode: 3,
        intensity: 100,
        tokensAmount: 55,
        duration: 10,
        lightShow: "Glow",
        lightColor: "#CC8400",
        toy: "all",
      },
      {
        title: "Patterns.Saw",
        active: true,
        mode: 3,
        intensity: 100,
        tokensAmount: 60,
        duration: 10,
        lightShow: "Glow",
        lightColor: "#CCCC00",
        toy: "all",
        periodDuration: 500,
      },
    ],
  },
];

export const DEFAULT_WHEEL_OF_FORTUNE = {
  name: "Wheel of Fortune",
  id: "WheelOfFortune",
  buyIn: 50,
  levels: [
    {
      title: "take off my bra",
      active: true,
    },
    {
      title: "squirt",
      active: true,
    },
    {
      title: "suck my finger",
      active: true,
    },
    {
      title: "striptease",
      active: true,
    },
    {
      title: "slide the toy in my pussy",
      active: true,
    },
  ],
};

export const MODAL_STYLES = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "999998",
  },
};

export const AUTH_CODE_INPUT_LENGTH = 6;

// threshold for battery notification
export const BATTERY_THRESHOLD_WARNING = 15;

export const BATTERY_NOTIFICATIONS_OPTIONS = {
  position: "top-right",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  draggable: true,
  theme: "dark",
  className: "tostify_custom_container",
};

export const LIGHT_SHOWS_SPOT = {
  Starlight: translations("PresetDetailsTableRow.Starlight"),
  Comet: translations("PresetDetailsTableRow.Comet"),
  Blink: translations("PresetDetailsTableRow.Blink"),
  Glow: translations("PresetDetailsTableRow.Glow"),
  Spin: translations("PresetDetailsTableRow.Spin"),
  Spectrum: translations("PresetDetailsTableRow.Spectrum"),
  Unicorn: translations("PresetDetailsTableRow.Unicorn"),
  Flare: translations("PresetDetailsTableRow.Flare"),
  Sparkle: translations("PresetDetailsTableRow.Sparkle"),
  Strobe: translations("PresetDetailsTableRow.Strobe"),
  Supernova: translations("PresetDetailsTableRow.Supernova"),
  Galaxy: translations("PresetDetailsTableRow.Galaxy"),
  Rainbow: translations("PresetDetailsTableRow.Rainbow"),
};

export const LIGHT_SHOWS_PROWAND = {
  Blink: translations("PresetDetailsTableRow.Blink"),
  Glow: translations("PresetDetailsTableRow.Glow"),
  Unicorn: translations("PresetDetailsTableRow.Unicorn"),
};

export const LIGHT_SHOWS_EMMA2 = {
  Continuous: translations("PresetDetailsTableRow.Continuous"),
};

export const NON_COLOR_CUSTOMIZABLE_LIGHT_SHOW = [
  "Comet",
  "Spectrum",
  "Unicorn",
  "Flare",
  "Sparkle",
  "Strobe",
  "Supernova",
  "Supernova",
  "Galaxy",
  "Rainbow",
];

export const LIGHT_SHOW_COLORS = [
  "#CC0000",
  "#CC8400",
  "#CCCC00",
  "#00CC00",
  "#00CCCC",
  "#0000CC",
  "#6600CC",
  "#CC00CC",
  "#CC99A3",
];

export const INTENSITIES = {
  0: translations("PresetDetailsTableRow.IntensityOff"),
  25: translations("PresetDetailsTableRow.IntensityLow"),
  50: translations("PresetDetailsTableRow.IntensityMedium"),
  75: translations("PresetDetailsTableRow.IntensityHigh"),
  100: translations("PresetDetailsTableRow.IntensityUltraHigh"),
};
export const KLAVIYO_ENDPOINT =
  "https://13hkv57aba.execute-api.us-east-1.amazonaws.com/Prod/";
