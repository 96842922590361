import styles from "./style.module.scss";
import OBSTemplate from "../common/components/OBSTemplate";
import TipSettingRows from "../common/components/TipSettingRows";
import TipSettingsAnimation from "../common/components/TipSettingsAnimation";

import imgLogo from "./img/OBS-template-8-logo.png";
import timeBgImg from "./img/OBS-template-8-timer.png";

import imgLeftLow from "./img/OBS-template-8-left-low.png";
import imgLeftMedium from "./img/OBS-template-8-left-medium.png";
import imgLeftHigh from "./img/OBS-template-8-left-high.png";
import imgRightLow from "./img/OBS-template-8-right-low.png";
import imgRightMedium from "./img/OBS-template-8-right-medium.png";
import imgRightHigh from "./img/OBS-template-8-right-high.png";

import countdownBg from "./img/OBS-template-8-counter.png";

const settings = {
  imgCoin: null,
  tipValueTextColor: "#fcebb1",
  durationBgImg: timeBgImg,
  durationTextColor: "#000",
  durationPadding: [
    ["30px", "10px"],
    ["30px", "13px"],
  ],
  intensityAssets: [
    [imgLeftLow, imgRightLow, "#00FF00"],
    [imgLeftMedium, imgRightMedium, "#FFE28C"],
    [imgLeftHigh, imgRightHigh, "#FF1919"],
    [imgLeftHigh, imgRightHigh, "#FF1919"],
  ],
};

const animSettings = {
  countdownBgImg: countdownBg,
  countdownTextPadding: -10,
};

const WidgetComponent = ({
  preset,
  tips,
  isPreview = false,
  connectedDevices,
}) => {
  const render = () => {
    return (
      <div className={styles.settingsContainer}>
        <img className={styles.logo} src={imgLogo} alt="" />
        <TipSettingRows
          preset={preset}
          settings={settings}
          isPreview={isPreview}
        />
        <TipSettingsAnimation tips={tips} settings={animSettings} />
      </div>
    );
  };

  return (
    <OBSTemplate
      tips={tips}
      connectedDevices={connectedDevices}
      animationBgColor={"rgba(51, 73, 99, 0.7)"}
      tipSettingsRenderFunction={render}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
