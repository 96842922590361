import React from "react";

import "./style.scss";
import styles from "./styles.module.scss";

const BLUE_COLOR = "#83c3f6";

const Loader = () => (
  <div className={styles.wrapper}>
    <div style={{ color: BLUE_COLOR }} className="la-ball-clip-rotate la-3x">
      <div />
    </div>
  </div>
);

export default Loader;
