import { translations } from "i18n";

const zeroPad = (num, places) => String(num).padStart(places, "0");

export const msecsToTime = (msec) => {
  return `${Math.floor(msec / 60000)}:${zeroPad(
    Math.floor((msec / 1000) % 60),
    2
  )}`;
};

// Vibration intensity limits to define
// ranges which correspond to Low, Medium, High, Ultra-High
// intensity titles
// TODO move to the common module
const INTENSITY_LIMIT_NONE = 13;
const INTENSITY_LIMIT_LOW = 38;
const INTENSITY_LIMIT_MEDIUM = 63;
const INTENSITY_LIMIT_HIGH = 88;

export const selectIntensity = (intensity, selectArray) => {
  if (intensity < INTENSITY_LIMIT_NONE) {
    // Off, do not display this row
    return selectArray[0];
  } else if (intensity < INTENSITY_LIMIT_LOW) {
    // Low
    return selectArray[1];
  } else if (intensity < INTENSITY_LIMIT_MEDIUM) {
    // Medium
    return selectArray[2];
  } else if (intensity < INTENSITY_LIMIT_HIGH) {
    return selectArray[3];
  }
  return selectArray[4];
};

export const getIntensityText = (intensity) => {
  if (+intensity < INTENSITY_LIMIT_NONE) {
    return translations("PresetDetailsTableRow.IntensityOff");
  }
  if (+intensity < INTENSITY_LIMIT_LOW) {
    return translations("PresetDetailsTableRow.IntensityLow");
  }
  if (+intensity < INTENSITY_LIMIT_MEDIUM) {
    return translations("PresetDetailsTableRow.IntensityMedium");
  }
  if (+intensity < INTENSITY_LIMIT_HIGH) {
    return translations("PresetDetailsTableRow.IntensityHigh");
  }

  return translations("PresetDetailsTableRow.IntensityUltraHigh");
};

export const getRangeMinArray = (preset) => {
  const result = [];
  preset.levels.forEach((level, index) => {
    if (index === 0) {
      result[0] = 1;
      return;
    }
    result[index] = +preset.levels[index - 1].rangeMax + 1;
  });

  return result;
};
