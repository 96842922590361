import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { translations } from "i18n";
import Lottie from "lottie-react";

import styles from "./style.module.scss";
import OBSTemplateWithoutDevices from "../common/components/OBSTemplateWithoutDevices";
import WheelOfFortuneRows from "../common/components/WheelOfFortuneRows";
import { useWheelOfFortune } from "hooks/WheelOfFortuneHook";
import lightRed from "./animations/light-red.json";
import red from "./animations/red.json";
import white from "./animations/white.json";
import yellow from "./animations/yellow.json";
import imgLogo from "./img/logo.png";
import imgWin from "./img/win.png";

// wheel consist of 4 colored sectors
const animations = [lightRed, red, white, yellow];

const settings = {
  imgLogo,
  imgWin,
  tipValueTextColor: "#fff",
  tipValueTextFontSize: 48,
  optionStyles: {
    width: 80,
    height: 80,
    borderRadius: 80,
    backgroundColor: "#fff",
    fontFamily: "SF Pro Display",
    fontSize: 50,
    fontWeight: "bold",
    color: "#13657f",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 30px 0 50px",
  },
};

const WidgetComponent = ({ preset, tips = [], isPreview, widgetStep }) => {
  const animationRef = useRef();
  const { randomOption, getRandomOption, getRandomAnimationKey } =
    useWheelOfFortune();

  const renderHeader = () => (
    <div className={styles.logoContainer}>
      <img className={styles.logo} src={settings.imgLogo} alt="" />
      <div className={styles.titleContainer}>
        <div className={styles.firstLine}>
          {translations("WheelOfFortune.Fortune")}
        </div>
        <div className={styles.secondLine}>
          {translations("WheelOfFortune.Wheel")}
        </div>
      </div>
    </div>
  );

  const render = () => {
    return (
      <>
        {(isPreview || widgetStep === 0) && (
          <div
            className={cn(
              styles.settingsContainer,
              styles.backgroundDefault,
              isPreview && styles.preview
            )}
          >
            {renderHeader()}
            <div className={styles.buyIn}>
              {translations("WheelOfFortune.ForTokens", {
                amount: preset.buyIn,
              })}
            </div>
            <WheelOfFortuneRows
              preset={preset}
              isPreview={isPreview}
              settings={settings}
            />
          </div>
        )}
        {widgetStep === 1 && !isPreview && (
          <div
            className={cn(
              styles.settingsContainer,
              styles.backgroundSpinningWheel
            )}
          >
            {renderHeader()}
            <div className={styles.senderTipped}>
              {translations("WheelOfFortune.TippedTokens", {
                sender: tips[tips.length - 1]?.sender,
                amount: tips[tips.length - 1]?.amount,
              }).toUpperCase()}
            </div>
            <Lottie
              lottieRef={animationRef}
              animationData={animations[getRandomAnimationKey()]}
              autoplay={true}
              loop={false}
              className={styles.wheel}
            />
          </div>
        )}
        {widgetStep === 2 && !isPreview && (
          <div
            className={cn(styles.settingsContainer, styles.backgroundNoWheel)}
          >
            {renderHeader()}
            <div className={styles.description}>
              {translations("WheelOfFortune.AndThePrizeIs")}
            </div>
            <img className={styles.win} src={settings.imgWin} alt="" />
            <WheelOfFortuneRows
              preset={preset}
              isPreview={isPreview}
              settings={{
                ...settings,
                tipValueTextFontSize: 68,
                tipValueTextFontWeight: "normal",
                tipValueTextFontFamily: "Phosphate-Inline",
                tipValueTextTransform: "uppercase",
              }}
              indexWon={randomOption}
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (widgetStep === 1) {
      getRandomOption(preset);
    }
  }, [widgetStep]);

  return (
    <OBSTemplateWithoutDevices
      tipSettingsRenderFunction={render}
      isPreview={isPreview}
    />
  );
};

export default WidgetComponent;
