import * as Sentry from "@sentry/react";

import { includesSequence } from "lib/helpers";

const deviceWrappers = [
  require("./wrappers/Esca2").default,
  require("./wrappers/Pearl2").default,
  require("./wrappers/Pearl21").default,
  require("./wrappers/Pearl3").default,
  require("./wrappers/Pearl2+").default,
  require("./wrappers/Fuse").default,
  require("./wrappers/Fuse11").default,
  require("./wrappers/Keon").default,
  require("./wrappers/PulseInteractive").default,
  require("./wrappers/Svakom").default,
  require("./wrappers/SvakomAlex").default,
  require("./wrappers/SvakomAlex2").default,
  require("./wrappers/SvakomHannes").default,
  require("./wrappers/SvakomSam").default,
  require("./wrappers/SvakomSam2").default,
  // require("./wrappers/WeVibe").default,
  require("./wrappers/Cliona").default,
  require("./wrappers/PlayBulb").default,
  require("./wrappers/Cowgirl").default,
  require("./wrappers/Spot").default,
  require("./wrappers/ProWand").default,
  require("./wrappers/SvakomEmma2").default,
  require("./wrappers/PleasureDrive").default,
  require("./wrappers/PowerShot").default,
];

const RenamedSpotDeviceWrapper = require("./wrappers/Spot").default;
const RenamedProwandDeviceWrapper = require("./wrappers/ProWand").default;
const RenamedPleasureDriveWrapper = require("./wrappers/PleasureDrive").default;
const RenamedPowerShotWrapper = require("./wrappers/PowerShot").default;

/**
 * Get array of all possible device names
 */
export function getAllDeviceNames() {
  return deviceWrappers.reduce(
    (names, wrapper) => names.concat(wrapper.deviceNames),
    []
  );
}

// Some devices' names are not the names we want to display, so we use this mapping
const DEVICE_NAME_MAPPING = {
  "SWK-S63E": "Alex NEO 2",
  "SWK-S63E-A": "Alex NEO 2",
  "SWK-SE63": "Alex NEO 2",
  "SWK-SE63E": "Alex NEO 2",
  ALEXNEO2: "Alex NEO 2",
  "S63E Alex NEO 2": "Alex NEO 2",
  "SWK-SX013A": "Pulse Lite NEO",
  "Phoenix Neo 2": "Phoenix NEO 2",
  116: "Phoenix NEO 2",
  "QH-SA059-B": "Ava NEO",
  "MLC-186-B": "Ava NEO",
  "OhMiBod 4.0": "Esca2",
  ESCA2: "Esca2",
  "OhMiBod ESCA": "Esca2",
  "OhMiBod LUMEN": "Lumen",
  SA299C: "Mini Emma Neo",
};

export const getDeviceDisplayName = (deviceName) => {
  return DEVICE_NAME_MAPPING[deviceName] || deviceName;
};

const DEVICE_SPECIFIC_INFO = 0x2a29;

// device-type-specific information stored in manufacturer data
const SPOT_NAME_MANUFACTURER_DATA_V22 = [165, 5, 83, 80, 79, 84];
const SPOT_NAME_MANUFACTURER_DATA_V23 = [165, 5, 1, 1, 1];
const PROWAND_NAME_MANUFACTURER_DATA = [165, 5, 1, 4, 1];
const PLEASURE_DRIVE_NAME_MANUFACTURER_DATA = [
  82, 70, 32, 67, 114, 97, 122, 121, 0, 0,
];
const POWERSHOT_NAME_MANUFACTURER_DATA = [165, 5, 1, 12, 0];

const RENAMED_DEVICES_SERVICES = [
  0x1400, 0x180f, 0x1900, 0x1600, 0xffe5, 0xff90,
];

export async function createDevice(webBleDevice, stateCallback, isSendingData) {
  const deviceName = webBleDevice.name;
  let result = null;
  deviceWrappers.forEach((Wrapper) => {
    if (Wrapper.deviceNames.includes(deviceName)) {
      result = new Wrapper(webBleDevice, isSendingData);
      result.connectionStateCallback = stateCallback;
    }
  });

  if (result) {
    return result;
  } else {
    const server = await webBleDevice.gatt.connect();
    const deviceInformationService = await server.getPrimaryService(
      "device_information"
    );
    const deviceInformationCharacteristic =
      await deviceInformationService.getCharacteristic(DEVICE_SPECIFIC_INFO);
    const value = await deviceInformationCharacteristic.readValue();
    const manufacturerDataArr = [];
    for (let i = 0; i < value.byteLength; i++) {
      manufacturerDataArr.push(value.getUint8(i));
    }

    // Spot R1
    if (
      includesSequence(manufacturerDataArr, SPOT_NAME_MANUFACTURER_DATA_V22) ||
      includesSequence(manufacturerDataArr, SPOT_NAME_MANUFACTURER_DATA_V23)
    ) {
      result = new RenamedSpotDeviceWrapper(webBleDevice, isSendingData);
    }
    // ProWand
    if (includesSequence(manufacturerDataArr, PROWAND_NAME_MANUFACTURER_DATA)) {
      result = new RenamedProwandDeviceWrapper(webBleDevice, isSendingData);
    }
    // PleasureDrive
    if (
      includesSequence(
        manufacturerDataArr,
        PLEASURE_DRIVE_NAME_MANUFACTURER_DATA
      )
    ) {
      result = new RenamedPleasureDriveWrapper(webBleDevice, isSendingData);
    }
    // PowerShot
    if (
      includesSequence(manufacturerDataArr, POWERSHOT_NAME_MANUFACTURER_DATA)
    ) {
      result = new RenamedPowerShotWrapper(webBleDevice, isSendingData);
    }

    result.connectionStateCallback = stateCallback;
    return result;
  }
}

/**
 * Connect a bluetooth device wrapper.
 * Device wrapper is a class around BluetoothDevice.
 * https://developer.mozilla.org/en-US/docs/Web/API/BluetoothDevice
 * The wrapper cannot exist without BluetoothDevice and cannot be created without
 * actual connection to the bluetooth device.
 * @return a promise with is resolved with the device wrapper
 */
export const discoverDevice = async (stateCallback, isSendingData) => {
  const allDevices = getAllDeviceNames().map((name) => ({ name }));
  const allServices = deviceWrappers.reduce(
    (services, wrapper) => services.concat(wrapper.services),
    []
  );
  allServices.push("battery_service");
  allServices.push("device_information");
  allServices.concat(RENAMED_DEVICES_SERVICES);

  console.log("allDevices", allDevices);
  console.log("allServices", allServices);
  const options = {
    filters: [
      ...allDevices,
      {
        manufacturerData: [
          {
            companyIdentifier: 1445, // Kiiroo company (0x05A5)
          },
        ],
      },
      {
        manufacturerData: [
          {
            companyIdentifier: 1040, // Fender musical instrument company (0x0410)
          },
        ],
      },
      {
        manufacturerData: [
          {
            companyIdentifier: 277, // e.solutions company (0x0115)
          },
        ],
      },
    ],
    optionalServices: allServices,
  };

  console.log("Selecting device");
  let device = null;
  try {
    device = await navigator.bluetooth.requestDevice(options);
  } catch (error) {
    console.warn("Device selection was probably cancelled, reason:", error);

    if (isSendingData) {
      Sentry.captureException(error);
    }
    return null;
  }
  console.log(`Device selected. Name: ${device.name}, device id: ${device.id},
                  connected: ${device.gatt.connected}. Connecting to the device...`);
  const deviceWrapper = await createDevice(
    device,
    stateCallback,
    isSendingData
  );
  return deviceWrapper;
};
