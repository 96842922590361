export const getRandomString = (size = 30) =>
  [...Array(size)].map(() => Math.random().toString(36)[2]).join("");

// check if the array includes the sequence (subarray)
export const includesSequence = (arr, sequence) => {
  if (!Array.isArray(arr)) {
    return false;
  }
  for (let i = 0; i <= arr.length - sequence.length; i++) {
    if (arr.slice(i, i + sequence.length).join("") === sequence.join("")) {
      return true;
    }
  }
  return false;
};
