import React, { createContext, useEffect, useState, useCallback } from "react";
import PubNub from "pubnub";
import { publishKey, subscribeKey } from "lib/pubnub";

import { useTipSettings } from "hooks/TipSettingsHook";
import { useTipPatterns } from "hooks/TipPatternsHook";
import { useWheelOfFortune } from "hooks/WheelOfFortuneHook";
import { useTips } from "hooks/TipsHook";
import { useWidgetClient } from "./WidgetClientHook";
import { useWidgetSettings } from "./WidgetSettingsHook";
import { useWebsites } from "./WebsitesHook";
import {
  presetsTemplates,
  patternsTemplates,
  wheelOfFortuneTemplates,
} from "hooks/widgets";
import { useConnectedDevices } from "hooks/ConnectedDevicesHook";

const WidgetServerContext = createContext();

const useWidgetServer = () => {
  const context = React.useContext(WidgetServerContext);
  if (context === undefined) {
    throw new Error(
      "`useWidgetServer` hook must be used within a `WidgetServerContextProvider` component"
    );
  }
  return context;
};

const WidgetServerContextProvider = ({ children }) => {
  const [pubnub, setPubnub] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [currentPreset, setCurrentPreset] = useState(null);

  const { websites } = useWebsites();
  const { presets } = useTipSettings();
  const { patterns } = useTipPatterns();
  const { options, widgetStep } = useWheelOfFortune();
  const { subscribeTipEvents, unsubscribeTipEvents } = useTips();
  const { selectedWebsiteId, selectedTemplateId } = useWidgetSettings();
  const { connectedDevices } = useConnectedDevices();
  const { overlayActive } = useWidgetClient();

  useEffect(() => {
    const uuid = localStorage.getItem("pubnub-uuid") || PubNub.generateUUID();
    localStorage.setItem("pubnub-uuid", uuid);

    setChannelId(uuid);
    const pubNubUser = `portal-${uuid}`;
    const pubnub = new PubNub({
      publishKey,
      subscribeKey,
      uuid: pubNubUser,
    });
    setPubnub(pubnub);
  }, []);

  const sendMessage = useCallback(
    (message) => {
      if (!pubnub || !channelId) {
        const publishPayload = {
          channel: null,
          message: null,
        };

        pubnub.publish(publishPayload, (status, response) => {
          // TODO log errors if any
        });
        // Ignore if not initialized
        return;
      }

      const publishPayload = {
        channel: channelId,
        message,
      };

      pubnub.publish(publishPayload, (status, response) => {
        // TODO log errors if any
      });
    },
    [pubnub, channelId]
  );

  // Notify OBS Widget on every presets change
  useEffect(() => {
    const findTemplate = (preset) => {
      const matchCallback = ({ id }) => id === selectedTemplateId;

      if (
        preset.id === "WheelOfFortune" &&
        wheelOfFortuneTemplates.some(matchCallback)
      ) {
        return wheelOfFortuneTemplates.find(matchCallback);
      }

      if (
        preset.id === "WheelOfFortune" &&
        !wheelOfFortuneTemplates.some(matchCallback)
      ) {
        return wheelOfFortuneTemplates[0];
      }

      if (preset.id === "Patterns" && patternsTemplates.some(matchCallback)) {
        return patternsTemplates.find(matchCallback);
      }

      if (preset.id === "Patterns" && !patternsTemplates.some(matchCallback)) {
        return patternsTemplates[0];
      }

      if (presetsTemplates.some(matchCallback)) {
        return presetsTemplates.find(matchCallback);
      }

      if (!presetsTemplates.some(matchCallback)) {
        return presetsTemplates[0];
      }
    };

    const updatePresense = () => {
      // Get preset based on selected website
      const selectedWebsite = websites.find(
        (ws) => ws.id === selectedWebsiteId
      );
      if (!selectedWebsite) {
        return;
      }

      const preset = presets
        .concat(patterns, options)
        .find((p) => p.id === selectedWebsite.tipSettingsId);
      if (!preset) {
        return;
      }

      setCurrentPreset(preset);

      // Get widget template based on selected template ID
      const template = findTemplate(preset);

      pubnub.setState(
        {
          state: {
            preset,
            template,
            connectedDevices,
            widgetStep,
            overlayActive,
          },
          channels: [channelId],
        },
        function (status, response) {
          if (status.error) {
            // TODO report the error
          }
        }
      );
    };

    if (
      !channelId ||
      !presets ||
      !patterns ||
      !options ||
      !pubnub ||
      !selectedWebsiteId
    ) {
      return;
    }

    updatePresense();
  }, [
    channelId,
    presets,
    patterns,
    options,
    selectedWebsiteId,
    pubnub,
    websites,
    selectedTemplateId,
    connectedDevices,
    widgetStep,
    overlayActive,
  ]);

  useEffect(() => {
    // Handle tips
    const onTip = (tip) => {
      sendMessage({
        what: "tip",
        payload: tip,
      });
    };
    subscribeTipEvents(onTip);
    return () => {
      unsubscribeTipEvents(onTip);
    };
  });

  return (
    <WidgetServerContext.Provider
      value={{
        sendMessage,
        channelId,
        currentPreset,
      }}
    >
      {children}
    </WidgetServerContext.Provider>
  );
};

export { WidgetServerContextProvider, useWidgetServer };
