import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/alex-promotion.png";

const serviceUuid = 0xffe0;
const characteristicUuid = 0xffe1;

const DEVICE_NAME = "Alex NEO";

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Svakom extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
  }

  static get deviceNames() {
    return DEVICE_NAME;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "Svakom";
  }

  async writeMode(mode) {
    const data = [0x12, 0x01, 0x03, 0x00, mode, 0x00];
    const array = new Uint8Array(data);
    await this.motorChar.writeValue(array);
  }

  async write(percent) {
    // Mode could be 01, 02 or 03, or 0xFF if you want to stop the device
    const mode = percent <= 0 ? 0xff : percent < 33 ? 1 : percent < 66 ? 2 : 3;
    await this.writeMode(mode);
  }
}
