const WRITE_ERROR_WAIT_MSEC = 100;

/**
 * AsyncWriteQueue is a class that manages a queue of asynchronous write operations.
 * It ensures that only one write operation is executed at a time, and retries on error with a delay.
 */
export default class AsyncWriteQueue {
  private cmdDelay: number;
  private queue: Array<{
    command: () => Promise<any>;
    callback?: (value: any) => void;
  }>;
  private currentItem: {
    command: () => Promise<any>;
    callback?: (value: any) => void;
  } | null;

  /**
   * Creates an instance of AsyncWriteQueue.
   * @param {number} [delay=WRITE_ERROR_WAIT_MSEC] - The delay in milliseconds to wait before retrying a failed write operation.
   */
  constructor(delay: number = WRITE_ERROR_WAIT_MSEC) {
    this.cmdDelay = delay;
    this.queue = [];
    this.currentItem = null;
  }

  /**
   * Sleeps for a specified number of milliseconds.
   * @param {number} ms - The number of milliseconds to sleep.
   * @returns {Promise<void>} A promise that resolves after the specified delay.
   */
  private sleep = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

  /**
   * Enqueues a new write operation to be processed.
   * @param {() => Promise<any>} command - The asynchronous write operation to execute.
   * @param {(value: any) => void} [callback] - An optional callback function to be called after the write operation completes successfully.
   * @returns {Promise<void>} A promise that resolves when the write operation has been processed.
   */
  async enqueue(
    command: () => Promise<any>,
    callback?: (value: any) => void
  ): Promise<void> {
    // Add the command and its callback to the queue
    this.queue.push({ command, callback });

    // Process the queue
    while (this.queue.length || this.currentItem) {
      if (this.currentItem) {
        try {
          // Execute the command and call the callback with the result
          const value = await this.currentItem.command();
          this.currentItem.callback?.(value);
          this.currentItem = null;
        } catch (error) {
          // On error, wait for the specified delay before retrying
          await this.sleep(this.cmdDelay);
        }
      } else {
        if (this.queue.length) {
          // If no current item is being processed, get the next one from the queue
          this.currentItem = this.queue.shift() || null;
        } else {
          this.currentItem = null;
        }
      }
    }
  }

  /**
   * Clears all pending write operations from the queue.
   */
  clear(): void {
    this.queue.length = 0; // Clearing the array by setting its length to 0
  }
}
