import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/sam-promotion.png";

const serviceUuid = 0xffe0;
const characteristicUuid = 0xffe1;
const confirmCharacteristicUuid = 0xffe2;

const names = ["Sam Neo 2", "Sam Neo 2 Pro"];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Svakom extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
  }

  static get deviceNames() {
    return names;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "Svakom";
  }

  async write(percent) {
    percent = Math.max(0, Math.min(100, percent));
    const intensity = Math.round((percent * 10) / 200);
    const data = [0x55, 0x03, 0x03, 0x00, intensity, intensity ? 0x05 : 0];
    const array = new Uint8Array(data);
    await this.motorChar.writeValue(array);
  }

  async connect() {
    try {
      const result = await super.connect(
        this.getConfirmCharacteristics.bind(this)
      );
      return result;
    } catch (e) {
      return Promise.reject("connect Sam Neo2 e: " + e);
    }
  }

  async getConfirmCharacteristics() {
    this.sensorChar = await this.sensorService.getCharacteristic(
      confirmCharacteristicUuid
    );
    await this.sensorChar.startNotifications();
  }

  async turnOffSuction() {
    const mode = 0;
    const data = [0x12, 0x06, 0x01, mode];
    const array = new Uint8Array(data);
    await this.motorChar.writeValue(array);
  }
}
