import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/default-device.png";

const serviceUuid = 0xff02;
const characteristicUuid = 0xfffc;
const names = ["PLAYBULB CANDLE"];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class PlayBulb extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
  }

  static get deviceNames() {
    return names;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "MiPow";
  }

  async write(percent) {
    const value = Math.max(0, Math.min(100, Math.round(percent)));
    const array = new Uint8Array([value, value, value, value]);
    await this.motorChar.writeValue(array);
  }
}
