import { BaseDeviceWrapper } from "./BaseDeviceWrapper";
import image from "images/devices/keon-promotion.png";

const serviceUuid = "00001900-0000-1000-8000-00805f9b34fb";
const statusCharacteristicUuid = 0x1901;
const characteristicUuid = 0x1902;

const names = ["KEON", "Keon R1", "Keon R2"];

const KEON_STROKE_TIME = 275;

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Keon extends BaseDeviceWrapper {
  constructor(device, isSendingData) {
    super(device, serviceUuid, characteristicUuid, image, isSendingData);
    this.moveInterval = null;
  }

  static get deviceNames() {
    return names;
  }

  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return "Kiiroo";
  }

  async connect() {
    try {
      const result = await super.connect(
        this.getBatteryCharacteristic.bind(this)
      );
      // Move Keon to the movement mode
      await this.motorChar.writeValue(new Uint8Array([0x00]));
      return result;
    } catch (e) {
      console.log("connect Keon e", e);
      return Promise.reject("connect Keon e: " + e);
    }
  }

  async getBatteryCharacteristic() {
    this.statusChar = await this.sensorService.getCharacteristic(
      statusCharacteristicUuid
    );
  }

  async disconnect() {
    clearInterval(this.moveInterval);
    this.moveInterval = null;
    await super.disconnect();
  }

  async moveTo(position, speed) {
    const pos = Math.max(0, Math.min(99, Math.round(position)));
    const spd = Math.max(0, Math.min(99, Math.round(speed)));
    await this.motorChar.writeValue(new Uint8Array([0x03, 0x01, spd, pos]));
  }

  async write(speedPercentage) {
    clearInterval(this.moveInterval);
    this.moveInterval = null;
    if (speedPercentage === 0) {
      return;
    }

    const delay = Math.floor(KEON_STROKE_TIME / (speedPercentage / 100));
    let pos = 0;
    this.moveInterval = setInterval(() => {
      pos = pos ? 0 : 99;
      this.moveTo(pos, speedPercentage);
    }, delay);

    await this.moveTo(0, speedPercentage);
  }

  async getBattery() {
    const data = await this.statusChar?.readValue();
    return data?.getUint8(5);
  }
}
