import React, { createContext, useState, useEffect, useRef } from "react";
import { toast, Id } from "react-toastify";
import differenceBy from "lodash/differenceBy";

import { CreateHook } from "utils/utils";
import { useConnectedDevices } from "hooks/ConnectedDevicesHook";
import { usePageTitle } from "hooks/PageTitleHook";
import { BATTERY_THRESHOLD_WARNING } from "data/constants";
import DeviceBatteryNotification from "components/DeviceBatteryNotification";
import { BATTERY_NOTIFICATIONS_OPTIONS } from "data/constants";
import { getDeviceDisplayName } from "lib/devices/connect";
import { translations } from "i18n";

type Props = {
  children: React.ReactNode;
};

type BatteryDevice = {
  deviceId: string;
  battery?: number;
};

type AlmostDiedDevice = {
  id?: string;
  toastId?: Id;
};

export interface DeviceBatteryContextInterface {
  batteryPerDevice: Array<BatteryDevice> | null;
}

const DeviceBatteryContext =
  createContext<DeviceBatteryContextInterface | null>(null);
const useDeviceBattery = () =>
  CreateHook("useDeviceBattery", DeviceBatteryContext);

const DeviceBatteryContextProvider = (props: Props) => {
  const almostDiedDevices = useRef<AlmostDiedDevice[]>([{}]);
  const [batteryPerDevice, setBatteryPerDevice] =
    useState<Array<BatteryDevice> | null>(null);
  const pageIsvisible = useRef(true);

  const { connectedDevices } = useConnectedDevices();
  const { pageTitle } = usePageTitle();

  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.hidden) {
        pageIsvisible.current = false;
      } else {
        pageIsvisible.current = true;
        document.title = pageTitle;
        const link: HTMLLinkElement | null =
          document.querySelector("link[rel~='icon']");
        link!.href = "/favicon.ico";
      }
    };

    document.addEventListener("visibilitychange", visibilityChangeHandler);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, [pageTitle]);

  const BATTERY_UPDATE_TIMEOUT = 60 * 1000; // every minute
  // get battery status
  useEffect(() => {
    const devicesWithBatteryData = connectedDevices.filter(
      (device: any) => device.wrapper?.getBattery
    );
    let interval: ReturnType<typeof setInterval>;
    if (devicesWithBatteryData.length) {
      const requestBatteryData = () => {
        let batterylevels: Array<BatteryDevice> = [];
        devicesWithBatteryData.forEach(async (device: any) => {
          const battery = await device.wrapper?.getBattery?.();

          batterylevels = [...batterylevels, { battery, deviceId: device.id }];
          setBatteryPerDevice(batterylevels);

          // show warning ony once for each device
          if (almostDiedDevices.current.some(({ id }) => id === device.id)) {
            return;
          }

          if (battery > BATTERY_THRESHOLD_WARNING) {
            return;
          }

          const toastId = toast(
            <DeviceBatteryNotification device={device} />,
            // @ts-ignore
            BATTERY_NOTIFICATIONS_OPTIONS
          );
          almostDiedDevices.current.push({ id: device.id, toastId });

          if (!pageIsvisible.current) {
            const deviceName = getDeviceDisplayName(device.name);
            document.title = `Battery of your ${deviceName} is low`;
            let link: HTMLLinkElement =
              document.querySelector("link[rel~='icon']");
            link.href = "/battery-red.png";

            // create a new system notification
            const notification = new Notification(
              translations("Notifications.OopsYourBatteryIsLow", {
                deviceName: deviceName,
              }),
              {
                body: translations(
                  "Notifications.ToAvoidDisappointmentPutYourDeviceOnCharge"
                ),
                icon: "/favicon.ico",
              }
            );

            // close the notification after 10 seconds
            setTimeout(() => {
              notification.close();
            }, 10 * 1000);
          }
        });
      };

      requestBatteryData();
      interval = setInterval(() => {
        requestBatteryData();
      }, BATTERY_UPDATE_TIMEOUT);
    } else {
      setBatteryPerDevice(null);
    }
    return () => clearInterval(interval);
  }, [connectedDevices.length]);

  useEffect(() => {
    // find disconnected device and dismiss notification for it
    const differences = differenceBy(
      almostDiedDevices.current,
      connectedDevices,
      "id"
    );
    differences.forEach(({ toastId }) => {
      if (toastId) {
        toast.dismiss(toastId);
        const found = almostDiedDevices.current.findIndex(
          ({ toastId: tId }) => toastId == tId
        );
        almostDiedDevices.current.splice(found, 1);
      }
    });
  }, [connectedDevices.length]);

  return (
    <DeviceBatteryContext.Provider
      value={{
        batteryPerDevice,
      }}
    >
      {props.children}
    </DeviceBatteryContext.Provider>
  );
};

export { DeviceBatteryContextProvider, useDeviceBattery };
